import 'admin/assets/css/slide-pane.css';

import { useTheme } from 'admin/context/ThemeContext';
import cx from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactSlidingPane from 'react-sliding-pane';

interface Props {
  nestingLevel?: number;
  customWidth?: string;
  isOpen: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  onRequestClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
}

const NESTING_WIDTHS = ['50%', '45%', '40%'];

function SidePane({
  nestingLevel = 0,
  customWidth,
  children,
  ...props
}: Props): ReactElement {
  const isLg = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const { theme } = useTheme();

  const width = useMemo(() => {
    if (!isLg) {
      return '100%';
    }

    if (customWidth) {
      return customWidth;
    }

    return NESTING_WIDTHS[nestingLevel];
  }, [nestingLevel, isLg, customWidth]);

  return (
    <ReactSlidingPane
      className={cx('dark:bg-gray-800 shadow-none dark:text-gray-300', {
        'slide-pane-dark': theme === 'dark',
      })}
      overlayClassName="z-10"
      width={width}
      {...props}
    >
      <div className="h-full p-8 -m-4 -mx-8 overflow-auto">{children}</div>
    </ReactSlidingPane>
  );
}

export default SidePane;
