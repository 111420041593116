import { Icon } from 'admin/components/widgets/Icon';

type Props = {
  icon: string;
  color?: string;
  className?: string;
  darkMode?: boolean;
};

/**
 * using css filter generator:
 * https://bit.ly/3v7M8X8
 * */

function ColoredIcon({ color, icon, className, darkMode }: Props) {
  let filter = 'brightness(0) saturate(100%)';

  switch (color) {
    case 'white':
      filter +=
        'invert(100%) sepia(100%) saturate(0%) hue-rotate(251deg) brightness(102%) contrast(103%)';
      break;
    case 'gray':
      filter +=
        'invert(100%) sepia(0%) saturate(1389%) hue-rotate(185deg) brightness(90%) contrast(67%)';
      break;
    case 'green':
      filter +=
        'invert(71%) sepia(14%) saturate(1268%) hue-rotate(117deg) brightness(91%) contrast(86%)';
      break;
    case 'red':
      filter +=
        'invert(12%) sepia(99%) saturate(7500%) hue-rotate(1deg) brightness(103%) contrast(105%)';
      break;
    default:
      filter = '';
  }

  if (darkMode) filter += 'invert(1)';

  return (
    <Icon
      aria-hidden="true"
      className={className}
      icon={icon}
      style={{ filter }}
    />
  );
}

export default ColoredIcon;
