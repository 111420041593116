import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  // ToDo Move all error handling to this link
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.info(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  } else if (networkError) {
    console.error(networkError);
    if (response) {
      response.errors = undefined;
    }
  }
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
  credentials: 'include',
});

const defaultOptions = {
  query: {
    notifyOnNetworkStatusChange: true,
  },
  watchQuery: {
    notifyOnNetworkStatusChange: true,
  },
};

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, uploadLink] as any[]),
  cache: new InMemoryCache(),
  defaultOptions,
});

export const clearCacheOnClients = () => {
  client.clearStore();
};
