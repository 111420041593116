import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ArrowRightIcon,
  EditAltIcon,
  EyeIcon,
  EyeOffIcon,
  InboundIcon,
  LinkIcon,
  OutboundIcon,
  PinIcon,
  ReplyIcon,
  TrashIcon,
} from 'admin/assets/icons';
import { EventBadge } from 'admin/components/badges/EventBadge';
import ColoredIcon from 'admin/components/icons/ColoredIcon';
import TimelineRow from 'admin/components/rows/TimelineRow';
import Button from 'admin/components/widgets/Button';
import { Icon } from 'admin/components/widgets/Icon';
import LoadingIndicator from 'admin/components/widgets/LoadingIndicator';
import SidePane from 'admin/components/widgets/SidePane';
import { MOMENT_FULL_DATE_TIME_FORMAT } from 'admin/constants/displays';
import { useCurrentUser } from 'admin/context/CurrentUserContext';
import NewEmailFragment from 'admin/fragments/panels/NewEmailFragment';
import {
  COMPLETE_TASK,
  DELETE_TIMELINE_ENTRY,
  UPDATE_TIMELINE_ENTRY,
} from 'admin/graphql/mutations';
import {
  MAIL_EVENT,
  MAIL_EVENT_ATTACHMENT,
  OUTSTANDING_TASKS,
} from 'admin/graphql/queries';
import { handleBadgeClasses } from 'admin/helpers/event-helpers';
import { useLazyNetworkQuery } from 'admin/hooks/useLazyNetworkQuery';
import {
  formatCallDuration,
  formatDate,
  formatTimelineEntryAuthor,
} from 'admin/utils/formatter';
import {
  getAssociationLink,
  isSame,
  showError,
  showSuccess,
} from 'admin/utils/helpers';
import cx from 'classnames';
import { startCase, unescape, upperFirst } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CallDirection,
  CallOutcome,
  Permission,
} from 'shared/types/admin/enums';
import { EventType } from 'shared/types/admin/enums';
import { AdminEventCall } from 'shared/types/admin/event-call';
import { AdminEventLog } from 'shared/types/admin/event-log';
import {
  AdminEventMail,
  AdminGmailMessage,
} from 'shared/types/admin/event-mail';
import { AdminEventNote } from 'shared/types/admin/event-note';
import { AdminEventUpdate } from 'shared/types/admin/event-update';
import { AdminTask } from 'shared/types/admin/task';
import { AdminTimelineEntry } from 'shared/types/admin/timeline-entry';

import TimelineEventUpdate from './TimelineEventUpdate';

type Props = {
  timelineEntry: AdminTimelineEntry;
  previousTimelineEntry: AdminTimelineEntry | undefined;
  editButtonPressCb: () => void;
  updateEntryCb: (
    entry: Partial<AdminTimelineEntry> | undefined,
    isDeleteEntry?: boolean
  ) => void;
  customerId?: number;
  leadId?: number;
  mixedContext?: boolean;
};

function TimelineEvent({
  timelineEntry,
  previousTimelineEntry,
  leadId,
  customerId,
  updateEntryCb,
  editButtonPressCb,
  mixedContext,
}: Props) {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const [isCompleted, setIsCompleted] = useState(false);
  const [gmailExpanded, setGmailExpanded] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [emailPaneOpen, setEmailPaneOpen] = useState(false);
  const [gmailFullMessage, setGmailFullMessage] = useState<AdminGmailMessage>();

  const [getMailEvent] = useLazyQuery(MAIL_EVENT, {
    onCompleted: ({ mailEvent: { gmailMessageFull } }) =>
      setGmailFullMessage(gmailMessageFull),
  });

  const [
    getMailAttachment,
    { loading: loadingAttachment },
  ] = useLazyNetworkQuery(MAIL_EVENT_ATTACHMENT, {
    onCompleted: ({ mailEventAttachment }) => {
      window.open(mailEventAttachment, '_blank');
    },
  });

  const [completeTask] = useMutation(COMPLETE_TASK, {
    refetchQueries: [{ query: OUTSTANDING_TASKS }],
  });

  const [updateTimelineEntry] = useMutation(UPDATE_TIMELINE_ENTRY);

  const [deleteTimelineEntry] = useMutation(DELETE_TIMELINE_ENTRY);

  const editDisabled = useMemo(
    () =>
      timelineEntry?.author?.id !== currentUser?.owner.id ||
      timelineEntry.type === EventType.update ||
      timelineEntry.type === EventType.call ||
      timelineEntry.type === EventType.mail,
    [currentUser?.owner.id, timelineEntry]
  );

  const handlePinButton = useCallback(() => {
    updateTimelineEntry({
      variables: {
        id: timelineEntry.id,
        data: { isPinned: !timelineEntry.isPinned },
      },
    })
      .then(({ data }) => updateEntryCb(data?.updateTimelineEntry))
      .catch(showError);
  }, [updateTimelineEntry, timelineEntry, updateEntryCb]);

  const handleDeleteButton = useCallback(() => {
    deleteTimelineEntry({
      variables: {
        id: timelineEntry.id,
      },
    })
      .then(({ data }) => {
        showSuccess('Successfully deleted!');
        updateEntryCb(data?.deleteTimelineEntry, true);
      })
      .catch(showError);
  }, [deleteTimelineEntry, timelineEntry.id, updateEntryCb]);

  const showHeader = useMemo(() => {
    if (previousTimelineEntry) {
      const currDate = new Date(timelineEntry.status.effectiveAt);
      const prevDate = new Date(previousTimelineEntry.status.effectiveAt);
      const now = new Date();

      if (timelineEntry.isPinned && previousTimelineEntry.isPinned)
        return false;
      else if (
        timelineEntry.status.isUpcoming &&
        previousTimelineEntry.status.isUpcoming
      )
        return false;
      else if (currDate > now && prevDate > now) return false;
      else if (
        (currDate > now ||
          (!timelineEntry.status.isUpcoming &&
            !previousTimelineEntry.status.isUpcoming)) &&
        isSame(
          timelineEntry.status.effectiveAt,
          previousTimelineEntry?.status.effectiveAt,
          'M'
        ) &&
        !timelineEntry.isPinned &&
        !previousTimelineEntry.isPinned
      )
        return false;
    }
    return true;
  }, [previousTimelineEntry, timelineEntry]);

  const handleTaskButtonPress = useCallback(() => {
    const event = timelineEntry.event as AdminTask;

    completeTask({
      variables: {
        id: event.id,
      },
    })
      .catch(showError)
      .finally(() => setIsCompleted(true));
  }, [completeTask, timelineEntry.event]);

  const handleViewButtonPress = useCallback(() => {
    const { id } = (timelineEntry?.event as AdminEventMail) || {};
    getMailEvent({ variables: { id } });
    setGmailExpanded((v) => !v);
  }, [getMailEvent, timelineEntry?.event]);

  const renderPin = useCallback(
    () =>
      timelineEntry.isPinned && (
        <PinIcon
          aria-hidden="true"
          className="absolute w-6 h-6"
          style={{ top: -25, left: -25 }}
        />
      ),
    [timelineEntry.isPinned]
  );

  const renderActionButtons = useCallback(
    (timelineEntry: AdminTimelineEntry) => {
      if (mixedContext) {
        const { link } = getAssociationLink(timelineEntry.association);
        return (
          <Button aria-label="Open" layout="link" size="icon" title={t('Open')}>
            <a className="ml-1" href={link} rel="noreferrer" target="_blank">
              <LinkIcon className="inline w-4 h-4" />
            </a>
          </Button>
        );
      }

      return (
        <div className="inline ml-2 space-x-1">
          {timelineEntry.type === EventType.mail &&
            (timelineEntry.event as AdminEventMail).gmailThreadId && (
              <Button
                aria-label="View"
                layout="link"
                onClick={handleViewButtonPress}
                size="icon"
                title={t('View')}
              >
                {!gmailExpanded && (
                  <EyeIcon aria-hidden="true" className="w-4 h-4" />
                )}
                {gmailExpanded && (
                  <EyeOffIcon aria-hidden="true" className="w-4 h-4" />
                )}
              </Button>
            )}
          <Button
            aria-label="Pin"
            layout="link"
            onClick={handlePinButton}
            size="icon"
            title={timelineEntry.isPinned ? t('Unpin') : t('Pin')}
          >
            <Icon
              aria-hidden="true"
              className="w-4 h-4"
              icon={timelineEntry.isPinned ? 'StarSolidIcon' : 'StarIcon'}
            />
          </Button>
          {!editDisabled && (
            <Button
              aria-label="Edit"
              layout="link"
              onClick={editButtonPressCb}
              size="icon"
              title={t('Edit')}
            >
              <EditAltIcon aria-hidden="true" className="w-4 h-4" />
            </Button>
          )}
          {currentUser?.owner.role.permissions.includes(
            Permission.destroyEntry
          ) &&
            timelineEntry.type != EventType.call && (
              <Button
                aria-label="Delete"
                layout="link"
                onClick={handleDeleteButton}
                size="icon"
                title={t('Delete')}
              >
                <TrashIcon className="w-4 h-4" />
              </Button>
            )}
        </div>
      );
    },
    [
      editDisabled,
      currentUser?.owner.role.permissions,
      editButtonPressCb,
      handleViewButtonPress,
      handleDeleteButton,
      handlePinButton,
      gmailExpanded,
      mixedContext,
      t,
    ]
  );

  const renderUpdateEventTitle = useCallback(
    (timelineEntry: AdminTimelineEntry) => {
      const titleParts: string[] = [];
      const event = timelineEntry.event as AdminEventUpdate;

      if (timelineEntry.association.id) {
        // only association that is fetched is an offer
        titleParts.push(
          (timelineEntry.association as any).__typename.replace('Admin', '')
        );

        if (event.isCreationEvent) {
          titleParts.push(t('created'));
        } else {
          titleParts.push(t('update'));
        }
      }

      return titleParts.length
        ? upperFirst(titleParts.join(' '))
        : startCase(timelineEntry.type);
    },
    [t]
  );

  const renderDate = useCallback(() => {
    return (
      <div className="ml-auto">
        <span className="pr-2 text-xs font-medium border-r-2">
          {formatDate(timelineEntry.createdAt, MOMENT_FULL_DATE_TIME_FORMAT)}
        </span>
        {renderActionButtons(timelineEntry)}
      </div>
    );
  }, [renderActionButtons, timelineEntry.createdAt]);

  const renderUpdateEvent = useCallback(
    (timelineEntry: AdminTimelineEntry) => {
      return (
        <div className="relative">
          {renderPin()}
          <div className="flex pb-4">
            <span className="pr-1 font-bold">
              {renderUpdateEventTitle(timelineEntry)}
            </span>
            <span>{formatTimelineEntryAuthor(timelineEntry, t)}</span>
            {renderDate()}
          </div>
          <TimelineEventUpdate entry={timelineEntry} />
        </div>
      );
    },
    [renderPin, renderUpdateEventTitle, t, renderDate]
  );

  const renderNoteCallEvent = useCallback(() => {
    return (
      <div className="relative">
        {renderPin()}
        <div className="flex pb-4">
          <span className="pr-1 font-bold">
            {startCase(timelineEntry.type)}
          </span>
          <span>{formatTimelineEntryAuthor(timelineEntry, t)}</span>
          {renderDate()}
        </div>
        <TimelineRow key={timelineEntry.id}>
          {(timelineEntry.event as AdminEventNote).content}
        </TimelineRow>
      </div>
    );
  }, [timelineEntry, renderPin, renderDate, t]);

  const renderMailEvent = useCallback(() => {
    const { id, snippet, subject, gmailFrom, trackingStatus } =
      (timelineEntry?.event as AdminEventMail) || {};

    return (
      <div className="relative">
        {renderPin()}
        <div className="flex pb-4">
          <span className="pr-1 font-bold">
            {startCase(timelineEntry.type)}
          </span>
          {gmailFrom && <span>{`${t('from')} ${gmailFrom}`}</span>}
          {!gmailFrom && (
            <span>{formatTimelineEntryAuthor(timelineEntry, t)}</span>
          )}
          {renderDate()}
        </div>
        <div className="space-y-2">
          {trackingStatus && (
            <div>
              <span className="font-semibold">{`${t('Status')}: `}</span>
              <EventBadge className={handleBadgeClasses(trackingStatus)}>
                {startCase(trackingStatus)}
              </EventBadge>
            </div>
          )}
          <div>
            <span className="font-semibold">{`${t('Subject')}: `}</span>
            <span>{subject}</span>
          </div>
          {snippet && (
            <>
              {gmailExpanded &&
                gmailFullMessage?.attachments &&
                gmailFullMessage.attachments.length && (
                  <div>
                    <span className="font-semibold">{`${t(
                      'Attachments'
                    )}: `}</span>
                    {gmailFullMessage.attachments.map((a, i) => (
                      <button
                        className={cx('px-1 link', {
                          'cursor-wait opacity-50': loadingAttachment,
                        })}
                        disabled={loadingAttachment}
                        key={i}
                        onClick={() =>
                          getMailAttachment({
                            variables: {
                              mailEventId: id,
                              attachmentId: a.attachmentId,
                              filename: a.filename,
                            },
                          })
                        }
                      >
                        {a.filename}
                      </button>
                    ))}
                  </div>
                )}
              <div className="flex space-x-2">
                <TimelineRow>
                  <div>
                    {gmailExpanded && !gmailFullMessage && <LoadingIndicator />}
                    {!gmailExpanded && <i>{unescape(snippet)} ... </i>}
                    {gmailExpanded && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: gmailFullMessage?.content || '',
                        }}
                      />
                    )}
                  </div>
                </TimelineRow>
                <div>
                  {gmailExpanded && (
                    <div>
                      {gmailFrom && (
                        <Button
                          className="p-2"
                          iconLeft={ReplyIcon}
                          layout="link"
                          onClick={() => {
                            setIsForward(false);
                            setEmailPaneOpen(true);
                          }}
                          title={t('Reply')}
                        />
                      )}
                      <Button
                        className="p-2"
                        iconLeft={ArrowRightIcon}
                        layout="link"
                        onClick={() => {
                          setIsForward(true);
                          setEmailPaneOpen(true);
                        }}
                        title={t('Forward')}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }, [
    timelineEntry,
    renderPin,
    t,
    renderDate,
    gmailExpanded,
    gmailFullMessage,
    loadingAttachment,
    getMailAttachment,
  ]);

  const renderLogMailCallEvent = useCallback(() => {
    const logEvent = timelineEntry.event as AdminEventLog;

    return (
      <div className="relative">
        {renderPin()}
        <div className="flex pb-4">
          <span className="pr-1 font-bold">
            {startCase(timelineEntry.type)}
          </span>
          <span>{formatTimelineEntryAuthor(timelineEntry, t)}</span>
          {renderDate()}
        </div>
        <TimelineRow key={timelineEntry.id}>
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="w-32 font-semibold">{t('Date')}</div>
              <div>
                {formatDate(logEvent.date, MOMENT_FULL_DATE_TIME_FORMAT)}
              </div>
            </div>
            {logEvent.callOutcome && (
              <div className="flex flex-col md:flex-row">
                <div className="w-32 font-semibold">{t('Call Outcome')}</div>
                <div>{startCase(CallOutcome[logEvent.callOutcome!])}</div>
              </div>
            )}
            {logEvent.description && (
              <div className="flex flex-col md:flex-row">
                <div className="flex-shrink-0 w-32 font-semibold">
                  {t('Description')}
                </div>
                <div>{logEvent.description}</div>
              </div>
            )}
          </div>
        </TimelineRow>
      </div>
    );
  }, [timelineEntry, renderPin, renderDate, t]);

  const renderCallEvent = useCallback(() => {
    const callEvent = timelineEntry.event as AdminEventCall;

    return (
      <div className="relative">
        {renderPin()}
        <div className="flex pb-4">
          <span className="pr-1 font-bold">
            {startCase(timelineEntry.type)}
          </span>
          <span>
            {callEvent.direction === CallDirection.outbound && t('by')}
            {callEvent.direction === CallDirection.inbound && t('for')}{' '}
            {timelineEntry.author?.name || t('Support Team')}
          </span>
          {renderDate()}
        </div>
        <TimelineRow key={timelineEntry.id}>
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="w-32 font-semibold">{t('Direction')}:</div>
              <div>
                {callEvent.direction === CallDirection.outbound && (
                  <OutboundIcon className="w-4 h-4" title={t('Outbound')} />
                )}
                {callEvent.direction === CallDirection.inbound && (
                  <InboundIcon className="w-4 h-4" title={t('Inbound')} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="w-32 font-semibold">{t('Duration')}:</div>
              <div>{formatCallDuration(callEvent.duration)}</div>
              {callEvent.link && (
                <>
                  <span className="mx-2">·</span>
                  <a
                    className="link"
                    href={callEvent.link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('Listen')}
                    <LinkIcon className="inline w-4 h-4 ml-1" />
                  </a>
                </>
              )}
            </div>
            {callEvent.missedCallReason && (
              <div className="flex flex-col md:flex-row">
                <div className="w-32 font-semibold">{t('Missed Reason')}:</div>
                <div>{startCase(callEvent.missedCallReason)}</div>
              </div>
            )}
          </div>
        </TimelineRow>
      </div>
    );
  }, [timelineEntry, renderPin, renderDate, t]);

  const renderTaskEvent = useCallback(() => {
    const event = timelineEntry.event as AdminTask;
    const isDone = event.isCompleted || isCompleted;
    const clickableIcon =
      event.assignee.id === currentUser?.owner?.id && !isDone;
    const { status } = timelineEntry;
    const now = new Date();

    let icon = 'CheckOutlineCircleIcon';
    let color = '';
    let header = '';
    if (isDone) {
      icon = 'CheckSolidCircle';
      color = 'green';
    } else if (new Date(status.effectiveAt) > now) {
      color = 'gray';
      header = t('Due');
    } else if (new Date(status.effectiveAt) < now) {
      color = 'red';
      header = t('Overdue');
    }

    return (
      <div className="relative">
        {renderPin()}
        <div className="flex pb-2">
          <span className="pr-1 font-bold">
            {startCase(timelineEntry.type)}
          </span>
          <span>{`${t('assigned to')} ${event.assignee.name}`}</span>
          {timelineEntry.author &&
            timelineEntry.author.id !== event.assignee.id && (
              <span>
                &nbsp;{`${t('by')} ${timelineEntry.author.name || t('Bot')}`}
              </span>
            )}
          <span className="ml-auto text-xs font-medium">
            {header && <span>{header}:&nbsp;</span>}
            <span className="pr-2 border-r-2">
              {formatDate(status.effectiveAt, MOMENT_FULL_DATE_TIME_FORMAT)}
            </span>
            {renderActionButtons(timelineEntry)}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className={cx('focus:outline-none transition-all duration-200', {
              'cursor-default': !clickableIcon,
              'hover:opacity-75': clickableIcon,
              'animate-crescendo': isCompleted,
            })}
            disabled={isDone}
            onClick={() => clickableIcon && handleTaskButtonPress()}
          >
            <ColoredIcon
              className="w-6 h-6 transition-all duration-200"
              color={color}
              icon={icon}
            />
          </button>
          <TimelineRow key={timelineEntry.id}>
            <div>
              <div className={cx({ 'line-through': isDone })}>
                {event.title}
              </div>
              <div className="mt-1 text-xs">{event.note}</div>
            </div>
          </TimelineRow>
        </div>
      </div>
    );
  }, [
    currentUser?.owner?.id,
    isCompleted,
    timelineEntry,
    renderPin,
    handleTaskButtonPress,
    renderActionButtons,
    t,
  ]);

  const renderContentByType = useCallback(() => {
    switch (timelineEntry.type) {
      case EventType.update:
        return renderUpdateEvent(timelineEntry);
      case EventType.call:
        return renderCallEvent();
      case EventType.note:
        return renderNoteCallEvent();
      case EventType.mail:
        return renderMailEvent();
      case EventType.logMail:
      case EventType.logCall:
        return renderLogMailCallEvent();
      case EventType.task:
        return renderTaskEvent();
    }
  }, [
    timelineEntry,
    renderUpdateEvent,
    renderCallEvent,
    renderNoteCallEvent,
    renderMailEvent,
    renderLogMailCallEvent,
    renderTaskEvent,
  ]);

  const renderHeader = useCallback(() => {
    if (timelineEntry.isPinned) {
      return t('Pinned');
    } else if (timelineEntry.status.isUpcoming) {
      return t('Upcoming');
    }
    return formatDate(timelineEntry.status.effectiveAt, 'MMMM YYYY');
  }, [t, timelineEntry]);

  return (
    <div className="px-2 pb-4">
      {/** Header */}
      {showHeader && (
        <div
          className={cx(
            'flex justify-center p-2 mb-4 text-sm bg-purple-50 dark:bg-purple-500 w-36',
            { 'mt-6': previousTimelineEntry }
          )}
        >
          {renderHeader()}
        </div>
      )}

      {/** Content */}
      <div className="p-4 text-sm break-all border-2 border-gray-100 rounded min-h-24 dark:border-gray-500">
        {renderContentByType()}
      </div>

      <SidePane
        isOpen={emailPaneOpen}
        onRequestClose={() => setEmailPaneOpen(false)}
        title={isForward ? t('Forward Email') : t('Reply')}
      >
        <NewEmailFragment
          customerId={customerId}
          doc={'customGmail'}
          gmailData={gmailFullMessage}
          isForward={isForward}
          leadId={leadId}
          relevantAssociation={leadId ? 'lead' : 'customer'}
        />
      </SidePane>
    </div>
  );
}

export default TimelineEvent;
