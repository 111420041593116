import { ContextUser, useCurrentUser } from 'admin/context/CurrentUserContext';
import { StaticRoles } from 'shared/types/admin/enums';
import { AdminStaff } from 'shared/types/admin/staff';

type CurrentUserType = {
  permissions: string[];
} & ContextUser;

export function useCurrentRole() {
  const { currentUser } = useCurrentUser();
  const user = currentUser?.owner as AdminStaff;

  return {
    currStaff: user,
    currUserRole: user?.role.id,
    isAdmin: user?.role.id === StaticRoles.admin,
    currUserPermissions: (currentUser as CurrentUserType)?.permissions,
  };
}
