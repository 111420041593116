import { TFunction } from 'i18next';
import { omit } from 'lodash';
import moment from 'moment';
import momentBusiness from 'moment-business-days';
import { AdminLeadStatus, Gender } from 'shared/types/admin/enums';

export const MOMENT_DATE_SHORT = 'MMM DD';
export const MOMENT_DATE_TIME_SHORT = 'MMM DD, HH:mm';
export const MOMENT_DATE_FORMAT = 'MMM DD, YYYY';
export const MOMENT_DATE_FORMAT_STANDARD = 'DD/MM/YYYY';
export const MOMENT_FULL_TIME_FORMAT = 'HH:mm:ss';
export const MOMENT_DATE_TIME_FORMAT = 'ddd, MMM DD, HH:mm';
export const MOMENT_FULL_DATE_TIME_FORMAT = 'ddd, MMM DD YYYY, HH:mm';
export const MOMENT_DATE_FORMAT_STANDARD_ALT = 'DD.MM.YYYY';

export const DATE_PICKER_FORMAT = 'dd/MM/yyyy HH:mm';

export const DE = 'DE';

export const EURO_SYMBOL = '€';

// prevents UI from blocking
export const MAX_REMOVAL_QUANTITY_INPUT_COUNT = 300;

export enum CustomBreadcrumbParent {
  agencies = 'agencies',
  customers = 'customers',
  ordersReviewRemovals = 'review-removals',
  places = 'places',
}

export enum PaymentTypeDisplay {
  pre = 'Down Payment',
  single = 'Final Payment',
  partial = 'Partial Payment',
  final = 'Balance Payment',
  storno = 'Storno Payment',
  credit = 'Credit Payment',
  installment = 'Installment Payment',
}

export const GenderDisplay = Object.keys({
  ...omit(Gender, Gender.other),
});

export enum BooleanTypeDisplay {
  yes = 'yes',
  no = 'no',
}

export enum DashboardCardVariants {
  sales = 'sales',
  customers = 'customers',
}

export enum InvoiceActionFilter {
  partialInvoice = 'partialInvoice',
  finalInvoice = 'finalInvoice',
  ongoing = 'ongoing',
  autoInvoicing = 'autoInvoicing',
}

export const OngoingStatusesArr = Object.keys(AdminLeadStatus).filter(
  (status) =>
    !([
      AdminLeadStatus.new,
      AdminLeadStatus.won,
      AdminLeadStatus.rescue,
    ] as string[]).includes(status)
);

export const LeadOverallStatuses = {
  open: Object.keys(
    omit(AdminLeadStatus, [AdminLeadStatus.won, AdminLeadStatus.lost])
  ),
  closed: [AdminLeadStatus.won, AdminLeadStatus.lost],
};

export const getTimePeriods = (t: TFunction) => [
  {
    key: '1day',
    value: momentBusiness(new Date()).businessAdd(1, 'd').toISOString(),
    displayValue: t('In 1 business day'),
  },
  {
    key: '2days',
    value: momentBusiness(new Date()).businessAdd(2, 'd').toISOString(),
    displayValue: t('In 2 business days'),
  },
  {
    key: '3days',
    value: momentBusiness(new Date()).businessAdd(3, 'd').toISOString(),
    displayValue: t('In 3 business days'),
  },
  {
    key: '1week',
    value: moment(new Date()).add(1, 'w').toISOString(),
    displayValue: t('In 1 week'),
  },
  {
    key: '2weeks',
    value: moment(new Date()).add(2, 'w').toISOString(),
    displayValue: t('In 2 weeks'),
  },
  {
    key: '1month',
    value: moment(new Date()).add(1, 'M').toISOString(),
    displayValue: t('In 1 month'),
  },
  {
    key: '2months',
    value: moment(new Date()).add(2, 'M').toISOString(),
    displayValue: t('In 2 months'),
  },
  {
    key: '3months',
    value: moment(new Date()).add(3, 'M').toISOString(),
    displayValue: t('In 3 months'),
  },
  {
    key: '6months',
    value: moment(new Date()).add(6, 'M').toISOString(),
    displayValue: t('In 6 months'),
  },
];
