import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import './assets/css/tailwind.output.css';

import { ApolloProvider } from '@apollo/client';
import { Windmill } from '@windmill/react-ui';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Slide, ToastContainer } from 'react-toastify';

import App from './App';
import { AuthenticatedProvider } from './context/AuthenticatedContext';
import { CurrentUserProvider } from './context/CurrentUserContext';
import { SidebarProvider } from './context/SidebarContext';
import { ThemeProvider } from './context/ThemeContext';
import i18n from './i18n';
import { client } from './lib/apollo';
import Loading from './pages/Loading';
import theme from './windmill';

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <SidebarProvider>
    <AuthenticatedProvider>
      <CurrentUserProvider>
        <ThemeProvider>
          <ApolloProvider client={client}>
            <Suspense fallback={<Loading />}>
              <Windmill theme={theme} usePreferences>
                <ToastContainer
                  autoClose={2000}
                  hideProgressBar={true}
                  position="bottom-right"
                  transition={Slide}
                />
                <I18nextProvider i18n={i18n}>
                  <App />
                </I18nextProvider>
              </Windmill>
            </Suspense>
          </ApolloProvider>
        </ThemeProvider>
      </CurrentUserProvider>
    </AuthenticatedProvider>
  </SidebarProvider>,
  document.getElementById('root')
);
