import { Avatar } from '@windmill/react-ui';
import { capitalInitials } from 'admin/utils/user';
import cx from 'classnames';
import React from 'react';
import { AdminCustomer } from 'shared/types/admin/customer';
import { AdminStaff } from 'shared/types/admin/staff';

type AvatarSizeProps = 'small' | 'large' | 'regular';

type Props = {
  type: AdminStaff | AdminCustomer | any;
  className?: string;
  size?: AvatarSizeProps;
};

function CircleAvatar({
  className: containerClassName,
  type,
  size = 'large',
}: Props) {
  if (type?.user?.avatar) {
    return (
      <Avatar
        alt="avatar"
        className={containerClassName}
        size={size}
        src={type.user.avatar}
      />
    );
  }

  return (
    <div
      className={cx(
        'flex flex-shrink-0 items-center w-8 h-8 justify-center relative text-white bg-purple-500 border-purple-500 rounded-full',
        containerClassName
      )}
    >
      <div className="text-sm font-semibold">
        {type ? capitalInitials(type) : ''}
      </div>
    </div>
  );
}

export default CircleAvatar;
