import { useMutation, useQuery } from '@apollo/client';
import { Alert, Label } from '@windmill/react-ui';
import { DeleteIcon } from 'admin/assets/icons';
import GenericTagButton from 'admin/components/buttons/GenericTagButton';
import Button from 'admin/components/widgets/Button';
import Dropzone from 'admin/components/widgets/Dropzone';
import Input from 'admin/components/widgets/Input';
import LoadingIndicator from 'admin/components/widgets/LoadingIndicator';
import { RichTextEditor } from 'admin/components/widgets/RichTextEditor';
import {
  REPLY_OR_FORWARD_GMAIL,
  SEND_CUSTOMER_CREDENTIALS,
  SEND_GMAIL,
  SEND_INVOICE_EMAIL,
  SEND_ORDER_CONFIRMATION_EMAIL,
  SEND_POWER_OF_ATTORNEY_EMAIL,
  SEND_POWER_OF_ATTORNEY_ORDER_EMAIL,
  SEND_REMINDER_EMAIL_1,
  SEND_REMINDER_EMAIL_2,
} from 'admin/graphql/mutations';
import { SEND_OWNER_COMMENTS_REMINER_EMAIL } from 'admin/graphql/mutations';
import {
  CUSTOMER_PORTAL_CREDENTIALS_PREVIEW,
  EMAIL_TEMPLATE,
  INVOICE_PREVIEW,
  LEAD_EMAIL_PREVIEW,
  MY_EMAIL_TEMPLATES,
  ORDER_CONFIRMATION_PREVIEW,
  OWNER_COMMENTS_REMINDER_PREVIEW,
  POWER_OF_ATTORNEY_GOOGLE_PREVIEW,
  POWER_OF_ATTORNEY_ORDER_PREVIEW,
  REMINDER_1_PREVIEW,
  REMINDER_2_PREVIEW,
} from 'admin/graphql/queries';
import { RelevantAssociation } from 'admin/types/offer';
import { handleApolloError } from 'admin/utils/helpers';
import { getHrefForAttachment } from 'admin/utils/http';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { omit } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminAttachment } from 'shared/types/admin/document-template';
import { AdminGmailMessage } from 'shared/types/admin/event-mail';

type OrderConfirmationEmailProps = {
  doc: 'orderConfirmation';
  orderId: number;
};

type PowerOfAttorneyEmailProps = {
  doc: 'powerOfAttorneyGoogle' | 'powerOfAttorneyOrder';
  customerId: number | undefined;
  orderId: number | undefined;
};

type InvoiceEmailProps = {
  doc: 'invoice' | undefined;
  paymentId: number;
};

type OwnerCommentsReminderProps = {
  doc: 'ownerCommentsReminder';
  orderId: number;
};

type CustomerPortalCredsProps = {
  doc: 'customerPortalCreds';
  customerId: number;
};

type GmailProps = {
  doc: 'gmail';
  leadId?: number;
  customerId?: number;
  offerId?: number;
  orderId?: number;
  emailTo?: string;
};

type CustomGmailProps = {
  doc: 'customGmail';
  gmailData?: AdminGmailMessage;
  isForward: boolean;
  leadId?: number;
  customerId?: number;
  offerId?: number;
  orderId?: number;
};

type PaymentReminderEmailProps = {
  doc: 'paymentReminder1' | 'paymentReminder2' | undefined;
  paymentId: number;
};

type Base64FileData = {
  filename: string;
  contentType: string;
  base64File: string;
};

export type NewEmailFragmentProps = {
  relevantAssociation?: RelevantAssociation;
} & (
  | OrderConfirmationEmailProps
  | PowerOfAttorneyEmailProps
  | OwnerCommentsReminderProps
  | CustomerPortalCredsProps
  | InvoiceEmailProps
  | PaymentReminderEmailProps
  | GmailProps
  | CustomGmailProps
);

const initialValuesStatic = {
  to: '',
  bcc: '',
  cc: '',
  subject: '',
  includeSignature: true,
  content: '',
  attachments: [],
};

export default function NewEmailFragment(props: NewEmailFragmentProps) {
  const { t } = useTranslation();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const [initialValues, setInitialValues] = useState(initialValuesStatic);
  const [error, setError] = useState('');
  const [showBcc, setShowBcc] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [newAttachments, setNewAttachments] = useState<any[]>([{}]);

  const loading = useMemo(() => isEqual(initialValues, initialValuesStatic), [
    initialValues,
  ]);

  const previewQuery = useMemo(() => {
    switch (props.doc) {
      case 'orderConfirmation':
        return ORDER_CONFIRMATION_PREVIEW;
      case 'powerOfAttorneyOrder':
        return POWER_OF_ATTORNEY_ORDER_PREVIEW;
      case 'powerOfAttorneyGoogle':
        return POWER_OF_ATTORNEY_GOOGLE_PREVIEW;
      case 'ownerCommentsReminder':
        return OWNER_COMMENTS_REMINDER_PREVIEW;
      case 'invoice':
        return INVOICE_PREVIEW;
      case 'paymentReminder1':
        return REMINDER_1_PREVIEW;
      case 'gmail':
        return LEAD_EMAIL_PREVIEW;
      case 'customerPortalCreds':
        return CUSTOMER_PORTAL_CREDENTIALS_PREVIEW;
      default:
        return REMINDER_2_PREVIEW;
    }
  }, [props.doc]);

  const sendMutation = useMemo(() => {
    switch (props.doc) {
      case 'orderConfirmation':
        return SEND_ORDER_CONFIRMATION_EMAIL;
      case 'powerOfAttorneyOrder':
        return SEND_POWER_OF_ATTORNEY_ORDER_EMAIL;
      case 'powerOfAttorneyGoogle':
        return SEND_POWER_OF_ATTORNEY_EMAIL;
      case 'ownerCommentsReminder':
        return SEND_OWNER_COMMENTS_REMINER_EMAIL;
      case 'invoice':
        return SEND_INVOICE_EMAIL;
      case 'paymentReminder1':
        return SEND_REMINDER_EMAIL_1;
      case 'gmail':
        return SEND_GMAIL;
      case 'customGmail':
        return REPLY_OR_FORWARD_GMAIL;
      case 'customerPortalCreds':
        return SEND_CUSTOMER_CREDENTIALS;
      default:
        return SEND_REMINDER_EMAIL_2;
    }
  }, [props.doc]);

  const [send, { loading: sending, data: sent }] = useMutation(sendMutation);

  const { refetch: fetchPreview } = useQuery(previewQuery, {
    skip: true,
  });

  const { refetch: fetchEmailTemplate } = useQuery(EMAIL_TEMPLATE, {
    skip: true,
  });

  const { data: emailTemplates, loading: loadingEmailTemplates } = useQuery(
    MY_EMAIL_TEMPLATES,
    {
      variables: {
        where: {
          relevantAssociation: props.relevantAssociation!,
        },
      },
      skip: !props.relevantAssociation,
    }
  );

  const handleEmailTemplateChange = useCallback(
    (id) => {
      if (id === selectedTemplate) {
        setInitialValues({
          ...initialValues,
          subject: props.doc === 'customGmail' ? initialValues.subject : '',
          content: '',
          attachments: [],
        });
        setSelectedTemplate(undefined);
        return;
      }

      const leadId =
        props.doc === 'gmail' || props.doc === 'customGmail'
          ? props.leadId
          : undefined;
      const offerId =
        props.doc === 'gmail' || props.doc === 'customGmail'
          ? props.offerId
          : undefined;
      const customerId =
        props.doc === 'gmail' || props.doc === 'customGmail'
          ? props.customerId
          : undefined;
      const orderId =
        props.doc === 'gmail' || props.doc === 'customGmail'
          ? props.orderId
          : undefined;

      setSelectedTemplate(id);
      fetchEmailTemplate({
        lead: leadId ? { id: Number(leadId) } : undefined,
        offer: offerId ? { id: offerId } : undefined,
        customer: customerId ? { id: Number(customerId) } : undefined,
        order: orderId ? { id: Number(orderId) } : undefined,
        id,
      })?.then((res: any) => {
        const subject =
          props.doc === 'customGmail'
            ? initialValues.subject
            : res.data.loadEmailTemplate.subject;
        setInitialValues({
          ...initialValues,
          subject,
          content: res.data.loadEmailTemplate.content,
          attachments: res.data.loadEmailTemplate.attachments,
        });
      });
    },
    [fetchEmailTemplate, initialValues, selectedTemplate, props]
  );

  useEffect(() => {
    const fetchPreviewAny = fetchPreview as any;
    switch (props.doc) {
      case 'orderConfirmation': {
        fetchPreviewAny({ orderId: props.orderId })?.then((res: any) =>
          setInitialValues(res.data.order.confirmationEmailPreview)
        );
        break;
      }
      case 'powerOfAttorneyOrder': {
        fetchPreviewAny({
          orderId: props.orderId,
        })?.then((res: any) =>
          setInitialValues(res.data.order.powerOfAttorneyOrderEmailPreview)
        );
        break;
      }
      case 'ownerCommentsReminder': {
        fetchPreviewAny({
          orderId: props.orderId,
        })?.then((res: any) =>
          setInitialValues(res.data.order.ownerCommentsReminderEmailPreview)
        );
        break;
      }
      case 'powerOfAttorneyGoogle': {
        fetchPreviewAny({
          customerId: props.customerId,
        })?.then((res: any) =>
          setInitialValues(res.data.customer.powerOfAttorneyGoogleEmailPreview)
        );
        break;
      }
      case 'invoice': {
        fetchPreviewAny({ paymentId: props.paymentId })?.then((res: any) =>
          setInitialValues(res.data.payment.invoiceEmailPreview)
        );
        break;
      }
      case 'paymentReminder1': {
        fetchPreviewAny({ paymentId: props.paymentId })?.then((res: any) =>
          setInitialValues(res.data.payment.reminder1EmailPreview)
        );
        break;
      }
      case 'paymentReminder2': {
        fetchPreviewAny({ paymentId: props.paymentId })?.then((res: any) =>
          setInitialValues(res.data.payment.reminder2EmailPreview)
        );
        break;
      }
      case 'gmail': {
        setInitialValues({
          ...initialValues,
          to: props.emailTo || '',
          includeSignature: true,
        });
        break;
      }
      case 'customGmail': {
        setInitialValues({
          ...initialValues,
          subject: props.gmailData ? props.gmailData.subject : '',
          to: props.gmailData && !props.isForward ? props.gmailData.from : '',
          content:
            props.gmailData && props.isForward ? props.gmailData.content : '',
        });
        break;
      }
      case 'customerPortalCreds': {
        fetchPreviewAny({ customerId: props.customerId })?.then((res: any) =>
          setInitialValues(res.data.customer.customerPortalCredentialsPreview)
        );
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.doc]);

  const handleSubmit = useCallback(
    (values) => {
      setError('');

      const variables = {} as Record<string, any>;

      switch (props.doc) {
        case 'invoice':
        case 'paymentReminder1':
        case 'paymentReminder2': {
          Object.assign(variables, {
            payment: { id: props.paymentId },
            data: {
              subject: values.subject,
              content: values.content,
              attachments: newAttachments.filter((i) => !isEmpty(i)),
              cc: values.cc,
              bcc: values.bcc,
            },
          });
          break;
        }
        case 'orderConfirmation':
        case 'ownerCommentsReminder': {
          Object.assign(variables, {
            order: { id: props.orderId },
            data: {
              subject: values.subject,
              content: values.content,
              attachments: newAttachments.filter((i) => !isEmpty(i)),
              to: values.to,
              cc: values.cc,
              bcc: values.bcc,
            },
          });
          break;
        }
        case 'powerOfAttorneyOrder': {
          Object.assign(variables, {
            order: {
              id: props.orderId,
            },
          });
          break;
        }
        case 'powerOfAttorneyGoogle': {
          Object.assign(variables, {
            customer: {
              id: props.customerId,
            },
          });
          break;
        }
        case 'gmail': {
          Object.assign(variables, {
            data: {
              ...omit(values, ['includeSignature', '__typename']),
              attachments: newAttachments.filter((i) => !isEmpty(i)),
            },
            selectedTemplate,
            lead: props.leadId ? { id: props.leadId } : undefined,
            offer: props.offerId ? { id: props.offerId } : undefined,
            order: props.orderId ? { id: props.orderId } : undefined,
            customer: props.customerId
              ? { id: props.customerId || undefined }
              : undefined,
            includeSignature: values.includeSignature,
          });
          break;
        }
        case 'customGmail': {
          Object.assign(variables, {
            data: {
              ...omit(values, ['includeSignature', '__typename']),
              attachments: newAttachments.filter((i) => !isEmpty(i)),
              isForward: props.isForward,
              threadId: props.gmailData!.gmailThreadId,
              references: props.gmailData!.references,
              inReplyTo: props.gmailData!.inReplyTo,
            },
            selectedTemplate,
            lead: props.leadId ? { id: props.leadId } : undefined,
            customer: props.customerId ? { id: props.customerId } : undefined,
            includeSignature: values.includeSignature,
          });
          break;
        }
        case 'customerPortalCreds': {
          Object.assign(variables, {
            customer: {
              id: props.customerId,
            },
          });
          break;
        }
      }

      if (!variables.data) {
        variables.data = {
          subject: values.subject,
          content: values.content,
          attachments: newAttachments.filter((i) => !isEmpty(i)),
          cc: values.cc,
          bcc: values.bcc,
        };
      }

      send({
        variables: variables as any,
      })
        .catch(handleApolloError(setError))
        .finally(() => messagesEndRef?.current?.scrollIntoView());
    },
    [props, send, newAttachments, selectedTemplate]
  );

  const handleUploads = useCallback((upload: Base64FileData) => {
    setNewAttachments((curr) => [
      ...curr.filter((i) => !isEmpty(i)),
      upload,
      {},
    ]);
  }, []);

  const handleRemoveAttachment = useCallback((i) => {
    // find and remove attachment
    setNewAttachments((attachments) => {
      if (attachments.length === 1) {
        return [];
      }
      return attachments.filter((att, j) => i !== j);
    });
  }, []);

  const disableInput = useMemo(() => {
    if (
      props.doc === 'orderConfirmation' ||
      props.doc === 'customerPortalCreds' ||
      props.doc === 'ownerCommentsReminder'
    ) {
      return false;
    }

    if (props.doc === 'customGmail' && props.isForward) {
      return false;
    }

    if (
      props.doc === 'gmail' &&
      (props.relevantAssociation === 'offerLead' ||
        props.relevantAssociation === 'offerCustomer')
    ) {
      return false;
    }

    return true;
  }, [props]);

  if (loading && !error) {
    return (
      <div className="flex justify-center h-full">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="overflow-y-scroll pb-8" style={{ maxHeight: '80vh' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <Label>
              <span className="font-semibold">{t('To')}</span>
              <Input
                className="mt-1"
                disabled={disableInput}
                name="to"
                onChange={handleChange}
                type="email"
                value={values.to}
              />
            </Label>
            <div>
              <span>
                <a
                  className="cursor-pointer link"
                  onClick={() => setShowBcc(!showBcc)}
                >
                  {t('Bcc')}
                </a>
              </span>
              <span> / </span>
              <span>
                <a
                  className="cursor-pointer link"
                  onClick={() => setShowCc(!showCc)}
                >
                  {t('Cc')}
                  {!!values.cc && (
                    <span className="px-2 py-1 ml-1 text-xs text-white bg-blue-500 rounded-full">
                      {
                        values.cc.split(',').filter((i: string) => i.trim())
                          .length
                      }
                    </span>
                  )}
                </a>
              </span>
            </div>
            {showBcc && (
              <Label className="px-1">
                <span className="font-semibold">{t('Bcc')}</span>
                <Input
                  className="mt-1"
                  name="bcc"
                  onChange={handleChange}
                  value={values.bcc}
                />
              </Label>
            )}
            {showCc && (
              <Label className="px-1">
                <span className="font-semibold">{t('Cc')}</span>
                <Input
                  className="mt-1"
                  name="cc"
                  onChange={handleChange}
                  value={values.cc}
                />
              </Label>
            )}
            <Label>
              <span className="font-semibold">{t('Subject')}</span>
              <Input
                className="mt-1"
                disabled={props.doc === 'customGmail' && !!values.subject}
                name="subject"
                onChange={handleChange}
                value={values.subject}
              />
            </Label>
            {(props.doc === 'gmail' ||
              (props.doc === 'customGmail' && !props.isForward)) &&
              !!emailTemplates?.myEmailTemplates.length && (
                <Label
                  className="font-semibold"
                  onClick={(e) => e.preventDefault()}
                >
                  <span>{t('Load Email Template')}</span>
                  {loadingEmailTemplates ? (
                    <div className="flex justify-center">
                      <LoadingIndicator />
                    </div>
                  ) : (
                    <div className="flex flex-wrap items-center">
                      {emailTemplates?.myEmailTemplates.map(({ id, name }) => (
                        <div key={id}>
                          <GenericTagButton
                            active={id === selectedTemplate}
                            key={id}
                            onClick={() => handleEmailTemplateChange(id)}
                            text={name}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Label>
              )}
            <Label onClick={(e) => e.preventDefault()}>
              <span className="font-semibold">{t('Content')}</span>
              <RichTextEditor
                className="mt-1 rounded-md"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    ['blockquote', 'code-block'],
                    ['link'],

                    [{ list: 'ordered' }, { list: 'bullet' }],

                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ font: [] }],
                    [{ align: [] }],

                    ['clean'],
                  ],
                }}
                onChange={(content) => {
                  setFieldValue('content', content);
                }}
                value={values.content}
              />
            </Label>
            {(props.doc === 'gmail' || props.doc === 'customGmail') && (
              <div>
                <Label>
                  <span className="pr-2 font-semibold">{t('Signature')}</span>
                  <Input
                    checked={values.includeSignature}
                    className="mr-1"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('includeSignature', e.target.checked);
                    }}
                    type="checkbox"
                  />
                </Label>
              </div>
            )}
            <div className="flex flex-col space-y-4 text-sm">
              <span className="font-semibold">{t('Attachments')}</span>
              {newAttachments.map((v, i) => (
                <div className="flex items-center space-x-2" key={i}>
                  <Dropzone
                    base64Format
                    className="w-96"
                    handleUploads={handleUploads}
                    maxFiles={1}
                    name={v.filename}
                  />
                  {i !== newAttachments.length - 1 && (
                    <Button
                      layout="link"
                      onClick={() => handleRemoveAttachment(i)}
                      type="button"
                    >
                      <DeleteIcon aria-hidden="true" className="flex w-6 h-6" />
                    </Button>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-4 text-sm">
              {values.attachments?.map((attachment: AdminAttachment, i) => {
                return (
                  <a
                    className="block pt-1 text-sm link"
                    href={getHrefForAttachment(attachment)}
                    key={i}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {attachment.filename}
                  </a>
                );
              })}
            </div>
            <div className="flex">
              <Button
                className="w-36"
                disabled={!values.to || !values.subject || sending}
                type="submit"
              >
                {sending && <LoadingIndicator light />}
                {!sending && t('Send')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <div ref={messagesEndRef}>
        {sent && (
          <Alert className="mt-4" type="success">
            {t('Successfully sent!')}
          </Alert>
        )}
        {error && (
          <Alert className="mt-4" type="danger">
            {error}
          </Alert>
        )}
      </div>
    </div>
  );
}
