import React, { useContext, useState } from 'react';
import { AdminStaff } from 'shared/types/admin/staff';
import { AdminUser } from 'shared/types/admin/user';

type Props = {
  children: React.ReactNode;
};

export type ContextUser = AdminUser & { owner: AdminStaff };

type ContextProps = {
  currentUser?: ContextUser;
  setCurrentUser: (currentUser?: ContextUser) => void;
};

export const CurrentUserContext = React.createContext<ContextProps>({
  currentUser: undefined,
  setCurrentUser: () => null,
});

export const CurrentUserProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<ContextUser | undefined>();

  return (
    <CurrentUserContext.Provider value={{ setCurrentUser, currentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}
