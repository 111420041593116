import { Input as WindmillInput } from '@windmill/react-ui';
import { ParsedQs } from 'qs';
import React from 'react';

export type InputProps = React.HTMLAttributes<HTMLInputElement> & {
  /**
   * Defines the color of the input
   */
  valid?: boolean;
  /**
   * Defines if the input is disabled
   */
  disabled?: boolean;
  /**
   * Defines the type of the input
   */
  type?: string;

  name?: string;

  value?: string | string[] | ParsedQs | ParsedQs[] | number | undefined;

  min?: number;

  max?: number;

  step?: number;

  checked?: boolean;

  autoFocus?: boolean;

  required?: boolean;
};

export default function Input(props: InputProps): React.ReactElement {
  return <WindmillInput {...props} />;
}
