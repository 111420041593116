export const Tabs = Object.freeze({
  dashboard: 'dashboard',
  customers: 'customers',
  potential: 'potential',
  agencies: 'agencies',
  orders: 'orders',
  leads: 'leads',
  offers: 'offers',
  tasks: 'tasks',
  settings: 'settings',
  google: 'google',
  onnoGpt: 'OnnoGPT',
});

export enum NavVariants {
  main = 'main',
  sub = 'sub',
}
