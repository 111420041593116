import { TypedDocumentNode, gql } from '@apollo/client';
import {
  AdminBillingAddress,
  AdminBillingAddressCreateArgs,
  AdminBillingAddressUpdateArgs,
} from 'shared/types/admin/billing-address';
import {
  AdminCustomerSendCustomerCredentialsArgs,
  AdminCustomerUpdateArgs,
} from 'shared/types/admin/customer';
import {
  AdminCustomer,
  AdminCustomerSendPowerOfAttorneyArgs,
} from 'shared/types/admin/customer';
import { AdminEmailTemplateFindArgs } from 'shared/types/admin/email-template';
import {
  AdminCustomerLogEventCreateArgs,
  AdminEventLog,
  AdminLeadLogEventCreateArgs,
  AdminLogEventUpdateArgs,
} from 'shared/types/admin/event-log';
import {
  AdminEventCustomerNoteCreateArgs,
  AdminEventLeadNoteCreateArgs,
  AdminEventNote,
  AdminEventNoteUpdateArgs,
  AdminEventOrderNoteCreateArgs,
} from 'shared/types/admin/event-note';
import {
  AdminReplyOrForwardGmailArgs,
  AdminSendGmailArgs,
} from 'shared/types/admin/gmail';
import { IdInput } from 'shared/types/admin/input';
import {
  AdminLead,
  AdminLeadArgs,
  AdminLeadUpdateArgs,
  AdminStartOutboundCallArgs,
} from 'shared/types/admin/lead';
import {
  AdminLeadAllocationRuleRegion,
  AdminLeadAllocationRuleRegionUpdateArgs,
} from 'shared/types/admin/lead-allocation';
import {
  AdminLeadOfflineCampaign,
  AdminOfflineCampaignArgs,
} from 'shared/types/admin/lead-offline-campaign';
import {
  AdminOffer,
  AdminOfferCreateArgs,
  AdminOfferUpdateArgs,
  AdminOfferUpdateStatusArgs,
} from 'shared/types/admin/offer';
import {
  AdminOnlineProfile,
  AdminOnlineProfileCreateArgs,
} from 'shared/types/admin/online-profile';
import {
  AdminOrder,
  AdminOrderAutomateInvoicingArgs,
  AdminOrderCreateArgs,
  AdminOrderEmailArgs,
  AdminOrderUpdateArgs,
} from 'shared/types/admin/order';
import {
  AdminPausePolicy,
  AdminPausePolicyCreateArgs,
} from 'shared/types/admin/pause-policy';
import {
  AdminPayment,
  AdminPaymentCreateInvoiceArgs,
  AdminPaymentSendEmailArgs,
} from 'shared/types/admin/payment';
import {
  AdminPricingCategory,
  AdminPricingCategoryMoveProductArgs,
} from 'shared/types/admin/pricing-category';
import {
  AdminPricingRule,
  AdminPricingRuleCreateArgs,
  AdminPricingRuleUpdateArgs,
} from 'shared/types/admin/pricing-rule';
import {
  AdminRemovalEntry,
  AdminRemovalEntryUpdateArgs,
  AdminRemovalEntryUpdateStatusArgs,
} from 'shared/types/admin/removal-entry';
import {
  AdminRemovalSubmission,
  AdminRemovalSubmissionLawyerUpdateArgs,
  AdminRemovalSubmissionUpdateArgs,
} from 'shared/types/admin/removal-submission';
import { AdminUpdateFromCSV } from 'shared/types/admin/reports';
import {
  AdminTask,
  AdminTaskCustomerCreateArgs,
  AdminTaskLeadCreateArgs,
  AdminTaskOrderCreateArgs,
  AdminTaskUpdateArgs,
} from 'shared/types/admin/task';
import {
  AdminTimelineEntry,
  AdminTimelineEntryUpdateArgs,
} from 'shared/types/admin/timeline-entry';
import { AdminUser, AdminUserUpdateEmailArgs } from 'shared/types/admin/user';

// authentication

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

// staff

export const CREATE_STAFF = gql`
  mutation createStaff($data: AdminStaffCreateInput!) {
    createStaff(data: $data) {
      id
    }
  }
`;

export const UPDATE_STAFF = gql`
  mutation updateStaff($id: Int!, $data: AdminStaffUpdateInput!) {
    updateStaff(id: $id, data: $data) {
      id
      gender
      firstName
      lastName
      name
      phone
      jobTitle
      sendoutEmail
      user {
        id
        avatar
        signature
        pcSerialNumber
      }
    }
  }
`;

export const UPDATE_GMAIL_SIGNATURE = gql`
  mutation updateStaffGmailSignature($id: Int!) {
    updateStaffGmailSignature(id: $id) {
      id
    }
  }
`;

// customers

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($data: AdminCustomerCreateInput!) {
    createCustomer(data: $data) {
      id
    }
  }
`;

export const SEND_CUSTOMER_CREDENTIALS: TypedDocumentNode<
  { sendCustomerPortalCreds: Pick<AdminCustomer, 'id'> },
  AdminCustomerSendCustomerCredentialsArgs
> = gql`
  mutation sendCustomerPortalCreds($customer: IdInput!, $data: EmailInput!) {
    sendCustomerPortalCreds(customer: $customer, data: $data) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER: TypedDocumentNode<
  { updateCustomer: AdminCustomer },
  AdminCustomerUpdateArgs
> = gql`
  mutation updateCustomer(
    $id: Int!
    $data: AdminCustomerUpdateInput!
    $supportHandoverDays: Int
  ) {
    updateCustomer(
      id: $id
      data: $data
      supportHandoverDays: $supportHandoverDays
    ) {
      id
      powerOfAttorneyGoogle
      customerId
      isAgency
      blacklistReason
      isSubscribedNewsletter
      supportHandoverAt
      paymentMethod
      gender
      email
      name
      firstName
      lastName
      phoneNumbers
      user {
        id
      }
      manager {
        id
        user {
          id
        }
      }
    }
  }
`;

export const SUPPLY_CUSTOMER: TypedDocumentNode<
  { supplyCustomer: AdminCustomer },
  IdInput
> = gql`
  mutation supplyCustomer($id: Int!) {
    supplyCustomer(id: $id) {
      id
      currentSupplier {
        id
        name
      }
    }
  }
`;

export const COMPLETE_SUPPLYING_CUSTOMER: TypedDocumentNode<
  { completeSupplyingCustomer: AdminCustomer },
  IdInput
> = gql`
  mutation completeSupplyingCustomer($id: Int!) {
    completeSupplyingCustomer(id: $id) {
      id
      currentSupplier {
        id
      }
      lastSupplier {
        id
      }
      lastSuppliedAt
      potentialRemovalsCount
    }
  }
`;

export const CREATE_PAUSE_POLICY: TypedDocumentNode<
  { createPausePolicy: AdminPausePolicy },
  AdminPausePolicyCreateArgs
> = gql`
  mutation createPausePolicy($data: AdminPausePolicyCreateInput!) {
    createPausePolicy(data: $data) {
      id
    }
  }
`;

export const DELETE_PAUSE_POLICY: TypedDocumentNode<
  { deletePausePolicy: boolean },
  IdInput
> = gql`
  mutation deletePausePolicy($id: Int!) {
    deletePausePolicy(id: $id)
  }
`;

export const CREATE_PRICING_RULE: TypedDocumentNode<
  { createPricingRule: AdminPricingRule },
  AdminPricingRuleCreateArgs
> = gql`
  mutation createPricingRule($data: AdminPricingRuleCreateInput!) {
    createPricingRule(data: $data) {
      id
    }
  }
`;

export const UPDATE_PRICING_RULE: TypedDocumentNode<
  { updatePricingRule: AdminPricingRule },
  AdminPricingRuleUpdateArgs
> = gql`
  mutation updatePricingRule($id: Int!, $data: AdminPricingRuleUpdateInput!) {
    updatePricingRule(id: $id, data: $data) {
      id
      unitPrice
    }
  }
`;

export const DELETE_PRICING_RULE: TypedDocumentNode<
  { deletePricingRule: boolean },
  IdInput
> = gql`
  mutation deletePricingRule($id: Int!) {
    deletePricingRule(id: $id)
  }
`;

export const CREATE_USER_FOR_CUSTOMER: TypedDocumentNode<
  { createUserForCustomer: AdminCustomer },
  IdInput
> = gql`
  mutation createUserForCustomer($id: Int!) {
    createUserForCustomer(id: $id) {
      id
    }
  }
`;

export const RESET_USER_PASSWORD: TypedDocumentNode<
  { resetUserPassword: AdminUser },
  IdInput
> = gql`
  mutation resetUserPassword($id: Int!) {
    resetUserPassword(id: $id) {
      id
    }
  }
`;

export const UPDATE_USER_EMAIL: TypedDocumentNode<
  { updateUserEmail: AdminUser },
  AdminUserUpdateEmailArgs
> = gql`
  mutation updateUserEmail($id: Int!, $email: String!) {
    updateUserEmail(id: $id, email: $email) {
      id
    }
  }
`;

export const MOVE_PRODUCT_PRICING_CATEGORY: TypedDocumentNode<
  { moveProductPricingCategory: AdminPricingCategory[] },
  AdminPricingCategoryMoveProductArgs
> = gql`
  mutation moveProductPricingCategory(
    $oldPricingCategoryId: Int!
    $newPricingCategoryId: Int!
    $productId: Int!
  ) {
    moveProductPricingCategory(
      oldPricingCategoryId: $oldPricingCategoryId
      newPricingCategoryId: $newPricingCategoryId
      productId: $productId
    ) {
      id
      products {
        id
        platform {
          id
          title
        }
      }
    }
  }
`;

// notes

export const CREATE_NOTE_CUSTOMER: TypedDocumentNode<
  { createNoteCustomer: AdminEventNote },
  AdminEventCustomerNoteCreateArgs
> = gql`
  mutation createNoteCustomer($data: AdminEventCustomerNoteCreateData!) {
    createNoteCustomer(data: $data) {
      id
      content
    }
  }
`;

export const UPDATE_NOTE_CUSTOMER: TypedDocumentNode<
  { updateNoteCustomer: AdminEventNote },
  AdminEventNoteUpdateArgs
> = gql`
  mutation updateNoteCustomer($id: Int!, $data: AdminEventNoteUpdateData!) {
    updateNoteCustomer(id: $id, data: $data) {
      id
      content
      timelineEntry {
        type
      }
    }
  }
`;

export const CREATE_NOTE_ORDER: TypedDocumentNode<
  { createNoteOrder: AdminEventNote },
  AdminEventOrderNoteCreateArgs
> = gql`
  mutation createNoteOrder($data: AdminEventOrderNoteCreateData!) {
    createNoteOrder(data: $data) {
      id
      content
    }
  }
`;

export const UPDATE_NOTE_ORDER: TypedDocumentNode<
  { updateNoteOrder: AdminEventNote },
  AdminEventNoteUpdateArgs
> = gql`
  mutation updateNoteOrder($id: Int!, $data: AdminEventNoteUpdateData!) {
    updateNoteOrder(id: $id, data: $data) {
      id
      content
      timelineEntry {
        type
      }
    }
  }
`;

export const CREATE_NOTE_LEAD: TypedDocumentNode<
  { createNoteOrder: AdminEventNote },
  AdminEventLeadNoteCreateArgs
> = gql`
  mutation createNoteLead($data: AdminEventLeadNoteCreateData!) {
    createNoteLead(data: $data) {
      id
      content
    }
  }
`;

export const UPDATE_NOTE_LEAD: TypedDocumentNode<
  { updateNoteLead: AdminEventNote },
  AdminEventNoteUpdateArgs
> = gql`
  mutation updateNoteLead($id: Int!, $data: AdminEventNoteUpdateData!) {
    updateNoteLead(id: $id, data: $data) {
      id
      content
      timelineEntry {
        type
      }
    }
  }
`;

export const CREATE_LOG_CUSTOMER: TypedDocumentNode<
  { createLogCustomer: AdminEventLog },
  AdminCustomerLogEventCreateArgs
> = gql`
  mutation createLogCustomer($data: AdminCustomerLogEventCreateInput!) {
    createLogCustomer(data: $data) {
      id
    }
  }
`;

export const UPDATE_LOG_CUSTOMER: TypedDocumentNode<
  { updateLogCustomer: AdminEventLog },
  AdminLogEventUpdateArgs
> = gql`
  mutation updateLogCustomer($id: Int!, $data: AdminLogEventUpdateInput!) {
    updateLogCustomer(id: $id, data: $data) {
      id
      description
      date
      callOutcome
      timelineEntry {
        type
      }
    }
  }
`;

// locations

export const CREATE_LOCATION = gql`
  mutation createLocation($data: AdminLocationCreateInput!) {
    createLocation(data: $data) {
      id
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: Int!, $data: AdminLocationUpdateInput!) {
    updateLocation(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: Int!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;

// orders

export const UPDATE_ORDER: TypedDocumentNode<
  { updateOrder: AdminOrder },
  AdminOrderUpdateArgs
> = gql`
  mutation updateOrder($id: Int!, $data: AdminOrderUpdateInput!) {
    updateOrder(id: $id, data: $data) {
      id
      powerOfAttorney
      isBillingPaused
      customerConfirmation
      commission
      source
      paymentMethod
      removalAssistant {
        id
      }
      salesResponsible {
        id
      }
      hasInstallmentPayments
      paymentSummary {
        openCount
        lostCount
        billableCurrentCount
        billableAllTimeCount
        unitPrice
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($id: Int!, $data: AdminOrderCancelInput!) {
    cancelOrder(id: $id, data: $data) {
      id
    }
  }
`;

// order cancellation

export const UPDATE_ORDER_CANCELLATION = gql`
  mutation updateOrderCancellation(
    $id: Int!
    $data: AdminOrderCancellationUpdateInput!
  ) {
    updateOrderCancellation(id: $id, data: $data) {
      id
    }
  }
`;

// billing address

export const CREATE_BILLING_ADDRESS: TypedDocumentNode<
  { createBillingAddress: AdminBillingAddress },
  AdminBillingAddressCreateArgs
> = gql`
  mutation createBillingAddress($data: AdminBillingAddressCreateInput!) {
    createBillingAddress(data: $data) {
      id
      companyName
      vat
      firstName
      lastName
      address
      city
      zipCode
      country
      crefo
      customInvoiceText
      customDueDays
      email
      emailCC
    }
  }
`;

export const UPDATE_BILLING_ADDRESS: TypedDocumentNode<
  { updateBillingAddress: AdminBillingAddress },
  AdminBillingAddressUpdateArgs
> = gql`
  mutation updateBillingAddress(
    $id: Int!
    $data: AdminBillingAddressUpdateInput!
  ) {
    updateBillingAddress(id: $id, data: $data) {
      id
      companyName
      vat
      firstName
      lastName
      address
      city
      zipCode
      country
      crefo
      customInvoiceText
      customDueDays
      email
      emailCC
      gender
    }
  }
`;

export const DELETE_BILLING_ADDRESS: TypedDocumentNode<
  { deleteBillingAddress: boolean },
  IdInput
> = gql`
  mutation deleteBillingAddress($id: Int!) {
    deleteBillingAddress(id: $id)
  }
`;

// online profiles

export const CREATE_ONLINE_PROFILE: TypedDocumentNode<
  { createOnlineProfile: AdminOnlineProfile },
  AdminOnlineProfileCreateArgs
> = gql`
  mutation createOnlineProfile($data: AdminOnlineProfileCreateInput!) {
    createOnlineProfile(data: $data) {
      id
    }
  }
`;

export const UPDATE_ONLINE_PROFILE = gql`
  mutation updateOnlineProfile(
    $id: Int!
    $data: AdminOnlineProfileUpdateInput!
  ) {
    updateOnlineProfile(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_ONLINE_PROFILE = gql`
  mutation deleteOnlineProfile($id: Int!) {
    deleteOnlineProfile(id: $id) {
      id
    }
  }
`;

export const SCAN_ONLINE_PROFILE: TypedDocumentNode<{
  crawlOnlineProfile: boolean;
}> = gql`
  mutation crawlOnlineProfile($id: String!) {
    crawlOnlineProfile(id: $id)
  }
`;

// removal submission

export const UPDATE_REMOVAL_SUBMISSION: TypedDocumentNode<
  { updateRemovalSubmission: AdminRemovalSubmission },
  AdminRemovalSubmissionUpdateArgs
> = gql`
  mutation updateRemovalSubmission(
    $id: Int!
    $data: AdminRemovalSubmissionUpdateInput!
  ) {
    updateRemovalSubmission(id: $id, data: $data) {
      id
      referenceNumber
      proofVideo
      proofScreenshot
      entries {
        id
        status
      }
    }
  }
`;

export const UPDATE_REMOVAL_SUBMISSION_LAWYER_TEXTS = gql`
  mutation updateRemovalSubmissionLawyerTexts($id: Int!, $lawyer: IdInput!) {
    updateRemovalSubmissionLawyerTexts(id: $id, lawyer: $lawyer) {
      id
      entries {
        id
        removalRequest
      }
    }
  }
`;

export const SET_REMOVAL_SUBMISSION_EXTRA_ON_HOLD: TypedDocumentNode<
  { removalSubmissionExtractOnHold: AdminRemovalSubmission },
  IdInput
> = gql`
  mutation removalSubmissionExtractOnHold($id: Int!) {
    removalSubmissionExtractOnHold(id: $id) {
      id
    }
  }
`;

export const UPDATE_REMOVAL_SUBMISSION_LAWYER: TypedDocumentNode<
  { updateRemovalSubmissionLawyer: AdminRemovalSubmission },
  AdminRemovalSubmissionLawyerUpdateArgs
> = gql`
  mutation updateRemovalSubmissionLawyer(
    $id: Int!
    $data: AdminRemovalSubmissionLawyerUpdateInput!
  ) {
    updateRemovalSubmissionLawyer(id: $id, data: $data) {
      id
    }
  }
`;

// removal entry

export const ADD_REMOVAL_ENTRY = gql`
  mutation createRemovalEntry(
    $submission: IdInput!
    $data: AdminRemovalEntryCreateSingleInput!
  ) {
    createRemovalEntry(submission: $submission, data: $data) {
      id
    }
  }
`;

// timeline entry

export const UPDATE_TIMELINE_ENTRY: TypedDocumentNode<
  {
    updateTimelineEntry: AdminTimelineEntry;
  },
  AdminTimelineEntryUpdateArgs
> = gql`
  mutation updateTimelineEntry(
    $id: Int!
    $data: AdminTimelineEntryUpdateInput!
  ) {
    updateTimelineEntry(id: $id, data: $data) {
      id
      isPinned
    }
  }
`;

export const DELETE_TIMELINE_ENTRY: TypedDocumentNode<
  {
    deleteTimelineEntry: Pick<AdminTimelineEntry, 'id'>;
  },
  IdInput
> = gql`
  mutation deleteTimelineEntry($id: Int!) {
    deleteTimelineEntry(id: $id) {
      id
    }
  }
`;

// orders

export const CREATE_ORDER: TypedDocumentNode<
  { createOrder: AdminOrder },
  AdminOrderCreateArgs
> = gql`
  mutation createOrder($data: AdminOrderCreateInput!) {
    createOrder(data: $data) {
      id
      type
      payments {
        id
        status
      }
      customer {
        id
        powerOfAttorney
      }
    }
  }
`;

export const UPDATE_REMOVAL_ENTRY: TypedDocumentNode<
  { updateRemovalEntry: AdminRemovalEntry },
  AdminRemovalEntryUpdateArgs
> = gql`
  mutation updateRemovalEntry($id: Int!, $data: AdminRemovalEntryUpdateInput!) {
    updateRemovalEntry(id: $id, data: $data) {
      id
      status
      reviewerName
      reviewerLink
      reviewText
      removalRequest
      comment
      noText
      pseudonym
      recent
      notForwardedAt
    }
  }
`;

export const SEND_ORDER_CONFIRMATION_EMAIL: TypedDocumentNode<
  { sendEmailOrderConfirmation: Pick<AdminOrder, 'id'> },
  AdminOrderEmailArgs
> = gql`
  mutation sendEmailOrderConfirmation($order: IdInput!, $data: EmailInput!) {
    sendEmailOrderConfirmation(order: $order, data: $data) {
      id
    }
  }
`;

export const SEND_OWNER_COMMENTS_REMINER_EMAIL: TypedDocumentNode<
  { sendEmailOwnerCommentsReminder: Pick<AdminOrder, 'id'> },
  AdminOrderEmailArgs
> = gql`
  mutation sendEmailOwnerCommentsReminder(
    $order: IdInput!
    $data: EmailInput!
  ) {
    sendEmailOwnerCommentsReminder(order: $order, data: $data) {
      id
    }
  }
`;

export const UPDATE_REMOVAL_ENTRY_STATUS: TypedDocumentNode<
  {
    updateRemovalEntryStatus: AdminRemovalEntry;
  },
  AdminRemovalEntryUpdateStatusArgs
> = gql`
  mutation updateRemovalEntryStatus($id: Int!, $status: RemovalEntryStatus!) {
    updateRemovalEntryStatus(id: $id, status: $status) {
      id
      status
      transitionHistory {
        id
        statusEnd
        staff {
          id
          name
        }
      }
    }
  }
`;

export const SEND_POWER_OF_ATTORNEY_EMAIL: TypedDocumentNode<
  { sendEmailPowerOfAttorneyGoogle: Pick<AdminCustomer, 'id'> },
  AdminCustomerSendPowerOfAttorneyArgs
> = gql`
  mutation sendEmailPowerOfAttorneyGoogle(
    $customer: IdInput!
    $data: EmailInput!
  ) {
    sendEmailPowerOfAttorneyGoogle(customer: $customer, data: $data) {
      id
    }
  }
`;

export const SEND_POWER_OF_ATTORNEY_ORDER_EMAIL: TypedDocumentNode<
  { sendEmailPowerOfAttorney: Pick<AdminOrder, 'id'> },
  AdminOrderEmailArgs
> = gql`
  mutation sendEmailPowerOfAttorney($order: IdInput!, $data: EmailInput!) {
    sendEmailPowerOfAttorney(order: $order, data: $data) {
      id
    }
  }
`;

export const BULK_UPDATE_REMOVAL_ENTRY_STATUS = gql`
  mutation bulkUpdateRemovalEntryStatus(
    $id: IntFilter!
    $status: RemovalEntryStatus!
  ) {
    bulkUpdateRemovalEntryStatus(id: $id, status: $status) {
      id
      status
      reviewerName
      reviewerLink
      reviewText
      removalRequest
      comment
      noText
      pseudonym
      recent
      notForwardedAt
      transitionHistory {
        id
        statusEnd
        staff {
          id
          name
        }
      }
    }
  }
`;

// payments

export const CREATE_PAYMENT = gql`
  mutation createPayment($data: AdminPaymentCreateInput!) {
    createPayment(data: $data) {
      id
    }
  }
`;

export const CREATE_INSTALLMENT_PAYMENTS = gql`
  mutation createInstallmentPayments(
    $data: AdminInstallmentPaymentsCreateInput!
  ) {
    createInstallmentPayments(data: $data) {
      id
    }
  }
`;

export const CREATE_INVOICE_FOR_PAYMENT: TypedDocumentNode<
  { createInvoice: Pick<AdminPayment, 'id' | 'invoiceId'> },
  AdminPaymentCreateInvoiceArgs
> = gql`
  mutation createInvoice($id: Int!) {
    createInvoice(id: $id) {
      id
      invoiceId
      invoiceUrl
    }
  }
`;

export const CREATE_STORNO_PAYMENT = gql`
  mutation createStornoPayment($id: Int!) {
    createStornoPayment(id: $id) {
      id
      invoiceId
      invoiceUrl
    }
  }
`;

export const CREATE_BULK_STORNO_PAYMENT = gql`
  mutation createBulkStornoPayment($id: Int!) {
    createBulkStornoPayment(id: $id) {
      id
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: Int!, $data: AdminPaymentUpdateInput!) {
    updatePayment(id: $id, data: $data) {
      id
      status
    }
  }
`;

export const SEND_INVOICE_EMAIL: TypedDocumentNode<
  { sendEmailInvoice: Pick<AdminPayment, 'id'> },
  AdminPaymentSendEmailArgs
> = gql`
  mutation sendEmailInvoice($payment: IdInput!, $data: EmailInput!) {
    sendEmailInvoice(payment: $payment, data: $data) {
      id
    }
  }
`;

export const AUTOMATE_INVOICING: TypedDocumentNode<
  { automateInvoicing: boolean },
  AdminOrderAutomateInvoicingArgs
> = gql`
  mutation automateInvoicing($id: Int!, $amount: Float!) {
    automateInvoicing(id: $id, amount: $amount)
  }
`;

export const SEND_REMINDER_EMAIL_1: TypedDocumentNode<
  { sendEmailReminder: Pick<AdminPayment, 'id'> },
  AdminPaymentSendEmailArgs
> = gql`
  mutation sendEmailReminder1($payment: IdInput!, $data: EmailInput!) {
    sendEmailReminder1(payment: $payment, data: $data) {
      id
    }
  }
`;

export const SEND_REMINDER_EMAIL_2: TypedDocumentNode<
  { sendEmailReminder: Pick<AdminPayment, 'id'> },
  AdminPaymentSendEmailArgs
> = gql`
  mutation sendEmailReminder2($payment: IdInput!, $data: EmailInput!) {
    sendEmailReminder2(payment: $payment, data: $data) {
      id
    }
  }
`;

// payouts
export const CREATE_PAYOUT = gql`
  mutation createPayout($data: AdminPayoutCreateInput!) {
    createPayout(data: $data) {
      id
    }
  }
`;

// lead
export const UPDATE_LEAD: TypedDocumentNode<
  {
    updateLead: AdminLead;
  },
  AdminLeadUpdateArgs
> = gql`
  mutation updateLead($id: Int!, $data: AdminLeadUpdateInput!) {
    updateLead(id: $id, data: $data) {
      id
    }
  }
`;

export const SAVE_LOST_LEAD_INFO = gql`
  mutation createLeadLoss($id: Int!, $data: AdminLeadLossInput!) {
    createLeadLoss(id: $id, data: $data) {
      id
    }
  }
`;

export const CREATE_LOG_LEAD: TypedDocumentNode<
  {
    createLogLead: AdminEventLog;
  },
  AdminLeadLogEventCreateArgs
> = gql`
  mutation createLogLead($data: AdminLeadLogEventCreateInput!) {
    createLogLead(data: $data) {
      id
    }
  }
`;

export const UPDATE_LOG_LEAD: TypedDocumentNode<
  {
    updateLogLead: AdminEventLog;
  },
  AdminLogEventUpdateArgs
> = gql`
  mutation updateLogLead($id: Int!, $data: AdminLogEventUpdateInput!) {
    updateLogLead(id: $id, data: $data) {
      id
      description
      date
      callOutcome
      timelineEntry {
        type
      }
    }
  }
`;

export const ALLOCATE_LEAD: TypedDocumentNode<{
  allocateLead: AdminLead;
}> = gql`
  mutation allocateLead {
    allocateLead {
      id
    }
  }
`;

export const IMPORT_GOOGLE_LEAD: TypedDocumentNode<
  { importGoogleLead: boolean },
  { placeId: string }
> = gql`
  mutation importGoogleLead($placeId: String!) {
    importGoogleLead(placeId: $placeId)
  }
`;

export const BULK_UPDATE_LEADS_KEY_ACCOUNT: TypedDocumentNode<
  { bulkUpdateLeadsKeyAccount: number },
  Pick<AdminLeadArgs, 'where'>
> = gql`
  mutation bulkUpdateLeadsKeyAccount($where: AdminLeadWhereInput) {
    bulkUpdateLeadsKeyAccount(where: $where)
  }
`;

export const UPDATE_LEAD_CATEGORY: TypedDocumentNode = gql`
  mutation updateLeadCategory(
    $name: String!
    $active: Boolean
    $allocationMinReviews: Int
    $allocationMaxScore: Float
  ) {
    updateLeadCategory(
      name: $name
      active: $active
      allocationMinReviews: $allocationMinReviews
      allocationMaxScore: $allocationMaxScore
    ) {
      name
      active
      allocationMinReviews
      allocationMaxScore
    }
  }
`;

export const MUTATE_LEAD_ALLOCATION_RULE_REGION: TypedDocumentNode<
  { updateLeadAllocationRuleRegion: AdminLeadAllocationRuleRegion },
  AdminLeadAllocationRuleRegionUpdateArgs
> = gql`
  mutation updateLeadAllocationRuleRegion(
    $id: Int!
    $data: AdminLeadAllocationRuleRegionUpdateInput!
  ) {
    updateLeadAllocationRuleRegion(id: $id, data: $data) {
      id
      region
      active
    }
  }
`;

export const SEND_GMAIL: TypedDocumentNode<AdminSendGmailArgs> = gql`
  mutation sendGmail(
    $lead: IdInput
    $customer: IdInput
    $offer: IdInput
    $order: IdInput
    $selectedTemplate: Int
    $includeSignature: Boolean!
    $data: EmailInput!
  ) {
    sendGmail(
      lead: $lead
      customer: $customer
      offer: $offer
      order: $order
      selectedTemplate: $selectedTemplate
      includeSignature: $includeSignature
      data: $data
    ) {
      id
    }
  }
`;

export const REPLY_OR_FORWARD_GMAIL: TypedDocumentNode<AdminReplyOrForwardGmailArgs> = gql`
  mutation replyOrForwardGmail(
    $lead: IdInput
    $customer: IdInput
    $selectedTemplate: Int
    $includeSignature: Boolean!
    $data: EmailReplyOrForwardInput!
  ) {
    replyOrForwardGmail(
      lead: $lead
      customer: $customer
      selectedTemplate: $selectedTemplate
      includeSignature: $includeSignature
      data: $data
    ) {
      id
    }
  }
`;

export const CREATE_OFFLINE_CAMPAIGN: TypedDocumentNode<
  { createOfflineCampaign: AdminLeadOfflineCampaign },
  AdminOfflineCampaignArgs
> = gql`
  mutation createOfflineCampaign($data: AdminLeadOfflineCampaignCreateInput!) {
    createOfflineCampaign(data: $data) {
      id
    }
  }
`;

// reports
export const MARK_NOT_FORWARDED_REPORT: TypedDocumentNode<
  {
    markNotForwardedReport: number;
  },
  { file: any }
> = gql`
  mutation($file: Upload!) {
    markNotForwardedReport(file: $file)
  }
`;

export const UPDATE_REMOVAL_ENTRIES_FROM_CSV: TypedDocumentNode<
  { updateRemovalEntryStatusFromCSV: AdminUpdateFromCSV },
  { file: any }
> = gql`
  mutation($file: Upload!) {
    updateRemovalEntryStatusFromCSV(file: $file) {
      updatedCount
      missingReferenceNumbers
    }
  }
`;

export const UPDATE_PAYMENT_STATUS_FROM_CSV: TypedDocumentNode<
  { updatePaymentStatusFromCSV: AdminUpdateFromCSV },
  { file: any }
> = gql`
  mutation($file: Upload!) {
    updatePaymentStatusFromCSV(file: $file) {
      updatedCount
      missingReferenceNumbers
    }
  }
`;

// tasks
export const CREATE_TASK_CUSTOMER: TypedDocumentNode<
  {
    createTaskCustomer: AdminTask;
  },
  AdminTaskCustomerCreateArgs
> = gql`
  mutation createTaskCustomer($data: AdminTaskCustomerCreateInput!) {
    createTaskCustomer(data: $data) {
      id
    }
  }
`;

export const UPDATE_TASK_CUSTOMER: TypedDocumentNode<
  {
    updateTaskCustomer: AdminTask;
  },
  AdminTaskUpdateArgs
> = gql`
  mutation updateTaskCustomer($id: Int!, $data: AdminTaskUpdateInput!) {
    updateTaskCustomer(id: $id, data: $data) {
      id
      title
      dueAt
      isCompleted
      note
      assignee {
        id
        name
      }
      timelineEntry {
        type
      }
    }
  }
`;

export const CREATE_TASK_ORDER: TypedDocumentNode<
  {
    createTaskOrder: AdminTask;
  },
  AdminTaskOrderCreateArgs
> = gql`
  mutation createTaskOrder($data: AdminTaskOrderCreateInput!) {
    createTaskOrder(data: $data) {
      id
    }
  }
`;

export const UPDATE_TASK_ORDER: TypedDocumentNode<
  {
    updateTaskOrder: AdminTask;
  },
  AdminTaskUpdateArgs
> = gql`
  mutation updateTaskOrder($id: Int!, $data: AdminTaskUpdateInput!) {
    updateTaskOrder(id: $id, data: $data) {
      id
      title
      dueAt
      isCompleted
      note
      assignee {
        id
        name
      }
      timelineEntry {
        type
      }
    }
  }
`;

export const CREATE_TASK_LEAD: TypedDocumentNode<
  {
    createTaskLead: AdminTask;
  },
  AdminTaskLeadCreateArgs
> = gql`
  mutation createTaskLead($data: AdminTaskLeadCreateInput!) {
    createTaskLead(data: $data) {
      id
    }
  }
`;

export const UPDATE_TASK_LEAD: TypedDocumentNode<
  {
    updateTaskLead: AdminTask;
  },
  AdminTaskUpdateArgs
> = gql`
  mutation updateTaskLead($id: Int!, $data: AdminTaskUpdateInput!) {
    updateTaskLead(id: $id, data: $data) {
      id
      title
      dueAt
      isCompleted
      note
      assignee {
        id
        name
      }
      timelineEntry {
        type
      }
    }
  }
`;

export const COMPLETE_TASK: TypedDocumentNode<
  {
    completeTask: AdminTask;
  },
  IdInput
> = gql`
  mutation completeTask($id: Int!) {
    completeTask(id: $id) {
      id
    }
  }
`;

export const START_OUTBOUND_CALL: TypedDocumentNode<AdminStartOutboundCallArgs> = gql`
  mutation startOutboundCall($lead: IdInput, $customer: IdInput) {
    startOutboundCall(lead: $lead, customer: $customer) {
      id
    }
  }
`;

export const TOGGLE_TEMPLATE_VISIBILITY: TypedDocumentNode<AdminEmailTemplateFindArgs> = gql`
  mutation toggleTemplateVisibility(
    $emailTemplate: IdInput!
    $isHidden: Boolean!
  ) {
    toggleTemplateVisibility(
      emailTemplate: $emailTemplate
      isHidden: $isHidden
    ) {
      id
      isHiddenForMe
    }
  }
`;

export const CREATE_OFFER: TypedDocumentNode<
  { createOffer: AdminOffer },
  AdminOfferCreateArgs
> = gql`
  mutation createOffer($data: AdminOfferCreateInput!) {
    createOffer(data: $data) {
      id
      slug
      gender
      companyName
      lastName
      expiresInDays
      expiresAt
      customer {
        id
      }
      lineItems {
        id
        title
        scanCacheId
        reviewIds
        mapsLink
        onlineProfileId
        platformId
        unitPrice
        listPrice
      }
      status
      reviewCount
      locationsCount
      totalAmount
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_OFFER: TypedDocumentNode<
  { updateOffer: AdminOffer },
  AdminOfferUpdateArgs
> = gql`
  mutation updateOffer($id: Int!, $data: AdminOfferUpdateInput!) {
    updateOffer(id: $id, data: $data) {
      id
      slug
      gender
      companyName
      lastName
      expiresInDays
      expiresAt
      customer {
        id
      }
      lineItems {
        id
        title
        scanCacheId
        reviewIds
        mapsLink
        onlineProfileId
        platformId
        unitPrice
        listPrice
      }
      status
      reviewCount
      locationsCount
      totalAmount
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_OFFER_STATUS: TypedDocumentNode<
  { updateOfferStatus: AdminOffer },
  AdminOfferUpdateStatusArgs
> = gql`
  mutation updateOfferStatus($id: Int!, $status: OfferStatus!) {
    updateOfferStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`;

export const SCAN_PLACE_ID: TypedDocumentNode<
  { scanPlaceId: { id: string } },
  { placeId: string }
> = gql`
  mutation scanPlaceId($placeId: String!) {
    scanPlaceId(placeId: $placeId) {
      id
    }
  }
`;

export const SCAN_LINK: TypedDocumentNode<
  { scanLink: { id: string } },
  { platformSlug: string; link: string }
> = gql`
  mutation scanLink($platformSlug: String!, $link: String!) {
    scanLink(platformSlug: $platformSlug, link: $link) {
      id
    }
  }
`;

export const UPDATE_SCAN_CACHE: TypedDocumentNode<
  { updateScanCache: boolean },
  { id: string }
> = gql`
  mutation updateScanCache($id: String!) {
    updateScanCache(id: $id) {
      id
    }
  }
`;

// squads

export const UPDATE_SQUAD_LEAD = gql`
  mutation updateSquadLead($id: Int!, $staffId: Int) {
    updateSquadLead(id: $id, staffId: $staffId) {
      id
      lead {
        id
      }
    }
  }
`;
