export enum Permission {
  // settings
  readStaff = 'readStaff',
  writeStaff = 'writeStaff',
  readRoles = 'readRoles',
  writeRoles = 'writeRoles',
  readTemplates = 'readTemplates',
  writeTemplates = 'writeTemplates',
  readProducts = 'readProducts',
  writeProducts = 'writeProducts',
  readLawyers = 'readLawyers',
  writeLawyers = 'writeLawyers',
  // customers
  readOwnCustomers = 'readOwnCustomers',
  readCustomers = 'readCustomers',
  writeCustomers = 'writeCustomers',
  writeCustomerManager = 'writeCustomerManager',
  writeCustomerPricing = 'writeCustomerPricing',
  // orders
  readOwnOrders = 'readOwnOrders',
  readOrders = 'readOrders',
  writeOrders = 'writeOrders',
  createInvoice = 'createInvoice',
  sendEmail = 'sendEmail',
  automateInvoicing = 'automateInvoicing',
  // offline campaigns
  offlineCampaigns = 'offlineCampaigns',
  // stats
  readOwnSalesStats = 'readOwnSalesStats',
  readSalesStats = 'readSalesStats',
  // leads
  readOwnLeads = 'readOwnLeads',
  readLeads = 'readLeads',
  readPeerLeads = 'readPeerLeads',
  searchLeads = 'searchLeads',
  allocateLead = 'allocateLead',
  importLeads = 'importLeads',
  writeLeads = 'writeLeads',
  bulkWriteLeads = 'bulkWriteLeads',
  writeLeadManager = 'writeLeadManager',
  writeLeadIsKeyAccount = 'writeLeadIsKeyAccount',
  //timeline entries
  destroyEntry = 'destroyEntry',
  // payouts
  writePayouts = 'writePayouts',
  // partners
  readPartners = 'readPartners',
  scanPartners = 'scanPartners',
  // offers
  readOwnOffers = 'readOwnOffers',
  readPeerOffers = 'readPeerOffers',
  readOffers = 'readOffers',
  writeOffers = 'writeOffers',
  // tasks
  readAllTasks = 'readAllTasks',
}

export enum StaticRoles {
  admin = 1,
  accountManager,
  salesManager,
  orderAssistant,
  dataTransferAssistant,
  squadLead,
}

export enum CallDirection {
  inbound = 'inbound',
  outbound = 'outbound',
}

export enum Gender {
  male = 'male',
  female = 'female',
  other = 'other',
}

export enum EmailTemplate {
  orderConfirmation = 1,
  invoice,
  storno,
  credit,
  paymentReminder1,
  paymentReminder2,
  powerOfAttorney,
  powerOfAttorneyGoogle,
  ownerCommentsReminder,
  customerPortalCreds = 26,
}

export enum TemplateVariableKey {
  companyName = 'companyName',
  companyAddress = 'companyAddress',
  companyZipCode = 'companyZipCode',
  companyCity = 'companyCity',
  companyCountry = 'companyCountry',
  companyVAT = 'companyVAT',
  companyWebsite = 'companyWebsite',
  companyEmail = 'companyEmail',
  companyPhone = 'companyPhone',
  companyCEO = 'companyCEO',
  companyRegistrationCourt = 'companyRegistrationCourt',
  companyRegistrationNumber = 'companyRegistrationNumber',
  paymentAccountHolder = 'paymentAccountHolder',
  paymentBankName = 'paymentBankName',
  paymentIBAN = 'paymentIBAN',
  paymentBIC = 'paymentBIC',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum StringFilterMode {
  default = 'default',
  insensitive = 'insensitive',
}

// NOTE: order of listed enum values is important for TimelineFragment
export enum EventType {
  update = 'update',
  note = 'note',
  task = 'task',
  logMail = 'logMail',
  logCall = 'logCall',
  mail = 'mail',
  call = 'call',
}

export enum ProductType {
  removal = 'removal',
  listingRemoval = 'listingRemoval',
  localBoost = 'localBoost',
}

export enum RemovalEntryStatus {
  open = 'open',
  onHold = 'onHold',
  initiated = 'initiated',
  forwarded = 'forwarded',
  removed = 'removed',
  declined = 'declined',
  answer = 'answer',
  cancelled = 'cancelled',
}

export enum RemovalOrderStatus {
  open = 'open',
  onHold = 'onHold',
  initiated = 'initiated',
  forwarded = 'forwarded',
  partiallyCompleted = 'partiallyCompleted',
  partiallyForwarded = 'partiallyForwarded',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum PaymentStatus {
  new = 'new',
  invoiceSent = 'invoiceSent',
  paid = 'paid',
  m1 = 'm1',
  c1 = 'c1',
  c2 = 'c2',
  m2 = 'm2',
  c3 = 'c3',
  inkasso = 'inkasso',
  cancelled = 'cancelled',
  refunded = 'refunded',
  voided = 'voided',
}

export enum PaymentType {
  single = 'single',
  pre = 'pre',
  partial = 'partial',
  final = 'final',
  storno = 'storno',
  credit = 'credit',
  installment = 'installment',
}

export enum OrderStatus {
  inProgress = 'inProgress',
  completed = 'completed',
  cancelled = 'cancelled',
}

// Union Type
export const OrderTypeStatus = {
  ...RemovalOrderStatus,
};
export type OrderTypeStatus = typeof OrderTypeStatus;

export enum OrderSource {
  coldCall = 'coldCall',
  secondSale = 'secondSale',
  direct = 'direct',
  support = 'support',
  recommendation = 'recommendation',
  partner = 'partner',
  onlineAds = 'onlineAds',
  newsletter = 'newsletter',
  customerPortal = 'customerPortal',
  websiteChat = 'websiteChat',
  coldEmail = 'coldEmail',
}

export enum SalesStatsPeriodOption {
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum KPI {
  sales = 'sales',
  paid = 'paid',
  avgRemovalPrice = 'avgRemovalPrice',
}

export enum AdminLeadStatus {
  new = 'new',
  inContact = 'inContact',
  appointment = 'appointment',
  onHold = 'onHold',
  proposal = 'proposal',
  won = 'won',
  lost = 'lost',
  rescue = 'rescue',
}

export enum CallOutcome {
  busy = 'busy',
  couldNotReachDecider = 'couldNotReachDecider',
  connected = 'connected',
  leftVoicemail = 'leftVoicemail',
  noAnswer = 'noAnswer',
  wrongNumber = 'wrongNumber',
}

export enum ArchivedPaymentStatus {
  paid = 'paid',
  cancelled = 'cancelled',
}
export enum ArchivedOrderStatus {
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum MailgunEventStatus {
  delivered = 'delivered',
  processing = 'processing',
  failed = 'failed',
  opened = 'opened',
}

export enum CallDirectionOptions {
  outbound = 'outbound',
  inbound = 'inbound',
  all = 'all',
}

export enum EmailOptions {
  sent = 'sent',
  received = 'received',
  all = 'all',
}

export enum CallDurationOptions {
  over1Minute = 'over1Minute',
  all = 'all',
}

export enum EditInputType {
  textArea = 'textArea',
  input = 'input',
  select = 'select',
}

export enum PaymentMethod {
  crefoFactoring = 'crefoFactoring',
  factoring = 'factoring',
  direct = 'direct',
  lsv = 'lsv',
}

export enum OfferStatus {
  pending = 'pending',
  accepted = 'accepted',
  cancelled = 'cancelled',
  expired = 'expired',
}
