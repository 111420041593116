import React, { useContext, useState } from 'react';

type Props = {
  children: React.ReactNode;
};

type ContextProps = {
  authenticated: boolean | undefined;
  setAuthenticated: (isAuthenticated: boolean) => void;
};

export const AuthenticatedContext = React.createContext<ContextProps>({
  authenticated: undefined,
  setAuthenticated: () => null,
});

export const AuthenticatedProvider = ({ children }: Props) => {
  const [authenticated, setAuthenticated] = useState<boolean | undefined>();

  return (
    <AuthenticatedContext.Provider value={{ setAuthenticated, authenticated }}>
      {children}
    </AuthenticatedContext.Provider>
  );
};

export function useAuthenticatedContext() {
  return useContext(AuthenticatedContext);
}
