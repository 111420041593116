import { usePrevious } from 'admin/hooks/usePrevious';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

type Props = {
  children: React.ReactNode;
};

/**
 * Gets user preferences from local storage
 * @param {string} key - localStorage key
 * @return {array} getter and setter for user preferred theme
 */
function useStorageTheme(
  key: string
): [string | boolean, React.Dispatch<React.SetStateAction<string | boolean>>] {
  const userPreference =
    !!window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;

  const [theme, setTheme] = useState(
    // use stored theme; fallback to user preference
    localStorage.getItem(key) || userPreference
  );

  // update stored theme
  useEffect(() => {
    localStorage.setItem(key, theme.toString());
  }, [theme, key]);

  return [theme, setTheme];
}

type ThemeContextType = {
  theme?: 'light' | 'dark';
  toggleTheme?: () => void;
};

// create context
export const ThemeContext = React.createContext<ThemeContextType>({});

// create context provider
export const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useStorageTheme('theme');

  // update root element class on theme change
  const oldTheme = usePrevious(theme);
  useLayoutEffect(() => {
    document.documentElement.classList.remove(`theme-${oldTheme}`);
    document.documentElement.classList.add(`theme-${theme}`);
  }, [theme, oldTheme]);

  const toggleTheme = useCallback(() => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      return setTheme('light');
    }
  }, [setTheme, theme]);

  const value = useMemo(
    () => ({
      theme,
      toggleTheme,
    }),
    [theme, toggleTheme]
  ) as ThemeContextType;

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export function useTheme() {
  return useContext(ThemeContext);
}
