import { useLazyQuery } from '@apollo/client';
import * as Icons from 'admin/assets/icons';
import NavigationHeader from 'admin/components/headers/NavigationHeader';
import { CustomBreadcrumbParent } from 'admin/constants/displays';
import { COMPANY } from 'admin/graphql/queries';
import routes from 'admin/routes';
import { last, startCase } from 'lodash';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';

const Breadcrumb = () => {
  const location = useLocation();
  const [customerId, setCustomerId] = useState<number>(-1);
  const [breadcrumbsToDisplay, setBreadcrumbsToDisplay] = useState<
    BreadcrumbData[]
  >([]);

  const [customer, { data: customerData }] = useLazyQuery(COMPANY, {
    variables: {
      id: customerId,
    },
  });

  const breadcrumbs = useBreadcrumbs(routes).slice(2);

  useEffect(() => {
    if (
      customerId !== -1 &&
      (location.pathname.includes(CustomBreadcrumbParent.customers) ||
        location.pathname.includes(CustomBreadcrumbParent.agencies))
    )
      customer();

    if (location.pathname.includes(CustomBreadcrumbParent.places)) {
      return setBreadcrumbsToDisplay([]);
    }

    setBreadcrumbsToDisplay(
      breadcrumbs.map(({ breadcrumb, match, ...rest }) => {
        const breadcrumbArr = (breadcrumb as any).key.split('/');
        const elNumber = last(breadcrumbArr) as number;
        const isElNumber = !isNaN(elNumber);

        if (isElNumber) {
          setCustomerId(Number(elNumber));
        }

        let breadcrumbName;
        if (isElNumber) {
          breadcrumbName = handleNumberBreadcrumb(breadcrumbArr, breadcrumb);
        } else {
          const title = startCase((breadcrumb as ReactElement).props.children);
          if (title.includes('gpt')) {
            breadcrumbName = title.replace('gpt', 'GPT');
          } else {
            breadcrumbName = title;
          }
        }

        return {
          breadcrumb: breadcrumbName,
          match,
          ...rest,
        };
      })
    );
  }, [location, customerId, customerData]);

  const handleNumberBreadcrumb = useCallback(
    (arr: string[], breadcrumb: ReactNode) => {
      const parentKey = arr[arr.length - 1 - 1];

      switch (parentKey) {
        case CustomBreadcrumbParent.customers:
          return customerData?.customer.billingAddress.companyName;
        case CustomBreadcrumbParent.agencies:
          return customerData?.customer.billingAddress.companyName;
        default:
          return breadcrumb;
      }
    },
    [customerData]
  );

  useEffect(() => {
    document.title = `${breadcrumbsToDisplay
      .map(({ breadcrumb }) => {
        if (!breadcrumb) {
          return '';
        }

        if (typeof breadcrumb === 'string') {
          return breadcrumb;
        }

        return (breadcrumb as any).props?.children;
      })
      .filter(Boolean)
      .join(' › ')} | Onno Plus Admin`;
  }, [breadcrumbsToDisplay]);

  if (breadcrumbsToDisplay.some(({ breadcrumb }) => breadcrumb === undefined)) {
    return null;
  }

  return (
    <>
      {breadcrumbsToDisplay.map(({ breadcrumb, match }, index: number) => {
        return (
          <span className="flex items-center" key={index}>
            <NavigationHeader url={match.url}>{breadcrumb}</NavigationHeader>
            {index !== breadcrumbsToDisplay.length - 1 && (
              <Icons.ArrowRightIcon className="w-3 h-3 mx-2" />
            )}
          </span>
        );
      })}
    </>
  );
};

export default Breadcrumb;
