import cx from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  type: 'alert' | 'info';
  className?: string;
};

function NotificationBadge({ type, className, children }: Props) {
  return (
    <div
      className={cx(
        'flex items-center fill-current transition-colors duration-150 justify-center h-5 p-1 text-xs rounded-full min-w-5',
        {
          'text-white bg-red-500 dark:bg-red-700': type === 'alert',
          'border-2': type === 'info',
        },
        className
      )}
      style={{ borderColor: 'currentColor' }}
    >
      {children}
    </div>
  );
}

export default NotificationBadge;
