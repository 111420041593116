import PayoutRelatedTimelineEntries from 'admin/pages/PayoutLogs';
import { lazy } from 'react';

// use lazy for better code splitting, a.k.a. load faster
const Customers = lazy(() => import('../pages/Customers'));
const Potential = lazy(() => import('../pages/Potential'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Customer = lazy(() => import('../pages/Customer'));
const Orders = lazy(() => import('../pages/Orders'));
const Order = lazy(() => import('../pages/Order'));
const Leads = lazy(() => import('../pages/Leads'));
const Tasks = lazy(() => import('../pages/Tasks'));
const Lead = lazy(() => import('../pages/Lead'));
const Place = lazy(() => import('../pages/Place'));
const Settings = lazy(() => import('../pages/Settings'));
const Profile = lazy(() => import('../pages/Profile'));
const Locations = lazy(() => import('../pages/Locations'));
const Leaderboard = lazy(() => import('../pages/Leaderboard'));
const Offers = lazy(() => import('../pages/Offers'));
const OnnoGPT = lazy(() => import('../pages/OnnoGPT'));

/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */

const routes = [
  {
    path: '/dashboard', // the url
    component: Dashboard, // view rendered
  },
  {
    path: '/dashboard/:tab/:fragment?',
    component: Dashboard,
    exact: false,
  },
  {
    path: '/customers',
    component: Customers,
  },
  {
    path: '/agencies',
    component: Customers,
  },
  {
    path: '/potential',
    component: Potential,
  },
  {
    path: '/customers/:id',
    component: Customer,
    exact: false,
  },
  {
    path: '/agencies/:id',
    component: Customer,
    exact: false,
  },
  {
    path: '/orders',
    component: Orders,
  },
  {
    path: '/orders/:tab',
    component: Orders,
  },
  {
    path: '/orders/:tab/:id',
    component: Order,
    exact: false,
  },
  {
    path: '/orders/:tab/:id/:fragment',
    component: Order,
  },
  {
    path: '/leads',
    component: Leads,
  },
  {
    path: '/leads/:id',
    component: Lead,
    exact: false,
  },
  {
    path: '/places/:placeId',
    component: Place,
    exact: false,
  },
  {
    path: '/offers',
    component: Offers,
  },
  {
    path: '/leads/:id/:tab',
    component: Lead,
    exact: false,
  },
  {
    path: '/tasks',
    component: Tasks,
  },
  {
    path: '/tasks/:tab',
    component: Tasks,
    exact: false,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/settings/:tab',
    component: Settings,
    exact: false,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/locations',
    component: Locations,
  },
  {
    path: '/leaderboard',
    component: Leaderboard,
  },
  {
    path: '/payout-logs',
    component: PayoutRelatedTimelineEntries,
  },
  {
    path: '/onnogpt',
    component: OnnoGPT,
  },
  {
    path: '/onnogpt/:tab',
    component: OnnoGPT,
    exact: false,
  },
];

export default routes;
