import de from './de-DE.json';
import enUS from './en-US.json';

export default {
  'en-US': {
    translation: enUS,
  },
  'de-DE': {
    translation: de,
  },
};
