import { ReactNode } from 'react';
import Linkify from 'react-linkify';

type Props = {
  children: ReactNode;
};

function TimelineRow({ children }: Props) {
  const componentDecorator = (href: string, text: string, key: number) => (
    <a
      className="px-1 text-purple-600 dark:text-purple-500 hover:text-purple-800"
      href={href}
      key={key}
      rel="noopener noreferrer"
      target="_blank"
    >
      {text}
    </a>
  );

  return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>;
}

export default TimelineRow;
