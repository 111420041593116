import { useLazyQuery, useMutation } from '@apollo/client';
import { Dropdown, DropdownItem } from '@windmill/react-ui';
import {
  MenuIcon,
  MoonIcon,
  OutlineCogIcon,
  OutlineLogoutIcon,
  SunIcon,
} from 'admin/assets/icons';
import { useAuthenticatedContext } from 'admin/context/AuthenticatedContext';
import { useCurrentUser } from 'admin/context/CurrentUserContext';
import { useSidebarContext } from 'admin/context/SidebarContext';
import { useTheme } from 'admin/context/ThemeContext';
import { LOGOUT } from 'admin/graphql/mutations';
import { GET_ME } from 'admin/graphql/queries';
import { useCurrentRole } from 'admin/hooks/useCurrentRole';
import { clearCacheOnClients } from 'admin/lib/apollo';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CircleAvatar from './icons/CircleAvatar';
import Breadcrumb from './navigation/Breadcrumb';

function Header() {
  const { setAuthenticated } = useAuthenticatedContext();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { isAdmin } = useCurrentRole();

  const [getMe, { data }] = useLazyQuery(GET_ME, {
    onCompleted: () => {
      setCurrentUser(data?.me);
    },
  });

  useEffect(() => {
    if (!currentUser) {
      getMe();
    }
  }, [currentUser, getMe]);

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => {
      setAuthenticated(false);
      setCurrentUser(undefined);

      history.replace('/app/login');

      clearCacheOnClients();
    },
  });

  const { theme, toggleTheme } = useTheme();
  const { toggleSidebar } = useSidebarContext();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const clickOutsideHandler = useCallback(() => {
    setIsProfileMenuOpen(false);
  }, []);

  const handleProfileClick = useCallback(() => {
    if (!isProfileMenuOpen) {
      setIsProfileMenuOpen(true);
    }
  }, [isProfileMenuOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (isProfileMenuOpen) {
        document.addEventListener('click', clickOutsideHandler);
      } else {
        document.removeEventListener('click', clickOutsideHandler);
      }
    }, 100);
  }, [isProfileMenuOpen, clickOutsideHandler]);

  return (
    <header className="z-40 py-4 bg-white dark:bg-gray-800">
      <div className="flex items-center h-full pl-6 pr-10 text-purple-600 dark:text-purple-300">
        {/* <!-- Mobile hamburger --> */}
        <button
          aria-label="Menu"
          className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple"
          onClick={toggleSidebar}
        >
          <MenuIcon aria-hidden="true" className="w-6 h-6" />
        </button>
        <Breadcrumb />
        <ul className="flex items-center flex-shrink-0 h-10 ml-auto space-x-6">
          {/* <!-- Theme toggler --> */}
          <li className="flex">
            <button
              aria-label="Toggle color mode"
              className="rounded-md focus:outline-none focus:shadow-outline-purple"
              onClick={toggleTheme}
            >
              {theme === 'dark' ? (
                <SunIcon aria-hidden="true" className="w-5 h-5" />
              ) : (
                <MoonIcon aria-hidden="true" className="w-5 h-5" />
              )}
            </button>
          </li>
          {/* <!-- Notifications menu --> */}

          {/* <!-- Profile menu --> */}
          <li className="relative">
            <button
              aria-haspopup="true"
              aria-label="Account"
              className="flex items-center rounded-full focus:shadow-outline-purple focus:outline-none"
              onClick={handleProfileClick}
            >
              <CircleAvatar type={currentUser?.owner} />
              <span className="mx-2 text-sm font-semibold transition-colors duration-150 hover:text-purple-800 dark:hover:text-purple-200">
                {`${currentUser?.owner.firstName} ${currentUser?.owner.lastName}`}
              </span>
            </button>
            <Dropdown
              align="right"
              isOpen={isProfileMenuOpen}
              onClose={() => null}
            >
              {isAdmin && (
                <DropdownItem
                  className="py-2 focus:outline-none"
                  onClick={() => {
                    history.push('/app/settings');
                    setIsProfileMenuOpen(false);
                  }}
                >
                  <OutlineCogIcon aria-hidden="true" className="w-4 h-4 mr-3" />
                  <span>{t('settings')}</span>
                </DropdownItem>
              )}
              <DropdownItem
                className="py-2 focus:outline-none"
                onClick={() => logout()}
              >
                <OutlineLogoutIcon
                  aria-hidden="true"
                  className="w-4 h-4 mr-3"
                />
                <span>{t('Log out')}</span>
              </DropdownItem>
            </Dropdown>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
