import { useQuery } from '@apollo/client';
import Layout from 'admin/containers/Layout';
import { GET_ME } from 'admin/graphql/queries';
import React, { lazy } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import { useAuthenticatedContext } from './context/AuthenticatedContext';
import { useCurrentUser } from './context/CurrentUserContext';
import Loading from './pages/Loading';

const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

const AuthenticatedRoutes = [
  <Route component={Layout} key="app" path="/app/" />,
];

const UnauthenticatedRoutes = [
  <Route component={Login} key="lg" path="/app/login" />,
  <Route component={ForgotPassword} key="fp" path="/app/forgot-password" />,
];

function App() {
  const { authenticated, setAuthenticated } = useAuthenticatedContext();
  const { setCurrentUser } = useCurrentUser();
  const isAliasDomain =
    process.env.NODE_ENV === 'production' &&
    window.location.hostname.includes('kunden');

  const { loading } = useQuery(GET_ME, {
    fetchPolicy: 'network-only',
    skip: authenticated === false || isAliasDomain,
    onCompleted: (data) => {
      if (data.me.owner.id) {
        setAuthenticated(true);
        setCurrentUser(data.me);
      } else {
        setAuthenticated(false);
      }
    },
    onError: () => setAuthenticated(false),
  });

  if (isAliasDomain) {
    window.location.replace(window.location.origin);
    return null;
  }

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AccessibleNavigationAnnouncer />
        <Switch>
          {loading && <Route component={Loading} key="loading" path="/" />}
          {authenticated === true && AuthenticatedRoutes}
          {authenticated === false && UnauthenticatedRoutes}
          {authenticated === false && <Redirect to="/app/login" />}
        </Switch>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
