import cx from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

function Main({ children }: Props) {
  const { pathname } = useLocation();
  const isMapLayout = pathname?.match(/list-campaigns\/\d/);

  return (
    <main
      className="overflow-y-scroll bg-gray-50 dark:bg-gray-900 no-scrollbar"
      id="scrollDiv"
    >
      <div className={cx({ 'container grid px-6 mx-auto': !isMapLayout })}>
        {children}
      </div>
    </main>
  );
}

export default Main;
