import LogoIcon from 'admin/components/icons/LogoIcon';
import LoadingIndicator from 'admin/components/widgets/LoadingIndicator';
import React from 'react';

function Loading() {
  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-5 dark:bg-gray-900">
      <LogoIcon size="large" />
      <LoadingIndicator size="large" />
    </div>
  );
}

export default Loading;
