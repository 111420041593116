import { useQuery } from '@apollo/client';
import TimelineEvent from 'admin/components/timeline/TimelineEvent';
import Button from 'admin/components/widgets/Button';
import LoadingIndicator from 'admin/components/widgets/LoadingIndicator';
import { RESULTS_PER_PAGE } from 'admin/constants/table';
import { PAYOUT_RELATED_TIMELINE_ENTRIES } from 'admin/graphql/queries';
import { useCurrentRole } from 'admin/hooks/useCurrentRole';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminTimelineEntry } from 'shared/types/admin/timeline-entry';
import { NumberParam, useQueryParam } from 'use-query-params';

export default function PayoutLogs() {
  const { t } = useTranslation();
  const [timeline, setTimeline] = useState<AdminTimelineEntry[]>([]);
  const [isLoadMoreShown, setIsLoadMoreShown] = useState(false);
  const [page, setPage] = useState(0);
  const { currStaff } = useCurrentRole();
  const [staffId] = useQueryParam('staffId', NumberParam);

  const { loading } = useQuery(PAYOUT_RELATED_TIMELINE_ENTRIES, {
    onCompleted: ({ payoutRelatedTimelineEntries }) => {
      setTimeline((t) => t.concat(payoutRelatedTimelineEntries.items));
      setIsLoadMoreShown(
        timeline.length + payoutRelatedTimelineEntries.items.length <
          payoutRelatedTimelineEntries.totalCount
      );
    },
    variables: {
      staffId: staffId || currStaff.id,
      skip: page * RESULTS_PER_PAGE,
      take: RESULTS_PER_PAGE,
    },
  });

  return (
    <div className="p-4 my-8 bg-white">
      {timeline.map((entry: any, i, list: any) => (
        <TimelineEvent
          editButtonPressCb={() => null}
          key={entry.id}
          mixedContext
          previousTimelineEntry={list[i - 1]}
          timelineEntry={entry}
          updateEntryCb={() => null}
        />
      ))}
      {timeline.length === 0 && loading && <LoadingIndicator />}
      {isLoadMoreShown && (
        <Button
          className="ml-2"
          disabled={loading}
          onClick={() => setPage((p) => p + 1)}
        >
          {t('Load older')}
        </Button>
      )}
    </div>
  );
}
