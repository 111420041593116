import { useTheme } from 'admin/context/ThemeContext';
import cx from 'classnames';
import React from 'react';

type Props = {
  size?: 'xs' | 'small' | 'large';
  light?: boolean;
  className?: string;
};

function LoadingIndicator({ size = 'small', light, className }: Props) {
  const { theme } = useTheme();

  return (
    <svg
      className={cx(className, {
        'w-3': size === 'xs',
        'w-5': size === 'small',
        'w-8': size === 'large',
      })}
      style={{ filter: theme === 'dark' || light ? 'invert(1)' : undefined }}
      version="1.0"
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
          fill="#111827"
          fillOpacity="1"
        />
        <animateTransform
          attributeName="transform"
          dur="600ms"
          from="0 64 64"
          repeatCount="indefinite"
          to="360 64 64"
          type="rotate"
        ></animateTransform>
      </g>
    </svg>
  );
}

export default LoadingIndicator;
