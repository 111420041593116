export const BUTTON_LOCK_TIMEOUT_MS = 3000;

export const PARTIAL_INVOICE_BILLABLE_BALANCE = 999;

export const FINAL_INVOICE_DAYS_SINCE_COUNT = 7;

export const PARTIAL_INVOICE_WEEKS_COUNT = 2;

export const FONT_SIZE_XS = 13;

export const REMOVAL_FORM_INPUT_WIDTH = 180;

export const BREAKPOINT_3XL = 2560;

export const WINDOW_WIDTH = window.innerWidth;

export const QUERY_PARAM_OFFER_ID = 'offerId';
