import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  url: string;
  children: React.ReactNode;
};

function NavigationHeader({ url, children }: Props) {
  return (
    <Link
      className="flex items-center h-10 font-bold text-gray-700 text-l dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-300"
      key={url}
      to={url}
    >
      {children}
    </Link>
  );
}

export default NavigationHeader;
