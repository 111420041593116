import { MOMENT_DATE_FORMAT_STANDARD_ALT } from 'admin/constants/displays';
import { QUERY_PARAM_OFFER_ID } from 'admin/constants/utils';
import { formatDate, formatPrice, formatUrl } from 'admin/utils/formatter';
import { isValidURL, trimQuotes } from 'admin/utils/helpers';
import { isEmailValid } from 'admin/utils/validation-utils';
import cx from 'classnames';
import { startCase } from 'lodash';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminEventUpdate } from 'shared/types/admin/event-update';
import { AdminOffer } from 'shared/types/admin/offer';
import { AdminTimelineEntry } from 'shared/types/admin/timeline-entry';
import { useQueryParam } from 'use-query-params';

import { BadgeClassNames, EventBadge } from '../badges/EventBadge';
import Button from '../widgets/Button';

type Props = {
  entry: AdminTimelineEntry;
};

export default function TimelineEventUpdate({ entry }: Props) {
  const { t } = useTranslation();
  const [, setOfferId] = useQueryParam(QUERY_PARAM_OFFER_ID);

  const getValueToRender = useCallback((value) => {
    const strippedValue = value.replace(/['"]+/g, '');

    return isEmailValid(strippedValue) || isValidURL(strippedValue)
      ? strippedValue
      : startCase(value);
  }, []);

  const renderOfferCreated = useCallback(
    (event: AdminTimelineEntry) => {
      const offer = event.association as AdminOffer;
      return (
        <div>
          <div className="space-x-2">
            <span className="font-bold">{t('Total')}:</span>
            <span>{formatPrice(offer.totalAmount)}</span>
          </div>
          <div className="mt-1 space-x-2">
            <span className="font-bold">{t('Duration')}:</span>
            <span>
              {t('valid')} (
              {formatDate(offer.expiresAt, MOMENT_DATE_FORMAT_STANDARD_ALT)})
            </span>
          </div>
        </div>
      );
    },
    [t]
  );

  const renderAssociationCreated = useCallback(
    (entry: AdminTimelineEntry) => {
      if (isAssociationOffer(entry.association)) {
        return renderOfferCreated(entry);
      }

      return null;
    },
    [renderOfferCreated]
  );

  const renderExtraInformation = useCallback(
    (entry: AdminTimelineEntry) => {
      if (!entry.association.id) {
        return null;
      }

      if (isAssociationOffer(entry.association)) {
        const offer = entry.association as AdminOffer;
        const url = formatUrl(offer.slug);
        return (
          <div
            className={cx('mt-1 space-x-2', {
              'mt-2': (entry.event as AdminEventUpdate).isCreationEvent,
            })}
          >
            <span className="font-bold">{t('URL')}:</span>
            <a
              className="link"
              href={url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {url}
            </a>
          </div>
        );
      }
    },
    [t]
  );

  const renderAssociationButton = useCallback(
    (entry: AdminTimelineEntry) => {
      if (!entry.association.id) {
        return null;
      }

      if (isAssociationOffer(entry.association)) {
        const offer = entry.association as AdminOffer;

        return (
          <Button
            className="mt-4"
            layout="outline"
            onClick={() => setOfferId(offer.id)}
          >
            {t('View')}
          </Button>
        );
      }

      return null;
    },
    [setOfferId, t]
  );

  const renderBaseUpdate = useCallback(
    (event: AdminEventUpdate) => {
      let { oldValue, newValue, field } = event;
      oldValue = trimQuotes(oldValue);
      newValue = trimQuotes(newValue);

      if (field === 'orderTypeStatus') {
        field = 'orderStatus';
      }

      return (
        <div className="space-x-2">
          <span className="font-semibold">{startCase(field)}:</span>
          {isValidURL(oldValue) ? (
            <a
              className={cx('hover:underline', BadgeClassNames.error)}
              href={oldValue}
              rel="noreferrer"
              target="_blank"
              title={oldValue}
            >
              {t('Link')}
            </a>
          ) : (
            <EventBadge className={BadgeClassNames.error}>
              {getValueToRender(oldValue)}
            </EventBadge>
          )}
          <span>{`->`}</span>
          {isValidURL(newValue) ? (
            <a
              className={cx('hover:underline', BadgeClassNames.success)}
              href={newValue}
              rel="noreferrer"
              target="_blank"
              title={newValue}
            >
              {t('Link')}
            </a>
          ) : (
            <EventBadge className={BadgeClassNames.success}>
              {getValueToRender(newValue)}
            </EventBadge>
          )}
        </div>
      );
    },
    [getValueToRender, t]
  );

  const content = useMemo(() => {
    const event = entry.event as AdminEventUpdate;
    return event.isCreationEvent
      ? renderAssociationCreated(entry)
      : renderBaseUpdate(event);
  }, [entry, renderAssociationCreated, renderBaseUpdate]);

  return (
    <div>
      {content}
      {renderExtraInformation(entry)}
      {renderAssociationButton(entry)}
    </div>
  );
}

function isAssociationOffer(association: any) {
  return association?.__typename === 'AdminOffer';
}
