import cx from 'classnames';

type Props = {
  active?: boolean;
  text: string;
  onClick?: () => void;
};

function GenericTagButton({ active, text, onClick }: Props) {
  return (
    <div
      className={cx(
        'flex items-center font-medium justify-center px-4 py-1 mx-1 my-2 text-center cursor-pointer rounded-full dark:text-white',
        { 'bg-gray-400 text-white': active },
        { 'bg-gray-200 dark:bg-gray-600': !active }
      )}
      onClick={onClick}
      title={text}
    >
      {text}
    </div>
  );
}

export default GenericTagButton;
