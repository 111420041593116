import { Tabs } from 'admin/constants/tabs';
import { Permission, StaticRoles } from 'shared/types/admin/enums';
/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */

const routes = [
  {
    path: '/app/dashboard', // the url
    icon: 'HomeIcon', // the component being exported from icons/index.js
    name: Tabs.dashboard,
    roles: [
      StaticRoles.admin,
      StaticRoles.accountManager,
      StaticRoles.salesManager,
      StaticRoles.squadLead,
    ],
  },
  {
    path: '/app/tasks',
    icon: 'BellIcon',
    name: Tabs.tasks,
    roles: Object.values(StaticRoles),
  },
  {
    path: '/app/potential',
    icon: 'EyeIcon',
    name: Tabs.potential,
    roles: [
      StaticRoles.admin,
      StaticRoles.accountManager,
      StaticRoles.salesManager,
      StaticRoles.orderAssistant,
      StaticRoles.dataTransferAssistant,
      StaticRoles.squadLead,
    ],
  },
  {
    path: '/app/agencies',
    icon: 'HandshakeIcon',
    name: Tabs.agencies,
    roles: [StaticRoles.admin, StaticRoles.accountManager],
    permissions: [Permission.readPartners],
  },
  {
    path: '/app/customers',
    icon: 'PeopleIcon',
    name: Tabs.customers,
    roles: [
      StaticRoles.admin,
      StaticRoles.accountManager,
      StaticRoles.salesManager,
      StaticRoles.orderAssistant,
      StaticRoles.dataTransferAssistant,
      StaticRoles.squadLead,
    ],
  },
  {
    path: '/app/orders',
    icon: 'CartIcon',
    name: Tabs.orders,
    roles: Object.values(StaticRoles),
  },
  {
    path: '/app/leads',
    icon: 'SpeakerIcon',
    name: Tabs.leads,
    roles: [
      StaticRoles.admin,
      StaticRoles.accountManager,
      StaticRoles.salesManager,
      StaticRoles.squadLead,
    ],
  },
  {
    path: '/app/offers',
    icon: 'ContractIcon',
    name: Tabs.offers,
    roles: [
      StaticRoles.admin,
      StaticRoles.accountManager,
      StaticRoles.salesManager,
      StaticRoles.orderAssistant,
      StaticRoles.dataTransferAssistant,
      StaticRoles.squadLead,
    ],
  },
  {
    path: '/app/settings',
    icon: 'OutlineCogIcon',
    name: Tabs.settings,
    roles: [StaticRoles.admin],
  },
  {
    path: '/app/locations',
    icon: 'GoogleIcon',
    name: Tabs.google,
    roles: [
      StaticRoles.admin,
      StaticRoles.orderAssistant,
      StaticRoles.dataTransferAssistant,
    ],
  },
  {
    path: '/app/onnogpt',
    icon: 'OpenAIIcon',
    name: Tabs.onnoGpt,
    roles: [StaticRoles.admin, StaticRoles.orderAssistant],
  },
];

export default routes;
