import EnvironmentBanner from 'admin/components/EnvironmentBanner';
import Header from 'admin/components/Header';
import Sidebar from 'admin/components/sidebar';
import Main from 'admin/containers/Main';
import { useSidebarContext } from 'admin/context/SidebarContext';
import routes from 'admin/routes';
import { NavigationRouteType } from 'admin/types/routes';
import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Page404 = lazy(() => import('admin/pages/404'));

function Layout() {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <div
      className={`flex h-screen dark:bg-gray-800 ${
        isSidebarOpen && 'overflow-hidden'
      }`}
      id="layout"
    >
      <EnvironmentBanner />
      <Sidebar />

      <div className="relative flex flex-col flex-1 w-full overflow-hidden dark:bg-gray-900 bg-gray-50 dark:text-gray-300">
        <Header />
        <Main>
          <Switch>
            {routes.map((route: NavigationRouteType, i: number) => {
              const Component = route.component;
              return Component ? (
                <Route
                  exact={route.exact !== false}
                  key={i}
                  path={`/app${route.path}`}
                  render={(props) => <Component {...props} />}
                />
              ) : null;
            })}
            <Redirect from="/" to="/app/orders" />
            <Route component={Page404} />
          </Switch>
        </Main>
      </div>
    </div>
  );
}

export default Layout;
