import { UploadIcon } from 'admin/assets/icons';
import DefaultImage from 'admin/assets/img/300x200.png';
import RemoveIcon from 'admin/assets/img/close.png';
import cx from 'classnames';
import { CSSProperties, useState } from 'react';
import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const MAX_SIZE = 12582912; // 12 MB in bytes

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
} as CSSProperties;

const thumb = {
  position: 'relative',
  border: '1px solid #e2e8f0',
  display: 'inline-flex',
  width: 300,
  height: 200,
} as CSSProperties;

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

type PreviewFile = File & {
  preview: string;
};

type Props = {
  children?: React.ReactNode;
  handleUploads: (f: File | any) => void;
  maxFiles?: number;
  fileType?: string;
  base64Format?: boolean;
  className?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  withPreview?: boolean;
  required?: boolean;
  onDelete?: () => void;
};

function Dropzone({
  children,
  handleUploads,
  maxFiles,
  fileType,
  base64Format = false,
  className,
  name,
  placeholder,
  disabled,
  withPreview,
  onDelete,
  required,
}: Props) {
  const { t } = useTranslation();

  const [uploadedFile, setUploadedFile] = useState<string>();
  const [files, setFiles] = useState([]);

  const handleFileChosen = useCallback(
    (file: File) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          handleUploads({
            filename: file.name,
            contentType: file.type,
            base64File: (fileReader.result as string).split(',').slice(1)[0],
          });
          resolve(fileReader.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsDataURL(file);
      });
    },
    [handleUploads]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          if (base64Format) {
            const fileContents = await handleFileChosen(file);
            return fileContents;
          } else {
            handleUploads(file);
            setUploadedFile(file.name);
          }
        })
      );
    },
    [base64Format, handleUploads, handleFileChosen]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles,
    disabled,
    accept: fileType,
    maxSize: MAX_SIZE,
    multiple: maxFiles === 1 ? false : true,
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const renderTitle = useCallback(() => {
    const defaultTitle = placeholder || t('Drop/Choose File From Computer');

    if (name || name === '') {
      return name || defaultTitle;
    }
    return uploadedFile || defaultTitle;
  }, [uploadedFile, placeholder, name, t]);

  const renderThumb = useCallback(
    (file?: PreviewFile, index?: number) => {
      return (
        <div key={file?.name || 'default'} style={thumb}>
          {index !== undefined && (
            <img
              className="absolute w-6 h-6"
              onClick={(e) => {
                e.stopPropagation();
                const newFiles = [...files];
                newFiles.splice(file as any, 1);

                setFiles(newFiles);
                setUploadedFile(undefined);

                onDelete?.();
              }}
              src={RemoveIcon}
              style={{ top: -10, right: -10 }}
            />
          )}
          <div style={thumbInner}>
            <img src={file?.preview || DefaultImage} style={img} />
          </div>
        </div>
      );
    },
    [files, onDelete]
  );

  const thumbs = useCallback(() => {
    return files.map((file: PreviewFile, i: number) => renderThumb(file, i));
  }, [files, renderThumb]);

  return (
    <section>
      <div
        {...(getRootProps({ style }) as any)}
        className={cx(
          'cursor-pointer flex items-center border rounded-lg outline-none transition .24s ease-in-out border-dashed text-xs hover:bg-gray-50 dark:hover:bg-gray-700',
          { 'flex-col py-4': withPreview },
          {
            'space-x-2 h-10 p-4': !withPreview,
          },
          className
        )}
      >
        <div className="flex">
          <input {...getInputProps()} />

          <UploadIcon className="w-8" />
          <span>{renderTitle()}</span>
          {required && !uploadedFile && (
            <span className="ml-1 text-red-700">*</span>
          )}
        </div>
        {withPreview && (
          <aside style={thumbsContainer}>
            {files.length ? thumbs() : renderThumb()}
          </aside>
        )}
      </div>
      {children}
    </section>
  );
}

export default Dropzone;
