import { ApolloError } from '@apollo/client';
import { BadgeCheckIcon, CancelIcon, ClockIcon } from 'admin/assets/icons';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { get, sum } from 'lodash';
import moment from 'moment';
import { SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { AdminCustomer } from 'shared/types/admin/customer';
import { OrderTypeStatus, PaymentStatus } from 'shared/types/admin/enums';
import { AdminLead } from 'shared/types/admin/lead';
import { AdminOrder } from 'shared/types/admin/order';
import { AdminPayment } from 'shared/types/admin/payment';
import { AdminProduct } from 'shared/types/admin/product';
import * as yup from 'yup';

export const TIMEZONE_DEFAULT = 'Europe/Berlin';

export const handleApolloError = (
  errorCb: (e: SetStateAction<string>) => void
) => (e: ApolloError) =>
  errorCb(get(e, 'graphQLErrors[0].message') || e.message);

export const getFileName = (url: string | undefined) => {
  return url?.split('/').pop();
};

export const getFileInputIcon = (fileExists: boolean, isSent: boolean) => {
  if (fileExists) {
    return BadgeCheckIcon;
  }
  if (isSent) {
    return ClockIcon;
  }
  return CancelIcon;
};

export const getFileInputTitle = (isSent: boolean, t: TFunction) => {
  return isSent ? t('Request Sent') : t('File N/A');
};

export const isValidURL = (url: string) => {
  return yup.string().required().url().isValidSync(encodeURI(url));
};

export const getFormData = (fileData: File): FormData => {
  const formData = new FormData();
  formData.append('file', fileData);
  return formData;
};

export const archiveFilter = {
  OR: [
    {
      orderTypeStatus: { equals: OrderTypeStatus.cancelled },
    },
    {
      orderTypeStatus: { equals: OrderTypeStatus.completed },
      orderBalance: { billableBalance: { equals: 0 } },
      payments: {
        every: {
          status: {
            in: [
              PaymentStatus.paid,
              PaymentStatus.voided,
              PaymentStatus.cancelled,
            ],
          },
        },
      },
    },
  ],
};

// Convert x to a signed 32-bit integer
export const toInt32 = (x: number) => x | 0;

export const invoiceIdToNumber = (i: string) => Number(i.replace('RE-', ''));

export const showError = (message: any) =>
  toast.error(typeof message === 'object' ? message.error : message, {
    autoClose: 5000,
  });

export const showSuccess = (message: string) => toast.success(message);

export const showInfo = (message: string) => toast.info(message);

export const isTruthy = (value: string | boolean) =>
  typeof value === 'string' ? value === '1' : value;

export const booleanToSelectValue = (
  value: boolean | undefined | null | string
) => {
  if (value === false) {
    return '0';
  }
  if (value === true) {
    return '1';
  }
  return undefined;
};

export const booleanFromSelectValue = (value: string | null) => {
  if (value === '0') {
    return false;
  }
  if (value === '1') {
    return true;
  }
  return undefined;
};

export const trimAllWhiteSpaces = (value: string): string => {
  return value.replace(/ +/g, '');
};

export const getStartOfDay = (value?: Date) => moment(value).startOf('day');

export const getEndOfDay = (value?: Date) => moment(value).endOf('day');

export const isSame = (a: Date, b: Date, granularity: any): boolean =>
  moment(a).isSame(b, granularity);

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const trimQuotes = (value: string): string => {
  return value.replace(/['"]+/g, '');
};

export const getOrderProductCount = (order: AdminOrder) => {
  if (!order.paymentSummary) {
    return 0;
  }
  const { billableAllTimeCount, openCount, lostCount } = order.paymentSummary;
  return sum([billableAllTimeCount, openCount, lostCount]);
};

export const groupProductTypes = (products?: AdminProduct[]) => {
  return _(products)
    .groupBy('type')
    .map((value, key) => ({ group: key, options: value }))
    .value();
};

export const getLeaderboardStarColor = (i: number) => {
  switch (i) {
    case 0:
      return '#DFD746';

    case 1:
      return '#C0C0C0';

    case 2:
      return '#C28342';

    default:
      return '';
  }
};

export function getAssociationLink(association: any) {
  const { id } = association;
  switch (association.__typename) {
    case 'AdminLead': {
      const lead = association as AdminLead;
      return {
        label: lead.companyName,
        link: `/app/leads/${id}`,
      };
    }
    case 'Customer': {
      const customer = association as AdminCustomer;
      return {
        label: customer.billingAddress.companyName,
        link: `/app/customers/${id}`,
      };
    }
    case 'AdminOrder': {
      const order = association as AdminOrder;
      return {
        label: order.product
          ? `${order.id} - ${order.quantity}x ${order.product.platform?.title} - ${order.customer.billingAddress.companyName}`
          : order.orderId,
        link: `/app/orders/removal-orders/${id}`,
      };
    }
    case 'AdminPayment': {
      const payment = association as AdminPayment;
      return {
        label: payment.invoiceId,
        link: `/app/orders/removal-orders/${payment.orderId}/billing`,
      };
    }
    default:
      return {
        label: '',
        link: '#',
      };
  }
}
