import { getEnvironment } from 'admin/utils/env';
import cx from 'classnames';

function EnvironmentBanner() {
  const env = getEnvironment();
  if (env === 'production') {
    return null;
  }

  return (
    <div
      className={cx(
        'fixed inset-x-0 bottom-0 z-50 flex justify-center h-8 text-xs font-bold text-white',
        {
          'bg-yellow-400 dark:bg-yellow-600': env === 'staging',
          'bg-green-400 dark:bg-green-600': env === 'development',
        }
      )}
    >
      <span className="flex self-center justify-center">
        {env.toUpperCase()}
      </span>
    </div>
  );
}

export default EnvironmentBanner;
