import * as Icons from 'admin/assets/icons';
import { useTheme } from 'admin/context/ThemeContext';
import cx from 'classnames';
import React from 'react';

type Props = {
  size?: 'small' | 'large';
};

function LogoIcon({ size = 'small' }: Props) {
  const { theme } = useTheme();
  const Icon = theme === 'dark' ? Icons.LogoIconDark : Icons.LogoIcon;

  return (
    <Icon
      className={cx(
        'container',
        { 'h-10': size === 'small' },
        { 'h-72': size === 'large' }
      )}
    />
  );
}

export default LogoIcon;
