import { useQuery } from '@apollo/client';
import NotificationBadge from 'admin/components/badges/NotificationBadge';
import LogoIcon from 'admin/components/icons/LogoIcon';
import SidebarSubmenu from 'admin/components/sidebar/SidebarSubmenu';
import { Icon } from 'admin/components/widgets/Icon';
import { OUTSTANDING_TASKS } from 'admin/graphql/queries';
import { useCurrentRole } from 'admin/hooks/useCurrentRole';
import routes from 'admin/routes/sidebar';
import { SidebarRouteType } from 'admin/types/routes';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, Route } from 'react-router-dom';

function SidebarContent() {
  const { t } = useTranslation();
  const { currUserRole, currUserPermissions } = useCurrentRole();
  const { data } = useQuery(OUTSTANDING_TASKS);

  const renderRouteName = useCallback(
    (routeName: string) => {
      if (routeName === 'tasks') {
        const count = data?.outstandingTasks;

        return (
          <div className="flex items-center space-x-2">
            <span className="ml-4">{t(routeName)}</span>
            {!!count && (
              <NotificationBadge className="text-red-500" type="info">
                {count}
              </NotificationBadge>
            )}
          </div>
        );
      }
      return <span className="ml-4">{t(routeName)}</span>;
    },
    [data, t]
  );

  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <Link to={'/app/dashboard'}>
        <LogoIcon />
      </Link>

      <ul className="mt-6">
        {routes
          .filter(({ roles, permissions }) => {
            if (permissions) {
              return permissions.every((permission) =>
                currUserPermissions?.includes(permission)
              );
            }
            return roles.includes(currUserRole);
          })
          .map((route: SidebarRouteType) =>
            route.routes ? (
              <SidebarSubmenu key={route.name} route={route} />
            ) : (
              <li className="relative px-2" key={route.name}>
                <NavLink
                  activeClassName="text-gray-800 dark:text-gray-100"
                  className="inline-flex items-center w-full h-full px-4 py-3 text-sm font-semibold transition-colors duration-150 rounded-md dark:hover:bg-gray-700 hover:bg-gray-100 hover:text-gray-800 dark:hover:text-gray-200"
                  to={route.path}
                >
                  <Route exact={route.exact} path={route.path}>
                    <span
                      aria-hidden="true"
                      className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                    />
                  </Route>
                  <Icon
                    aria-hidden="true"
                    className="w-5 h-5"
                    icon={route.icon}
                  />
                  {renderRouteName(route.name)}
                </NavLink>
              </li>
            )
          )}
      </ul>
    </div>
  );
}

export default SidebarContent;
