import { Button as WindmillButton } from '@windmill/react-ui';
import { Props as ButtonProps } from '@windmill/react-ui/dist/Button';
import React, { HTMLProps } from 'react';

type ButtonAsLink = ButtonProps & {
  tag: any;
  to: string;
};

type WindmillProps = ButtonProps | ButtonAsLink;
type Props = HTMLProps<HTMLButtonElement> & WindmillProps & any;

export default function Button(props: Props): React.ReactElement {
  return React.createElement(WindmillButton as any, props);
}
