import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

export const BadgeClassNames = {
  success:
    'px-2 py-1 bg-green-200 rounded dark:bg-green-700 dark:text-gray-300',
  error: 'px-2 py-1 bg-red-200 rounded dark:bg-red-700 dark:text-gray-300',
  processing:
    'px-2 py-1 bg-yellow-200 rounded dark:bg-yellow-700 dark:text-gray-300',
  opened: 'px-2 py-1 bg-blue-200 rounded dark:bg-blue-700 dark:text-gray-300',
};

export function EventBadge({ children, className }: Props) {
  return <span className={className}>{children}</span>;
}
