import 'moment/locale/de';

import resources from 'admin/assets/translations';
import de from 'date-fns/locale/de';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';

registerLocale('de', de);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'de-DE',
    resources,
    keySeparator: false,
  })
  .then(() => {
    moment.locale(i18n.language);
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
