import React, { useContext, useMemo, useState } from 'react';

type Props = {
  children: React.ReactNode;
};

type ContextProps = {
  lang: string;
  theme: string;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

/**
 *  we initialise context properties without default values, to make that happen, we
 *  apply the Partial helper type.
 *  */

export const SidebarContext = React.createContext<Partial<ContextProps>>({});

export const SidebarProvider = ({ children }: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  const value = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
    }),
    [isSidebarOpen]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

export function useSidebarContext() {
  return useContext(SidebarContext);
}
