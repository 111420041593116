import { TypedDocumentNode, gql } from '@apollo/client';
import { CustomerCrawlStatus } from '@onnoplus/shared/types/reviews/customer';
import { ScanStatus } from '@onnoplus/shared/types/reviews/enums';
import { ReviewPaginatedList } from '@onnoplus/shared/types/reviews/review';
import { Scan } from '@onnoplus/shared/types/reviews/scan';
import {
  AdminCustomer,
  AdminCustomerArgs,
  AdminCustomerPaginatedList,
} from 'shared/types/admin/customer';
import { AdminAttachment } from 'shared/types/admin/document-template';
import {
  AdminEmailTemplate,
  AdminEmailTemplateArgs,
} from 'shared/types/admin/email-template';
import { StaticRoles } from 'shared/types/admin/enums';
import {
  AdminEventMail,
  AdminEventMailAttachmentArgs,
} from 'shared/types/admin/event-mail';
import { IdInput, StringIdInput } from 'shared/types/admin/input';
import { AdminOpenAIKununuPromptArgs } from 'shared/types/admin/integration';
import { AdminLawyer } from 'shared/types/admin/lawyer';
import {
  AdminLead,
  AdminLeadArgs,
  AdminLeadCategoryArgs,
  AdminLeadCategoryPaginatedList,
  AdminLeadId,
  AdminLeadPaginatedList,
  AdminLeadStatusAggregate,
  AdminLeadStatusesArgs,
  AdminPlace,
} from 'shared/types/admin/lead';
import { AdminLeadAllocationRuleRegion } from 'shared/types/admin/lead-allocation';
import { AdminLineItemBasePriceArgs } from 'shared/types/admin/line-item';
import {
  AdminLocationCustomerId,
  AdminLocationFindArgs,
} from 'shared/types/admin/location';
import {
  AdminOffer,
  AdminOfferArgs,
  AdminOfferLegacyArgs,
  AdminOfferOverviewStats,
  AdminOfferPaginatedList,
} from 'shared/types/admin/offer';
import {
  AdminOnlineProfile,
  AdminOnlineProfileArgs,
} from 'shared/types/admin/online-profile';
import {
  AdminExportOrderArgs,
  AdminOrder,
  AdminOrderArgs,
  AdminOrderPaginatedList,
} from 'shared/types/admin/order';
import { AdminPayment } from 'shared/types/admin/payment';
import {
  AdminPayoutArgs,
  AdminPayoutPaginatedList,
} from 'shared/types/admin/payout';
import { AdminPlatform } from 'shared/types/admin/platform';
import { AdminPricingCategory } from 'shared/types/admin/pricing-category';
import { AdminProduct } from 'shared/types/admin/product';
import {
  AdminDuplicateRemovalEntryArgs,
  AdminRemovalEntry,
} from 'shared/types/admin/removal-entry';
import {
  AdminReportsCustomerReviewsArgs,
  AdminReportsInitiatedForwardedArgs,
  AdminReportsInvoicesArgs,
  AdminReportsNotForwardedArgs,
} from 'shared/types/admin/reports';
import { AdminRole, AdminRoleArgs } from 'shared/types/admin/role';
import { AdminSquad } from 'shared/types/admin/squad';
import {
  AdminStaff,
  AdminStaffArgs,
  AdminStaffPaginatedList,
} from 'shared/types/admin/staff';
import {
  AdminAgentActivityArgs,
  AdminAgentActivityData,
  AdminAgentCallDurationData,
  AdminLeadLossMetricData,
  AdminLeadsWonLostMetric,
  AdminSquadStats,
  AdminStatsAbstractStaffMetric,
  AdminStatsByStaffAndDateArgs,
} from 'shared/types/admin/stats';
import {
  AdminLineData,
  AdminSalesByAgentsMetrics,
  AdminSalesStats,
  AdminStatsAllProductsOverviewMetric,
  AdminStatsByDateArgs,
  AdminStatsOwnSalesArgs,
  AdminStatsSalesArgs,
  AdminStatsSalesByAgentsArgs,
  AdminStatsSalesCompareArgs,
  AdminStatsSalesOverviewMetric,
} from 'shared/types/admin/stats';
import {
  AdminCustomerAgeStatsMetrics,
  AdminCustomerStatsList,
  AdminCustomerStatsOverview,
  AdminCustomerSupplyStats,
  AdminManagerStatsList,
  AdminStatsCustomerArgs,
} from 'shared/types/admin/stats-customer';
import {
  AdminBillingStats,
  AdminOverallPlatformStats,
  AdminRemovalStats,
  AdminStatsGoogleRollingAvgArgs,
  AdminStatsPlatformsArgs,
} from 'shared/types/admin/stats-removals';
import { AdminTaskArgs, AdminTaskPaginatedList } from 'shared/types/admin/task';
import {
  AdminPayoutRelatedTimelineEntryArgs,
  AdminTimelineEntryArgs,
  AdminTimelinePaginatedList,
} from 'shared/types/admin/timeline-entry';
import { AdminUser } from 'shared/types/admin/user';

export const IS_LOGGED_IN: TypedDocumentNode<{
  me: AdminUser;
}> = gql`
  query me {
    me {
      id
    }
  }
`;

export const GET_ME: TypedDocumentNode<{
  me: AdminUser & { owner: AdminStaff };
}> = gql`
  query me {
    me {
      id
      permissions
      owner {
        ... on AdminStaff {
          id
          firstName
          lastName
          name
          gender
          phone
          aircallUserId
          aircallNumberId
          role {
            id
            title
            permissions
          }
          user {
            id
            avatar
          }
        }
      }
      email
    }
  }
`;

export const ALL_PERMISSIONS = gql`
  query allPermissions($take: Int, $skip: Int) {
    allPermissions(take: $take, skip: $skip) {
      scope
      operation
    }
  }
`;

export type AdminStaffQueryResult = {
  allStaff: AdminStaffPaginatedList;
};

export const ALL_STAFF: TypedDocumentNode<
  { allStaff: AdminStaffPaginatedList },
  AdminStaffArgs
> = gql`
  query allStaff(
    $take: Int
    $skip: Int
    $where: AdminStaffWhereInput
    $orderBy: AdminStaffOrderInput
  ) {
    allStaff(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      items {
        id
        gender
        firstName
        lastName
        name
        phone
        isCustomerAssignable
        aircallUserId
        aircallNumberId
        commissionPercent
        jobTitle
        user {
          id
          active
          email
          avatar
          signature
        }
        role {
          id
          title
        }
      }
      totalCount
    }
  }
`;

export const STAFF: TypedDocumentNode<
  {
    staff: AdminStaff;
  },
  IdInput
> = gql`
  query staff($id: Int!) {
    staff(id: $id) {
      id
      gender
      firstName
      lastName
      jobTitle
      sendoutEmail
      name
      phone
      isCustomerAssignable
      aircallUserId
      aircallNumberId
      commissionPercent
      user {
        active
        email
        avatar
        signature
        pcSerialNumber
      }
      role {
        id
        title
      }
    }
  }
`;

export const ALL_MANAGERS: TypedDocumentNode<
  {
    allStaff: AdminStaffPaginatedList;
  },
  AdminStaffArgs
> = gql`
  query allManagers($take: Int, $skip: Int, $where: AdminStaffWhereInput) {
    allStaff(take: $take, skip: $skip, where: $where) {
      items {
        id
        firstName
        lastName
        name
        squadId
        role {
          id
        }
      }
    }
  }
`;

export const ALL_ROLES: TypedDocumentNode<
  {
    allRoles: AdminRole[];
  },
  AdminRoleArgs
> = gql`
  query allRoles($take: Int, $skip: Int, $where: AdminRoleWhereInput) {
    allRoles(take: $take, skip: $skip, where: $where) {
      id
      title
    }
  }
`;

export const ALL_LAWYERS: TypedDocumentNode<{
  allLawyers: AdminLawyer[];
}> = gql`
  query allLawyers($take: Int, $skip: Int, $where: AdminLawyerWhereInput) {
    allLawyers(take: $take, skip: $skip, where: $where) {
      id
      name
      archivedAt
    }
  }
`;

// Customers

export const ALL_CUSTOMERS: TypedDocumentNode<
  {
    allCustomers: AdminCustomerPaginatedList;
  },
  AdminCustomerArgs
> = gql`
  query allCustomers(
    $take: Int
    $skip: Int
    $where: AdminCustomerWhereInput
    $orderBy: [AdminCustomerSortInput!]
  ) {
    allCustomers(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      items {
        id
        name
        phoneNumbers
        createdAt
        billingAddress {
          id
          companyName
        }
        manager {
          id
          name
        }
        orderCount
        orderInProgressCount
        orderCompletedCount
        orderVolume
        billedAmount
        locationsCount
        potentialRemovalsCount
      }
      totalCount
    }
  }
`;

export const ALL_SUPPLIABLE_CUSTOMERS: TypedDocumentNode<
  {
    allCustomers: AdminCustomerPaginatedList;
  },
  AdminCustomerArgs
> = gql`
  query allSuppliableCustomers(
    $take: Int
    $skip: Int
    $where: AdminCustomerWhereInput
    $orderBy: [AdminCustomerSortInput!]
  ) {
    allCustomers(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      items {
        id
        orderVolume
        lastOrderAt
        lastSuppliedAt
        lastSupplier {
          id
          name
        }
        currentSupplier {
          id
          name
        }
        billingAddress {
          id
          companyName
        }
        platformsWithPotential {
          id
          potential
          title
          icon
        }
      }
      totalCount
    }
  }
`;

export const CUSTOMER_CURRENT_SUPPLIER: TypedDocumentNode<
  { customer: AdminCustomer },
  IdInput
> = gql`
  query customer($id: Int!) {
    customer(id: $id) {
      id
      currentSupplier {
        id
        name
      }
    }
  }
`;

export const SEARCH_CUSTOMERS: TypedDocumentNode<
  { allCustomers: AdminCustomerPaginatedList },
  AdminCustomerArgs
> = gql`
  query allCustomers($where: AdminCustomerWhereInput) {
    allCustomers(where: $where) {
      items {
        id
        name
        hasBadPayments
        billingAddress {
          id
          companyName
        }
      }
    }
  }
`;

export const CUSTOMER: TypedDocumentNode<
  { customer: AdminCustomer },
  IdInput
> = gql`
  query customer(
    $id: Int!
    $take: Int
    $skip: Int
    $where: AdminOrderWhereInput
  ) {
    customer(id: $id) {
      id
      customerId
      email
      firstName
      lastName
      gender
      name
      phoneNumbers
      isAgency
      isBlacklisted
      paymentMethod
      blacklistReason
      isSubscribedNewsletter
      supportHandoverAt
      potentialRemovalsCount
      billingAddress {
        id
        companyName
      }
      pausePolicy {
        id
        reason
        createdAt
        expiresAt
        platforms {
          id
          title
          icon
        }
        author {
          id
          firstName
          lastName
          name
          user {
            id
            avatar
          }
        }
        reason
      }
      createdAt
      billedAmount
      lastOrderAt
      orderCount
      orderVolume
      powerOfAttorneyGoogle
      isPowerOfAttorneyGoogleSent
      manager {
        id
        firstName
        lastName
        name
        createdAt
        phone
        user {
          id
          email
          avatar
        }
      }
      currentSupplier {
        id
      }
      orders(
        take: $take
        skip: $skip
        where: $where
        orderBy: { createdAt: desc }
      ) {
        items {
          id
          totalAmount
        }
        totalCount
      }
    }
  }
`;

export const CUSTOMER_ONLINE_PROFILES: TypedDocumentNode<
  { allOnlineProfiles: AdminOnlineProfile[] },
  AdminOnlineProfileArgs
> = gql`
  query allOnlineProfiles($where: AdminOnlineProfileWhereInput!) {
    allOnlineProfiles(where: $where) {
      id
      potentialRemovalsCount
      platform {
        id
        title
      }
      location {
        id
        title
        address
        city
        zipCode
      }
    }
  }
`;

export const CUSTOMER_DETAILS: TypedDocumentNode<{
  customer: AdminCustomer;
}> = gql`
  query customerDetails($id: Int!) {
    customer(id: $id) {
      id
      paymentMethod
      powerOfAttorneyGoogle
      isPowerOfAttorneyGoogleSent
      integrationSecretReviewHero
      billingAddress {
        id
        companyName
        vat
        firstName
        lastName
        gender
        email
        address
        city
        zipCode
        country
        crefo
        customInvoiceText
        customDueDays
        emailCC
      }
    }
  }
`;

export const CUSTOMER_ORDERS: TypedDocumentNode<{
  customer: AdminCustomer;
}> = gql`
  query customerOrders(
    $id: Int!
    $take: Int
    $skip: Int
    $where: AdminOrderWhereInput
  ) {
    customer(id: $id) {
      id
      orders(
        take: $take
        skip: $skip
        where: $where
        orderBy: { createdAt: desc }
      ) {
        items {
          id
          orderId
          paymentStatus
          paymentInvoiceIds
          orderStatus
          orderTypeStatus
          createdAt
          totalAmount
          billableAmount
          orderLocation
          paymentMethod
          price
          actionItemsCount
          product {
            id
            platform {
              id
              icon
              title
            }
          }
          paymentSummary {
            billableAllTimeCount
            openCount
            openWithoutOnHoldCount
            lostCount
            onHoldCount
          }
        }
        totalCount
      }
      billingAddress {
        id
        companyName
      }
    }
  }
`;

export const CUSTOMER_ORDER_STEP_GENERAL: TypedDocumentNode<{
  customer: AdminCustomer;
}> = gql`
  query customerOrderStepGeneral($id: Int!) {
    customer(id: $id) {
      id
      billingAddress {
        id
        companyName
      }
    }
  }
`;

export const CUSTOMER_LOCATIONS: TypedDocumentNode<
  { customer: AdminCustomer },
  IdInput
> = gql`
  query customerLocations($id: Int!) {
    customer(id: $id) {
      id
      customerId
      email
      isAgency
      isBlacklisted
      isScrapingAvoided
      billingAddress {
        id
        companyName
        vat
        firstName
        lastName
        gender
        address
        city
        zipCode
        country
        crefo
        email
        customInvoiceText
        customDueDays
        emailCC
      }
      locations {
        id
        title
        address
        phone
        city
        zipCode
        country
        website
        placeId
        mapsLink
        billingAddress {
          id
          companyName
          vat
          firstName
          lastName
          gender
          address
          city
          zipCode
          country
          crefo
          customInvoiceText
          customDueDays
          email
          emailCC
        }
        onlineProfiles {
          id
          link
          platform {
            id
            title
            icon
          }
        }
      }
      createdAt
      billedAmount
      orderCount
      orderVolume
      manager {
        id
        firstName
        lastName
        name
      }
    }
  }
`;

export const CUSTOMER_USER: TypedDocumentNode<{
  customer: AdminCustomer;
}> = gql`
  query customerUser($id: Int!) {
    customer(id: $id) {
      id
      customerPortalPassword
      pricingCategories {
        id
        products {
          id
          platform {
            id
            title
          }
        }
        pricingRules {
          id
          pricingCategoryId
          unitPrice
          minUnits
          maxUnits
        }
      }
      user {
        id
        email
        lastOnlineAt
      }
    }
  }
`;

export const CUSTOMER_PORTAL_CREDENTIALS_PREVIEW: TypedDocumentNode<
  { customer: Pick<AdminCustomer, 'customerPortalCredentialsPreview'> },
  { customerId: number }
> = gql`
  query customer($customerId: Int!) {
    customer(id: $customerId) {
      id
      customerPortalCredentialsPreview {
        to
        subject
        content
      }
    }
  }
`;

export type CustomerTotalNegativeReviewsResult = {
  id: number;
  totalNegativeReviewsCount: number;
};

export const CUSTOMERS_TOTAL_NEGATIVE_REVIEWS_COUNT: TypedDocumentNode<{
  customersTotalNegativeReviewsCount: CustomerTotalNegativeReviewsResult[];
}> = gql`
  query customersTotalNegativeReviewsCount($ids: [Int!]!) {
    customersTotalNegativeReviewsCount(ids: $ids) {
      id
      totalNegativeReviewsCount
    }
  }
`;

export const COMPANY = gql`
  query company($id: Int!) {
    customer(id: $id) {
      id
      billingAddress {
        id
        companyName
      }
    }
  }
`;

export const TIMELINE: TypedDocumentNode<
  { allTimelineEntries: AdminTimelinePaginatedList },
  AdminTimelineEntryArgs
> = gql`
  query allTimelineEntries(
    $take: Int
    $skip: Int
    $where: AdminTimelineEntryWhereInput
    $orderBy: [AdminTimelineEntryOrderInput!]
  ) {
    allTimelineEntries(
      take: $take
      skip: $skip
      where: $where
      orderBy: $orderBy
    ) {
      items {
        id
        type
        isBot
        createdAt
        isPinned
        author {
          id
          firstName
          lastName
          name
          user {
            id
            avatar
          }
        }
        status {
          effectiveAt
          isUpcoming
        }
        association {
          ... on AdminOffer {
            id
            slug
            status
            totalAmount
            expiresAt
          }
        }
        event {
          ... on AdminEventNote {
            id
            content
          }
          ... on AdminEventUpdate {
            id
            isCreationEvent
            field
            oldValue
            newValue
          }
          ... on AdminEventMail {
            id
            trackingId
            trackingStatus
            subject
            snippet
            gmailThreadId
            gmailFrom
          }
          ... on AdminEventLog {
            id
            description
            callOutcome
            date
          }
          ... on AdminTask {
            id
            isCompleted
            assignee {
              id
              name
            }
            title
            note
            dueAt
          }
          ... on AdminEventCall {
            id
            direction
            duration
            link
            missedCallReason
          }
        }
      }
      totalCount
    }
  }
`;

export const PAYOUT_RELATED_TIMELINE_ENTRIES: TypedDocumentNode<
  { payoutRelatedTimelineEntries: AdminTimelinePaginatedList },
  AdminPayoutRelatedTimelineEntryArgs
> = gql`
  query payoutRelatedTimelineEntries($staffId: Int!, $skip: Int, $take: Int) {
    payoutRelatedTimelineEntries(staffId: $staffId, skip: $skip, take: $take) {
      items {
        id
        type
        isBot
        createdAt
        isPinned
        author {
          id
          firstName
          lastName
          name
          user {
            id
            avatar
          }
        }
        status {
          effectiveAt
          isUpcoming
        }
        event {
          ... on AdminEventUpdate {
            id
            field
            oldValue
            newValue
          }
        }
        association {
          ... on AdminPayment {
            id
            invoiceId
            amount
            orderId
            status
          }
          ... on AdminOrder {
            id
            totalAmount
            commission
            quantity
          }
        }
      }
      totalCount
    }
  }
`;

export const FAILED_EMAILS: TypedDocumentNode<
  { allTimelineEntries: AdminTimelinePaginatedList },
  AdminTimelineEntryArgs
> = gql`
  query failedEmails(
    $take: Int
    $skip: Int
    $where: AdminTimelineEntryWhereInput
  ) {
    allTimelineEntries(take: $take, skip: $skip, where: $where) {
      items {
        id
        event {
          ... on AdminEventMail {
            id
            subject
          }
        }
        association {
          ... on Customer {
            id
          }
          ... on AdminPayment {
            id
            orderId
          }
          ... on AdminOrder {
            id
          }
          ... on AdminLead {
            id
          }
        }
      }
      totalCount
    }
  }
`;

// Platforms

export const ALL_PLATFORMS: TypedDocumentNode<{
  allPlatforms: AdminPlatform[];
}> = gql`
  query allPlatforms($productRemovalQuantity: Int) {
    allPlatforms {
      id
      title
      slug
      icon
      products {
        id
        basePrice(quantity: $productRemovalQuantity)
      }
    }
  }
`;

export const SCRAPABLE_PLATFORMS: TypedDocumentNode<{
  scrapablePlatforms: AdminPlatform[];
}> = gql`
  query scrapablePlatforms($productRemovalQuantity: Int) {
    scrapablePlatforms {
      id
      title
      slug
      icon
      products {
        id
        basePrice(quantity: $productRemovalQuantity)
      }
    }
  }
`;

// Orders

export const ALL_ORDERS: TypedDocumentNode<
  {
    allOrders: AdminOrderPaginatedList;
  },
  AdminOrderArgs
> = gql`
  query allOrders(
    $take: Int
    $skip: Int
    $where: AdminOrderWhereInput
    $orderBy: AdminOrderSortInput
  ) {
    allOrders(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      items {
        id
        createdAt
        orderId
        source
        quantity
        totalAmount
        billableAmount
        billableBalance
        commission
        paymentStatus
        orderStatus
        orderTypeStatus
        orderTypeStatusUpdatedAt
        actionItemsCount
        product {
          id
          platform {
            id
            icon
            title
          }
        }
        paymentSummary {
          billableAllTimeCount
          openCount
          openWithoutOnHoldCount
          lostCount
          onHoldCount
        }
        customer {
          id
          billingAddress {
            id
            companyName
          }
        }
        salesResponsible {
          id
          firstName
          lastName
          name
        }
        payments {
          id
          updatedAt
        }
      }
      totalCount
    }
  }
`;

export const EXPORT_ORDERS: TypedDocumentNode<
  { exportOrders: AdminAttachment },
  AdminExportOrderArgs
> = gql`
  query exportOrders($where: AdminOrderWhereInput) {
    exportOrders(where: $where) {
      key
    }
  }
`;

export const ORDER_SENT_EMAILS: TypedDocumentNode<
  { order: AdminOrder },
  IdInput
> = gql`
  query orderSendEmail($id: Int!) {
    order(id: $id) {
      id
      isOrderConfirmationSent
      powerOfAttorney
      isPowerOfAttorneySent
      product {
        platform {
          id
        }
      }
      payments {
        id
        isInvoiceSent
      }
      customer {
        id
        isPowerOfAttorneyGoogleSent
        powerOfAttorneyGoogle
      }
    }
  }
`;

export const ORDER_GENERAL: TypedDocumentNode<{
  order: AdminOrder;
  id: IdInput;
}> = gql`
  query orderGeneral($id: Int!) {
    order(id: $id) {
      id
      createdAt
      orderId
      orderStatus
      orderTypeStatus
      isOrderConfirmationSent
      isPowerOfAttorneySent
      source
      quantity
      commission
      totalAmount
      customerConfirmation
      powerOfAttorney
      orderCancellation {
        id
        reason
        note
      }
      salesResponsible {
        id
        firstName
        lastName
        name
        user {
          id
          avatar
        }
      }
      removalAssistant {
        id
        firstName
        lastName
        name
        user {
          id
          avatar
        }
      }
      customer {
        id
        customerId
        firstName
        lastName
        gender
        name
        email
        phoneNumbers
        manager {
          id
          firstName
          lastName
          name
          user {
            id
            avatar
          }
        }
        billingAddress {
          id
          address
          zipCode
          city
          country
          companyName
        }
      }
      type
      product {
        id
        title
        platform {
          id
          title
        }
      }
      price
    }
  }
`;

export const ORDER_BILLING: TypedDocumentNode<{
  order: AdminOrder;
  id: IdInput;
}> = gql`
  query orderBilling($id: Int!) {
    order(id: $id) {
      id
      quantity
      billableBalance
      billableAmount
      totalAmount
      paymentMethod
      hasInstallmentPayments
      isBillingPaused
      crefo
      paymentSummary {
        unitPrice
        billableAllTimeCount
        billableCurrentCount
        openCount
        lostCount
      }
      payments {
        id
        invoiceId
        invoiceUrl
        status
        amount
        amountWithVat
        type
        isInvoiceSent
        isReminder1Sent
        isReminder2Sent
        stornoInvoiceId
        timeline(orderBy: { createdAt: desc }) {
          id
          type
          author {
            id
            name
          }
          event {
            ... on AdminEventNote {
              id
              content
            }
            ... on AdminEventUpdate {
              id
              field
              oldValue
              newValue
            }
            ... on AdminEventMail {
              id
              trackingId
              trackingStatus
              subject
            }
          }
          isBot
          createdAt
        }
      }
      customer {
        id
        paymentMethod
      }
    }
  }
`;

export const ORDER_BASIC_DETAILS: TypedDocumentNode<{
  order: Pick<AdminOrder, 'hasUnwoundLineItems'>;
  id: IdInput;
}> = gql`
  query orderBasicDetails($id: Int!) {
    order(id: $id) {
      id
      hasUnwoundLineItems
    }
  }
`;

export const ORDER_DETAILS: TypedDocumentNode<{
  order: AdminOrder;
  id: IdInput;
}> = gql`
  query orderDetails(
    $id: Int!
    $take: Int
    $skip: Int
    $searchBy: String
    $statusFilter: String
  ) {
    order(id: $id) {
      id
      type
      quantity
      slug
      removalAssistant {
        id
        firstName
        lastName
        name
      }
      hasUnwoundLineItems
      lineItemsCount(searchBy: $searchBy, statusFilter: $statusFilter)
      lineItems(
        take: $take
        skip: $skip
        searchBy: $searchBy
        statusFilter: $statusFilter
      ) {
        id
        quantity
        price
        createdAt
        removalSubmission {
          id
          lawyer {
            id
            name
            companyName
            representativeName
          }
          onlineProfile {
            id
            link
            location {
              id
              title
              address
              zipCode
              city
            }
          }
          proofScreenshot
          proofVideo
          referenceNumber
          entries {
            id
            status
            reviewerName
            reviewerLink
            reviewText
            removalRequest
            comment
            noText
            pseudonym
            recent
            notForwardedAt
            transitionHistory {
              id
              createdAt
              statusEnd
              staff {
                id
                name
              }
            }
          }
        }
        product {
          id
          title
          platform {
            id
            title
            icon
          }
        }
      }
    }
  }
`;

export const ORDER_PRODUCT: TypedDocumentNode<{
  order: AdminOrder;
  id: IdInput;
}> = gql`
  query orderProduct($id: Int!) {
    order(id: $id) {
      id
      type
      quantity
      product {
        id
        title
        platform {
          id
          title
        }
      }
      customer {
        id
        billingAddress {
          id
          companyName
        }
      }
    }
  }
`;

export const ORDER_CONFIRMATION_PREVIEW: TypedDocumentNode<
  { order: Pick<AdminOrder, 'confirmationEmailPreview'> },
  { orderId: number }
> = gql`
  query order($orderId: Int!) {
    order(id: $orderId) {
      id
      confirmationEmailPreview {
        to
        subject
        content
        attachments {
          filename
          url
          key
        }
      }
    }
  }
`;

// Products

export const ALL_PRODUCTS: TypedDocumentNode<{
  allProducts: AdminProduct[];
}> = gql`
  query allProducts {
    allProducts {
      id
      title
      basePrice
      type
      platform {
        id
        title
      }
    }
  }
`;

export const ALL_PRICE_CATEGORIES: TypedDocumentNode<{
  allPriceCategories: AdminPricingCategory[];
}> = gql`
  query allPriceCategories {
    allPriceCategories {
      id
      products {
        id
        platform {
          id
          title
        }
      }
    }
  }
`;

export const POWER_OF_ATTORNEY_ORDER_PREVIEW: TypedDocumentNode<
  { customer: Pick<AdminOrder, 'powerOfAttorneyOrderEmailPreview'> },
  { customerId: number }
> = gql`
  query order($orderId: Int!) {
    order(id: $orderId) {
      id
      powerOfAttorneyOrderEmailPreview {
        to
        subject
        content
        attachments {
          filename
          url
          key
        }
      }
    }
  }
`;

export const POWER_OF_ATTORNEY_GOOGLE_PREVIEW: TypedDocumentNode<
  { customer: Pick<AdminCustomer, 'powerOfAttorneyGoogleEmailPreview'> },
  { customerId: number }
> = gql`
  query customer($customerId: Int!) {
    customer(id: $customerId) {
      id
      powerOfAttorneyGoogleEmailPreview {
        to
        subject
        content
        attachments {
          filename
          url
          key
        }
      }
    }
  }
`;

export const OWNER_COMMENTS_REMINDER_PREVIEW: TypedDocumentNode<
  { order: Pick<AdminOrder, 'ownerCommentsReminderEmailPreview'> },
  { orderId: number }
> = gql`
  query order($orderId: Int!) {
    order(id: $orderId) {
      id
      ownerCommentsReminderEmailPreview {
        to
        subject
        content
        attachments {
          filename
          url
          key
        }
      }
    }
  }
`;

// Payments

export const INVOICE_PREVIEW: TypedDocumentNode<
  { payment: Pick<AdminPayment, 'invoiceEmailPreview'> },
  { paymentId: number; forceRegenerate: boolean }
> = gql`
  query payment($paymentId: Int!, $forceRegenerate: Boolean) {
    payment(id: $paymentId) {
      id
      invoiceEmailPreview(forceRegenerate: $forceRegenerate) {
        to
        cc
        subject
        content
        attachments {
          filename
          url
          key
        }
      }
    }
  }
`;

export const REMINDER_1_PREVIEW: TypedDocumentNode<
  { payment: Pick<AdminPayment, 'reminder1EmailPreview'> },
  { paymentId: number }
> = gql`
  query payment($paymentId: Int!) {
    payment(id: $paymentId) {
      id
      reminder1EmailPreview {
        to
        cc
        subject
        content
        attachments {
          filename
          url
          key
        }
      }
    }
  }
`;

export const REMINDER_2_PREVIEW: TypedDocumentNode<
  { payment: Pick<AdminPayment, 'reminder2EmailPreview'> },
  { paymentId: number }
> = gql`
  query payment($paymentId: Int!) {
    payment(id: $paymentId) {
      id
      reminder2EmailPreview {
        to
        cc
        subject
        content
        attachments {
          filename
          url
          key
        }
      }
    }
  }
`;

export const LEAD_EMAIL_PREVIEW: TypedDocumentNode<
  { lead: Pick<AdminLead, 'leadEmailPreview'> },
  { leadId: number }
> = gql`
  query lead($leadId: Int!) {
    lead(id: $leadId) {
      id
      leadEmailPreview {
        to
        subject
        content
      }
    }
  }
`;

// Reviews

export const ALL_REVIEWS: TypedDocumentNode<{
  allReviews: ReviewPaginatedList;
}> = gql`
  query allReviews(
    $take: Int
    $skip: Int
    $where: ReviewWhereInput
    $orderBy: [ReviewSortInput!]
  ) {
    allReviews(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      totalCount
      items {
        id
        createdAt
        updatedAt
        reviewerName
        responseFromOwnerText
        isReviewerAuthority
        normalizedScore
        removabilityScore
        date
        title
        url
        description
        onlineProfile {
          id
          createdAt
          updatedAt
          link
          locationId
          customerId
          platformSlug
        }
      }
    }
  }
`;

export const ALL_REVIEWS_IDS: TypedDocumentNode<{
  allReviewsIds: IdInput[];
}> = gql`
  query allReviewsIds(
    $take: Int
    $skip: Int
    $where: ReviewWhereInput
    $orderBy: [ReviewSortInput!]
  ) {
    allReviewsIds(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      id
    }
  }
`;

export const DUPLICATE_REMOVAL_ENTRIES: TypedDocumentNode<
  { duplicateRemovalEntries: string[] },
  AdminDuplicateRemovalEntryArgs
> = gql`
  query duplicateRemovalEntries($reviewIds: [String!]!) {
    duplicateRemovalEntries(reviewIds: $reviewIds)
  }
`;

export const REMOVAL_ENTRIES_WITH_IDS: TypedDocumentNode<
  { entriesWithIds: AdminRemovalEntry[] },
  { ids: string[] }
> = gql`
  query entriesWithIds($ids: [String!]!) {
    entriesWithIds(ids: $ids) {
      id
      reviewId
      status
      orderId
    }
  }
`;

export const ONLINE_PROFILE_LAST_SCAN: TypedDocumentNode<{
  onlineProfile: {
    id: string;
    isPaused: boolean;
    lastScan: Scan | null;
  } | null;
}> = gql`
  query onlineProfile($id: String!) {
    onlineProfile(id: $id) {
      id
      isPaused
      lastScan {
        id
        status
        reviewCount
        createdAt
        updatedAt
      }
    }
  }
`;

export const SCAN_CACHE: TypedDocumentNode<
  {
    scanCache: {
      id: string;
      platformSlug: string;
      status: ScanStatus;
      reviews: ReviewPaginatedList;
    };
  },
  {
    id: string;
    starsMin?: number;
    starsMax?: number;
    skip: number;
    take: number;
  }
> = gql`
  query($id: String!, $starsMin: Int, $starsMax: Int, $skip: Int, $take: Int) {
    scanCache(
      id: $id
      starsMin: $starsMin
      starsMax: $starsMax
      skip: $skip
      take: $take
    ) {
      id
      status
      platformSlug
      reviews {
        items {
          id
          reviewerName
          normalizedScore
          date
          title
          url
          description
          responseFromOwnerText
          isReviewerAuthority
          removabilityScore
        }
        totalCount
      }
    }
  }
`;

export const CRAWL_CUSTOMER: TypedDocumentNode<{ crawlCustomer: number }> = gql`
  mutation crawlCustomer($id: String!) {
    crawlCustomer(id: $id)
  }
`;

export const CRAWL_CUSTOMER_STATUS: TypedDocumentNode<{
  customerCrawlStatus: CustomerCrawlStatus;
}> = gql`
  query customerCrawlStatus($id: String!) {
    customerCrawlStatus(id: $id) {
      isCrawling
      progress
    }
  }
`;

// Dashboard

// Dashboard Sales

export const SALES_BREAKDOWN_BY_PERIOD: TypedDocumentNode<
  {
    salesBreakdownByPeriod: AdminLineData;
  },
  AdminStatsSalesArgs
> = gql`
  query salesBreakdownByPeriod(
    $product: IdInput
    $salesResponsible: IdInput
    $source: OrderSource
    $period: SalesStatsPeriodOption!
  ) {
    salesBreakdownByPeriod(
      product: $product
      salesResponsible: $salesResponsible
      source: $source
      period: $period
    ) {
      labels
      datasets {
        label
        data
      }
    }
  }
`;

export const OWN_SALES_BREAKDOWN_BY_PERIOD: TypedDocumentNode<
  {
    ownSalesBreakdownByPeriod: AdminLineData;
  },
  AdminStatsOwnSalesArgs
> = gql`
  query ownSalesBreakdownByPeriod(
    $product: IdInput
    $period: SalesStatsPeriodOption!
    $metric: KPI!
  ) {
    ownSalesBreakdownByPeriod(
      product: $product
      period: $period
      metric: $metric
    ) {
      labels
      datasets {
        label
        data
      }
    }
  }
`;

export const SALES_OVERVIEW: TypedDocumentNode<{
  salesOverview: AdminStatsSalesOverviewMetric[];
}> = gql`
  query salesOverview {
    salesOverview {
      period
      value
      priorPeriodValue
      delta
    }
  }
`;

export const SALES_BY_AGENTS: TypedDocumentNode<
  { salesByAgents: AdminSalesByAgentsMetrics },
  AdminStatsSalesByAgentsArgs
> = gql`
  query salesByAgents(
    $roleIds: [Int!]!
    $active: Boolean
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    salesByAgents(
      roleIds: $roleIds
      active: $active
      startAt: $startAt
      endAt: $endAt
    ) {
      staff {
        id
        name
        sales
        firstSale
        secondSales
        paidSales
        commissionTotal
        billableAmount
        billableBalance
        openAmount
        ordersCount
        removalsCount
        walletPaid
        walletTotal
        avgRemovalPrice
      }
      total {
        sales
        firstSale
        secondSales
        paidSales
        commissionTotal
        billableAmount
        billableBalance
        openAmount
        ordersCount
        removalsCount
        avgRemovalPrice
      }
    }
  }
`;

export const PAYOUTS_FOR_STAFF: TypedDocumentNode<
  {
    allPayoutsForStaff: AdminPayoutPaginatedList;
  },
  AdminPayoutArgs
> = gql`
  query getPayouts($take: Int, $skip: Int, $where: AdminPayoutWhereInput) {
    allPayoutsForStaff(take: $take, skip: $skip, where: $where) {
      totalCount
      items {
        id
        amount
        staff {
          name
        }
        initiator {
          name
        }
        note
        createdAt
      }
    }
  }
`;

export const PRODUCTS_OVERVIEW: TypedDocumentNode<
  { productsOverview: AdminStatsAllProductsOverviewMetric },
  AdminStatsByDateArgs
> = gql`
  query productsOverview($startAt: DateTime!, $endAt: DateTime!) {
    productsOverview(startAt: $startAt, endAt: $endAt) {
      products {
        type
        title
        sales
        percentOfTotal
        paidSales
        percentOfTotalPaid
      }
      paidSales
      totalSales
    }
  }
`;

export const SOURCES_OVERVIEW: TypedDocumentNode<
  { sourcesOverview: AdminSalesStats },
  AdminStatsByDateArgs
> = gql`
  query sourcesOverview($startAt: DateTime!, $endAt: DateTime!) {
    sourcesOverview(startAt: $startAt, endAt: $endAt) {
      rows {
        label
        sales
        percentOfTotal
        paidSales
        percentOfTotalPaid
      }
      paidSales
      totalSales
    }
  }
`;

export const LOCATION_CATEGORIES_OVERVIEW: TypedDocumentNode<
  { locationCategoriesStats: AdminSalesStats },
  AdminStatsByDateArgs
> = gql`
  query locationCategoriesStats($startAt: DateTime!, $endAt: DateTime!) {
    locationCategoriesStats(startAt: $startAt, endAt: $endAt) {
      rows {
        label
        sales
        percentOfTotal
        paidSales
        percentOfTotalPaid
      }
      paidSales
      totalSales
    }
  }
`;

export const SALES_COMPARE_BY_PERIOD: TypedDocumentNode<
  {
    salesCompareByPeriod: AdminLineData;
  },
  AdminStatsSalesCompareArgs
> = gql`
  query salesCompareByPeriod(
    $product: IdInput
    $period: SalesStatsPeriodOption!
    $metric: KPI!
    $roleIds: [Int!]!
    $isActive: Boolean
  ) {
    salesCompareByPeriod(
      product: $product
      period: $period
      metric: $metric
      roleIds: $roleIds
      isActive: $isActive
    ) {
      labels
      datasets {
        label
        data
      }
    }
  }
`;

// Dashboard Customers

export const CUSTOMERS_OVERVIEW: TypedDocumentNode<{
  customerOverview: AdminCustomerStatsOverview[];
}> = gql`
  query customerOverview {
    customerOverview {
      value
      delta
      period
      type
      priorPeriodValue
      price
    }
  }
`;

export const CUSTOMER_BREAKDOWN_BY_PERIOD: TypedDocumentNode<
  {
    customerBreakdownByPeriod: AdminLineData;
  },
  AdminStatsCustomerArgs
> = gql`
  query customerBreakdownByPeriod($period: SalesStatsPeriodOption!) {
    customerBreakdownByPeriod(period: $period) {
      labels
      datasets {
        data
        label
      }
    }
  }
`;

export const CUSTOMER_STATS: TypedDocumentNode<
  {
    customerStats: AdminCustomerStatsList;
  },
  AdminStatsByDateArgs
> = gql`
  query customerStats($startAt: DateTime!, $endAt: DateTime!) {
    customerStats(startAt: $startAt, endAt: $endAt) {
      customers {
        customerName
        managerName
        orderCount
        removalCount
        sales
        paid
        lastOrderAt
      }
    }
  }
`;

export const MANAGER_STATS: TypedDocumentNode<{
  managerStats: AdminManagerStatsList;
}> = gql`
  query managerStats {
    managerStats {
      managers {
        name
        customerCount
        customerRatio
      }
    }
  }
`;

export const CUSTOMER_AGE_STATS: TypedDocumentNode<
  { customerAgeStats: AdminCustomerAgeStatsMetrics },
  AdminStatsByDateArgs
> = gql`
  query customerAgeStats($startAt: DateTime!, $endAt: DateTime!) {
    customerAgeStats(startAt: $startAt, endAt: $endAt) {
      managers {
        managerId
        manager
        month1
        month1to3
        month3to6
        month6to12
        afterYear1
        totalSales
        newCustomerCount
      }
      total {
        manager
        month1
        month1to3
        month3to6
        month6to12
        afterYear1
        totalSales
        newCustomerCount
      }
    }
  }
`;

// Dashboard Review Removals

export const PLATFORM_PERFORMANCE_STATS: TypedDocumentNode<{
  platformStats: AdminOverallPlatformStats;
}> = gql`
  query platformStats($startAt: DateTime!, $endAt: DateTime!) {
    platformStats(startAt: $startAt, endAt: $endAt) {
      platforms {
        id
        title
        orderCount
        forwardRate
        removalCount
        forwardRate
        forwardAvgDays
        forwardMinDays
        forwardMaxDays
        avgCompletionDays
        removalRate
      }
      totalPlatformStats {
        removalRate
        orderCount
        avgCompletionDays
        removalCount
      }
    }
  }
`;

export const PLATFORMS_COMPARE_BY_PERIOD: TypedDocumentNode<
  {
    platformsBreakdownByPeriod: AdminLineData;
  },
  AdminStatsPlatformsArgs
> = gql`
  query platformsBreakdownByPeriod($period: SalesStatsPeriodOption!) {
    platformsBreakdownByPeriod(period: $period) {
      labels
      datasets {
        label
        data
      }
    }
  }
`;

export const GOOGLE_FORWARDED_BY_PERIOD: TypedDocumentNode<
  {
    googleForwardedBreakdownByPeriod: AdminLineData;
  },
  AdminStatsPlatformsArgs
> = gql`
  query googleForwardedBreakdownByPeriod($period: SalesStatsPeriodOption!) {
    googleForwardedBreakdownByPeriod(period: $period) {
      labels
      datasets {
        label
        data
      }
    }
  }
`;

export const GOOGLE_ROLLING_AVG_BY_DAYS: TypedDocumentNode<
  {
    googleRollAvgBreakdownByPeriod: AdminLineData;
  },
  AdminStatsGoogleRollingAvgArgs
> = gql`
  query googleRollAvgBreakdownByPeriod($date: String!) {
    googleRollAvgBreakdownByPeriod(date: $date) {
      labels
      datasets {
        label
        data
      }
    }
  }
`;

export const LEADS_AVG_WON_LOST: TypedDocumentNode<
  {
    leadStatsAvgDaysWonLost: AdminLeadsWonLostMetric[];
  },
  AdminStatsByDateArgs
> = gql`
  query leadStatsAvgDaysWonLost($startAt: DateTime!, $endAt: DateTime!) {
    leadStatsAvgDaysWonLost(startAt: $startAt, endAt: $endAt) {
      managerId
      manager
      wonCount
      lostCount
      avgDaysToWon
      avgDaysToLost
      percentWon
    }
  }
`;

export const LEADS_LOSS_COUNT: TypedDocumentNode<
  {
    leadLossStats: AdminLeadLossMetricData;
  },
  AdminStatsByStaffAndDateArgs
> = gql`
  query leadLossStats(
    $startAt: DateTime!
    $endAt: DateTime!
    $manager: IdInput
    $take: Int
    $skip: Int
  ) {
    leadLossStats(
      startAt: $startAt
      endAt: $endAt
      manager: $manager
      take: $take
      skip: $skip
    ) {
      lossMetrics {
        reason
        lostCount
      }
      count
    }
  }
`;

export const REPORTS_NOT_FORWARDED: TypedDocumentNode<
  {
    reportNotForwarded: AdminAttachment;
  },
  AdminReportsNotForwardedArgs
> = gql`
  query reportNotForwarded($platforms: [Int!]!) {
    reportNotForwarded(platforms: $platforms) {
      key
    }
  }
`;

export const REPORT_CUSTOMER_REVIEWS: TypedDocumentNode<
  { reportCustomerReviews: boolean },
  AdminReportsCustomerReviewsArgs
> = gql`
  query reportCustomerReviews(
    $customerId: Int!
    $startAt: DateTime
    $endAt: DateTime
    $locationId: [Int!]
    $platformSlugs: [String!]
    $ratings: [Int!]
  ) {
    reportCustomerReviews(
      customerId: $customerId
      startAt: $startAt
      endAt: $endAt
      locationId: $locationId
      platformSlugs: $platformSlugs
      ratings: $ratings
    )
  }
`;

export const REPORT_ORDER_OVERVIEW: TypedDocumentNode<
  { reportOrderOverview: AdminAttachment },
  IdInput
> = gql`
  query reportOrderOverview($id: Int!) {
    reportOrderOverview(id: $id) {
      url
    }
  }
`;

export const REPORT_INITIATED_FORWARDED: TypedDocumentNode<
  { reportInitiatedForwarded: AdminAttachment },
  AdminReportsInitiatedForwardedArgs
> = gql`
  query reportInitiatedForwarded(
    $statuses: [RemovalEntryStatus!]!
    $platformId: Int!
  ) {
    reportInitiatedForwarded(statuses: $statuses, platformId: $platformId) {
      key
      filename
    }
  }
`;

export const REPORT_FACTORING_INVOICES: TypedDocumentNode<
  { reportFactoringInvoices: AdminAttachment },
  AdminReportsInvoicesArgs
> = gql`
  query reportFactoringInvoices($startDate: DateTime!, $endDate: DateTime!) {
    reportFactoringInvoices(startDate: $startDate, endDate: $endDate) {
      key
      filename
    }
  }
`;

export const REPORT_CREATED_INOICES: TypedDocumentNode<
  { reportCreatedInvoices: AdminAttachment },
  AdminReportsInvoicesArgs
> = gql`
  query reportCreatedInvoices(
    $startDate: DateTime!
    $endDate: DateTime!
    $factoringOnly: Boolean
  ) {
    reportCreatedInvoices(
      startDate: $startDate
      endDate: $endDate
      factoringOnly: $factoringOnly
    ) {
      url
      filename
    }
  }
`;

export const BILLED_REMOVALS_STATS: TypedDocumentNode<{
  billedStats: AdminBillingStats[];
}> = gql`
  query billedStats(
    $startAt: DateTime!
    $endAt: DateTime!
    $platform: IdInput
  ) {
    billedStats(startAt: $startAt, endAt: $endAt, platform: $platform) {
      amount
      count
      status
    }
  }
`;

export const REMOVALS_STATS: TypedDocumentNode<{
  removalsStats: AdminRemovalStats[];
}> = gql`
  query removalsStats(
    $startAt: DateTime
    $endAt: DateTime
    $includePayments: Boolean!
    $removalStatuses: [RemovalEntryStatus!]!
    $platform: IdInput
  ) {
    removalsStats(
      startAt: $startAt
      endAt: $endAt
      removalStatuses: $removalStatuses
      includePayments: $includePayments
      platform: $platform
    ) {
      amount
      count
      status
    }
  }
`;

// Dashboard Activity

export const ACTIVITY_OVERVIEW: TypedDocumentNode<{
  activityOverviewSM: AdminAgentActivityData[];
  activityOverviewAM: AdminAgentActivityData[];
  activityOverviewTL: AdminAgentActivityData[];
  customerSupplyStats: AdminCustomerSupplyStats[];
}> = gql`
  query activityOverview(
    $startAt: DateTime!
    $endAt: DateTime!
    $callDirection: CallDirectionOptions!
    $emailDirection: EmailOptions!
  ) {
    activityOverviewSM: activityOverview(
      startAt: $startAt
      endAt: $endAt
      callDirection: $callDirection
      emailDirection: $emailDirection
      roleIds: [${StaticRoles.salesManager},${StaticRoles.squadLead}]
    ) {
        name
        callsCount
        emailsCount
        totalCount
    }
    activityOverviewAM: activityOverview(
      startAt: $startAt
      endAt: $endAt
      callDirection: $callDirection
      emailDirection: $emailDirection
      roleIds: [${StaticRoles.accountManager}]
    ) {
        name
        callsCount
        emailsCount
        totalCount
    }
    customerSupplyStats(startAt: $startAt, endAt: $endAt) {
      name
      totalCount
    }
  }
`;

export const ACTIVITY_CALL_DURATION: TypedDocumentNode<{
  activityCallDuration: AdminAgentCallDurationData[];
}> = gql`
  query activityCallDuration(
    $startAt: DateTime!
    $endAt: DateTime!
    $callDirection: CallDirectionOptions!
    $emailDirection: EmailOptions!
  ) {
    activityCallDuration(
      startAt: $startAt
      endAt: $endAt
      callDirection: $callDirection
      emailDirection: $emailDirection
      roleIds: [${StaticRoles.salesManager},${StaticRoles.squadLead}]) {
      name
      totalCallDuration
      avgCallDuration
    }
  }
`;

// Leads
export const LEADS_IDS: TypedDocumentNode<
  {
    leadsIds: AdminLeadId[];
  },
  AdminLeadStatusesArgs
> = gql`
  query leadsIds($where: AdminLeadWhereInput) {
    leadsIds(where: $where) {
      id
    }
  }
`;

export const ALL_LEADS: TypedDocumentNode<
  {
    leads: AdminLeadPaginatedList;
  },
  AdminLeadArgs
> = gql`
  query leads(
    $take: Int
    $skip: Int
    $where: AdminLeadWhereInput
    $orderBy: AdminLeadSortInput
  ) {
    leads(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      items {
        id
        companyName
        categoryName
        parentCategoryName
        googleScore
        negativeReviewsCount
        status
        googleUrl
        leadEmailPreview {
          content
        }
        lastNote {
          id
          content
          timelineEntry {
            id
            createdAt
          }
        }
        nextTask {
          id
          dueAt
          title
        }
        manager {
          id
          name
        }
        offlineCampaign {
          id
        }
      }
      totalCount
    }
  }
`;

export const OFFLINE_CAMPAIGN_LEADS: TypedDocumentNode<
  {
    offlineCampaignLeads: AdminLeadPaginatedList;
  },
  AdminLeadArgs
> = gql`
  query offlineCampaignLeads(
    $take: Int
    $skip: Int
    $where: AdminLeadWhereInput
    $orderBy: AdminLeadSortInput
  ) {
    offlineCampaignLeads(
      take: $take
      skip: $skip
      where: $where
      orderBy: $orderBy
    ) {
      items {
        id
        companyName
        categoryName
        parentCategoryName
        googleScore
        negativeReviewsCount
        status
        googleUrl
        leadEmailPreview {
          content
        }
        lastNote {
          id
          content
          timelineEntry {
            id
            createdAt
          }
        }
        nextTask {
          id
          dueAt
          title
        }
        manager {
          id
          name
        }
        offlineCampaign {
          id
        }
      }
      totalCount
    }
  }
`;

export const LEAD: TypedDocumentNode<{ lead: AdminLead }, IdInput> = gql`
  query lead($id: Int!) {
    lead(id: $id) {
      id
      companyName
      categoryName
      parentCategoryName
      googleScore
      googleUrl
      reviewsCount
      negativeReviewsCount
      website
      gender
      firstName
      lastName
      email
      phone
      placeId
      address
      zipCode
      city
      country
      status
      isKeyAccount
      defaultOfferListPrice
      leadLoss {
        reason
      }
      manager {
        id
        name
      }
    }
  }
`;

export const PLACE: TypedDocumentNode<
  { place: AdminPlace },
  StringIdInput
> = gql`
  query place($id: String!) {
    place(id: $id) {
      leadId
      customerId
    }
  }
`;

export const LEAD_CATEGORIES: TypedDocumentNode<
  {
    leadCategories: AdminLeadCategoryPaginatedList;
  },
  IdInput
> = gql`
  query leadCategories {
    leadCategories {
      items {
        category
      }
    }
  }
`;

export const ALL_LEAD_CATEGORIES_ADMIN: TypedDocumentNode<
  {
    leadCategories: AdminLeadCategoryPaginatedList;
  },
  AdminLeadCategoryArgs
> = gql`
  query leadCategories($take: Int, $skip: Int, $where: AdminLeadCategoryInput) {
    leadCategories(take: $take, skip: $skip, where: $where) {
      items {
        name
        active
        allocationMinReviews
        allocationMaxScore
        totalLeads
        unassignedLeads
      }
      totalCount
    }
  }
`;

export const ALL_LEAD_ALLOCATION_RULES_REGIONS: TypedDocumentNode<{
  allLeadAllocationRulesRegion: AdminLeadAllocationRuleRegion[];
}> = gql`
  query {
    allLeadAllocationRulesRegion {
      id
      region
      active
    }
  }
`;

export const LEAD_STATUSES: TypedDocumentNode<
  {
    leadStatuses: AdminLeadStatusAggregate[];
  },
  AdminLeadStatusesArgs
> = gql`
  query leadStatuses($where: AdminLeadWhereInput) {
    leadStatuses(where: $where) {
      status
      totalLeads
    }
  }
`;

// Tasks

export const MY_TASKS: TypedDocumentNode<
  {
    myTasks: AdminTaskPaginatedList;
  },
  AdminTaskArgs
> = gql`
  query myTasks(
    $take: Int
    $skip: Int
    $where: AdminTaskWhereInput
    $orderBy: AdminTaskSortInput
  ) {
    myTasks(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
      items {
        id
        title
        isCompleted
        dueAt
        note
        timelineEntry {
          id
          associationStatus
          association {
            ... on AdminLead {
              id
              companyName
            }
            ... on Customer {
              id
              billingAddress {
                id
                companyName
              }
            }
            ... on AdminOrder {
              id
              quantity
              customer {
                billingAddress {
                  id
                  companyName
                }
              }
              product {
                id
                platform {
                  id
                  title
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const MY_DELEGATED_TASKS: TypedDocumentNode<
  {
    myDelegatedTasks: AdminTaskPaginatedList;
  },
  AdminTaskArgs
> = gql`
  query myDelegatedTasks(
    $take: Int
    $skip: Int
    $where: AdminTaskWhereInput
    $orderBy: AdminTaskSortInput
  ) {
    myDelegatedTasks(
      take: $take
      skip: $skip
      where: $where
      orderBy: $orderBy
    ) {
      items {
        id
        title
        isCompleted
        dueAt
        note
        timelineEntry {
          id
          associationStatus
          association {
            ... on AdminLead {
              id
              companyName
            }
            ... on Customer {
              id
              billingAddress {
                id
                companyName
              }
            }
            ... on AdminOrder {
              id
              quantity
              customer {
                billingAddress {
                  id
                  companyName
                }
              }
              product {
                id
                platform {
                  id
                  title
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const OUTSTANDING_TASKS: TypedDocumentNode<{
  outstandingTasks: number;
}> = gql`
  query outstandingTasks {
    outstandingTasks
  }
`;

export const MAIL_EVENT: TypedDocumentNode<
  { mailEvent: AdminEventMail },
  IdInput
> = gql`
  query mailEvent($id: Int!) {
    mailEvent(id: $id) {
      id
      gmailMessageFull {
        id
        from
        to
        subject
        content
        gmailThreadId
        leadId
        references
        inReplyTo
        attachments {
          filename
          attachmentId
        }
      }
    }
  }
`;

export const MAIL_EVENT_ATTACHMENT: TypedDocumentNode<
  { mailEventAttachment: string },
  AdminEventMailAttachmentArgs
> = gql`
  query mailEventAttachment(
    $mailEventId: Int!
    $attachmentId: String!
    $filename: String!
  ) {
    mailEventAttachment(
      mailEventId: $mailEventId
      attachmentId: $attachmentId
      filename: $filename
    )
  }
`;

export const GENERATE_CUSTOMER_OFFER: TypedDocumentNode<
  {
    offerCustomer: AdminAttachment;
  },
  AdminOfferLegacyArgs
> = gql`
  query offerCustomer($data: AdminOfferLegacyInput) {
    offerCustomer(data: $data) {
      url
    }
  }
`;

export const GENERATE_LEAD_OFFER: TypedDocumentNode<
  { offerLead: AdminAttachment },
  AdminOfferLegacyArgs
> = gql`
  query offerLead($data: AdminOfferLegacyInput) {
    offerLead(data: $data) {
      url
    }
  }
`;

// Line Items

export const BASE_PRICE: TypedDocumentNode<
  {
    basePrice: number;
  },
  AdminLineItemBasePriceArgs
> = gql`
  query basePrice($category: Int!, $quantity: Int!) {
    basePrice(category: $category, quantity: $quantity)
  }
`;

// Email Templates
export const EMAIL_TEMPLATE: TypedDocumentNode<{
  lead: IdInput;
  customer: IdInput;
}> = gql`
  query loadEmailTemplate(
    $id: Int!
    $lead: IdInput
    $customer: IdInput
    $offer: IdInput
    $order: IdInput
  ) {
    loadEmailTemplate(
      id: $id
      lead: $lead
      customer: $customer
      offer: $offer
      order: $order
    ) {
      subject
      content
      attachments {
        filename
        url
        key
      }
    }
  }
`;

export const MY_EMAIL_TEMPLATES: TypedDocumentNode<
  {
    myEmailTemplates: [AdminEmailTemplate];
  },
  AdminEmailTemplateArgs
> = gql`
  query myEmailTemplates($where: AdminEmailTemplateWhereInput!) {
    myEmailTemplates(where: $where) {
      id
      name
    }
  }
`;

export const ALL_EMAIL_TEMPLATES: TypedDocumentNode<
  {
    allEmailTemplates: [AdminEmailTemplate];
  },
  AdminEmailTemplateArgs
> = gql`
  query allEmailTemplates($where: AdminEmailTemplateWhereInput!) {
    allEmailTemplates(where: $where) {
      id
      name
      isHiddenForMe
    }
  }
`;

// location

export const GET_DUPLICATE_LOCATIONS: TypedDocumentNode<
  {
    getDuplicateLocations: [AdminLocationCustomerId];
  },
  AdminLocationFindArgs
> = gql`
  query getDuplicateLocations($placeId: String!) {
    getDuplicateLocations(placeId: $placeId) {
      customerId
    }
  }
`;

// leaderboard

export const LEADERBOARD_MONTHLY_SALES: TypedDocumentNode<
  { leaderboardMonthlySales: AdminLineData },
  { roleIds: number[] }
> = gql`
  query leaderboardMonthlySales($roleIds: [Int!]!) {
    leaderboardMonthlySales(roleIds: $roleIds) {
      labels
      datasets {
        label
        data
      }
    }
  }
`;

export const LEADERBOARD_TOP_PERFORMERS: TypedDocumentNode<
  { leaderboardTopPerformers: AdminStatsAbstractStaffMetric[] },
  AdminStatsByDateArgs
> = gql`
  query leaderboardTopPerformers($startAt: DateTime!, $endAt: DateTime!) {
    leaderboardTopPerformers(startAt: $startAt, endAt: $endAt) {
      id
      name
      avatar
      value
    }
  }
`;

export const LEADERBOARD_MOST_CUSTOMERS: TypedDocumentNode<
  { leaderboardMostCustomers: AdminStatsAbstractStaffMetric[] },
  AdminStatsByDateArgs
> = gql`
  query leaderboardMostCustomers($startAt: DateTime!, $endAt: DateTime!) {
    leaderboardMostCustomers(startAt: $startAt, endAt: $endAt) {
      id
      name
      avatar
      value
    }
  }
`;

export const LEADERBOARD_ACTIVITY: TypedDocumentNode<
  { activityOverview: AdminAgentActivityData[] },
  AdminAgentActivityArgs
> = gql`
  query activityOverview(
    $startAt: DateTime!
    $endAt: DateTime!
    $callDirection: CallDirectionOptions!
    $emailDirection: EmailOptions!
    $roleIds: [Int!]!
  ) {
    activityOverview(
      startAt: $startAt
      endAt: $endAt
      callDirection: $callDirection
      emailDirection: $emailDirection
      roleIds: $roleIds
    ) {
      name
      callsCount
      emailsCount
      totalCount
    }
  }
`;

export const LEADERBOARD_CALL_DURATION: TypedDocumentNode<{
  activityCallDuration: AdminAgentCallDurationData[];
}> = gql`
  query activityCallDuration(
    $startAt: DateTime!
    $endAt: DateTime!
    $callDirection: CallDirectionOptions!
    $emailDirection: EmailOptions!
  ) {
    activityCallDuration(
      startAt: $startAt
      endAt: $endAt
      callDirection: $callDirection
      emailDirection: $emailDirection
      roleIds: [${StaticRoles.salesManager},${StaticRoles.squadLead}]) {
      name
      totalCallDuration
    }
  }
`;

export const LEADERBOARD_SQUAD_SALES: TypedDocumentNode<
  { leaderboardSquads: AdminSquadStats },
  AdminStatsByDateArgs
> = gql`
  query leaderboardSquads($startAt: DateTime!, $endAt: DateTime!) {
    leaderboardSquads(startAt: $startAt, endAt: $endAt) {
      sales {
        id
        totalAmount
        avg
      }
      squads {
        id
        lead {
          firstName
        }
        members {
          firstName
        }
      }
    }
  }
`;

//  offers

export const OFFERS: TypedDocumentNode<
  { allOffers: AdminOfferPaginatedList },
  AdminOfferArgs
> = gql`
  query allOffers($where: AdminOfferWhereInput!, $take: Int, $skip: Int) {
    allOffers(where: $where, take: $take, skip: $skip) {
      totalCount
      items {
        id
        slug
        companyName
        customer {
          id
          email
        }
        lead {
          id
          email
        }
        author {
          name
        }
        author {
          name
        }
        status
        reviewCount
        locationsCount
        totalAmount
        createdAt
        lastStaffUpdateAt
        lastStatusUpdateAt
        discountCode
      }
    }
  }
`;

export const OFFER: TypedDocumentNode<{ offer: AdminOffer }, IdInput> = gql`
  query offer($id: Int!) {
    offer: offerById(id: $id) {
      id
      slug
      gender
      companyName
      lastName
      expiresInDays
      expiresAt
      customer {
        id
      }
      lead {
        id
      }
      lineItems {
        id
        title
        scanCacheId
        mapsLink
        reviewIds
        onlineProfileId
        platformId
        unitPrice
        listPrice
      }
      status
      reviewCount
      locationsCount
      totalAmount
      createdAt
      updatedAt
      discountCode
    }
  }
`;

export const OFFER_STATS: TypedDocumentNode<
  {
    offerOverview: AdminOfferOverviewStats;
  },
  AdminStatsByDateArgs
> = gql`
  query offerOverview($startAt: DateTime!, $endAt: DateTime!) {
    offerOverview(startAt: $startAt, endAt: $endAt) {
      offers {
        name
        createdOffers
        cancelledOffers
        netCreated
        amount
        pendingCount
        expiredCount
        wonCount
        wonValue
        wonTimeDays
        wonRate
      }
      total {
        name
        createdOffers
        cancelledOffers
        netCreated
        amount
        pendingCount
        expiredCount
        wonCount
        wonValue
        wonTimeDays
        wonRate
      }
    }
  }
`;

// squad

export const ALL_SQUADS: TypedDocumentNode<{
  allSquads: AdminSquad[];
}> = gql`
  query allSquads {
    allSquads {
      id
      lead {
        id
        name
      }
      members {
        id
        name
      }
    }
  }
`;

// integrations
export const OPENAI_KUNUNU: TypedDocumentNode<
  { openAIKununuPrompt: string },
  AdminOpenAIKununuPromptArgs
> = gql`
  query openAIKununuPrompt($topic: String!, $review: String!) {
    openAIKununuPrompt(topic: $topic, review: $review)
  }
`;
