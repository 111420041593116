import { useLazyQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import { isEmpty } from 'lodash';

export function useLazyNetworkQuery<TData, TVariables = any>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
) {
  const res = useLazyQuery(query, { ...options, fetchPolicy: 'network-only' });
  return isEmpty(res[1].data) ? { ...res, data: undefined } : res;
}
