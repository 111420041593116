import { BadgeClassNames } from 'admin/components/badges/EventBadge';
import { MailgunEventStatus } from 'shared/types/admin/enums';

export const handleBadgeClasses = (trackingStatus?: string) => {
  switch (trackingStatus) {
    case MailgunEventStatus.processing:
      return BadgeClassNames.processing;
    case MailgunEventStatus.delivered:
      return BadgeClassNames.success;
    case MailgunEventStatus.opened:
      return BadgeClassNames.opened;
    case MailgunEventStatus.failed:
      return BadgeClassNames.error;
    default:
      return '';
  }
};
