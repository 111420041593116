import * as Icons from 'admin/assets/icons';
import { CSSProperties } from 'react';

type IconProps = {
  icon: string;
  className?: string;
  style?: CSSProperties;
};

export function Icon({ icon, style, ...props }: IconProps) {
  const Icon = (Icons as any)[icon];
  return <Icon {...props} style={style} />;
}
