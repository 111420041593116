import axios, { AxiosRequestConfig } from 'axios';
import { AdminAttachment } from 'shared/types/admin/document-template';

export const postData = (
  url?: string,
  data?: any,
  config?: AxiosRequestConfig
) => url && axios.post(url, data, config);

export const putData = (
  url?: string,
  data?: any,
  config?: AxiosRequestConfig
) => url && axios.put(url, data, config);

export const getData = async (url?: string, config?: AxiosRequestConfig) =>
  url && axios.get(url, config);

/**
 *
 * fetcher function for SWR library
 */

export const fetcher = (
  ...args: [input: RequestInfo, init?: RequestInit | undefined]
) => args && fetch(...args).then((res) => res.json());

/**
 * CRUD file
 */
const HTTP_FILE_CONFIG = {
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export function postFileData(path: string, data: any) {
  return axios.post(path, data, HTTP_FILE_CONFIG);
}

export function deleteFileData(path: string) {
  return axios.delete(path, HTTP_FILE_CONFIG);
}

export function getHrefForAttachment(
  attachment: AdminAttachment,
  download = false
) {
  const d = download ? '1' : '';
  return (
    attachment.url ||
    `${process.env.REACT_APP_UPLOAD_SERVER}/cache/${attachment.key}?download=${d}`
  );
}

export function getHrefForMailTracking(trackingId: string) {
  return `https://app.eu.mailgun.com/app/sending/domains/onnoplus.de/logs/${trackingId}`;
}
