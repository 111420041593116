import { TFunction } from 'i18next';
import { isNumber } from 'lodash';
import moment, { MomentInput } from 'moment';
import {
  EventType,
  Gender,
  KPI,
  PaymentMethod,
} from 'shared/types/admin/enums';
import { AdminTimelineEntry } from 'shared/types/admin/timeline-entry';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
});

export const formatPrice = (value: number | undefined | null) => {
  if (value === null) {
    return '';
  }
  return value === undefined ? 'N/A' : currencyFormatter.format(value);
};

export const formatPriceShort = (value: number | undefined | null) => {
  return formatPrice(value).slice(0, -3);
};

export const formatDate = (
  value: Date | string | undefined,
  formatter: string,
  t?: TFunction
) => {
  if (value) {
    return moment(value).format(formatter);
  }
  if (t) {
    return t('N/A');
  }
  return '';
};

export const formatRelativeDate = (value: MomentInput) => {
  if (value) {
    return moment(value).fromNow();
  }

  return '';
};

export const formatCallDuration = (
  seconds: number | undefined,
  suffix?: string | undefined
) => {
  if (!seconds) return '';

  return `${Math.floor(seconds / 60)}:${(seconds % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })}${suffix || ' min'}`;
};

export const formatBoolean = (value: boolean | undefined, t: TFunction) => {
  if (value === undefined) {
    return '';
  }
  return value ? t('Yes') : t('No');
};

export const formatPhone = (value: string | undefined) =>
  !value || value.startsWith('+') ? value : '+' + value;

export const formatPhoneDigitsOnly = (value: string | undefined) =>
  value?.substring(1);

export const formatPercent = (
  value: number | undefined | null,
  signed?: boolean
) => {
  if (value === null) {
    return '';
  }
  if (value === undefined) {
    return 'N/A';
  }
  const formatted = `${Number(value.toFixed(1))}%`;
  if (signed && value && value > 0) {
    return `+${formatted}`;
  }
  return formatted || '';
};

export const formatDecimalPercent = (
  value: number | undefined | null,
  decimals: number
) => {
  if (value === null) {
    return 'N/A';
  }
  if (value === undefined) {
    return '';
  }
  const formatted = `${value.toFixed(decimals)}%`;
  return formatted;
};

export const formatFloat = (value: number | undefined, precision = 2) => {
  if (value === null) {
    return 'N/A';
  }

  if (value === undefined) {
    return '';
  }

  return value.toFixed(precision);
};

export const formatKPI = (kpi: KPI, t: TFunction) => {
  if (kpi === KPI.sales) {
    return t('Sales');
  }
  return t('Paid Invoices');
};

export const formatAccountManagerName = (
  name: string | undefined,
  t: TFunction
) => {
  return name?.trim() || t('Support Team');
};

export const formatTimelineEntryAuthor = (
  timelineEntry: AdminTimelineEntry,
  t: TFunction
) => {
  const prefix =
    timelineEntry.type === EventType.task ? t('assigned to') : t('by');

  if (timelineEntry.author) {
    return `${prefix} ${timelineEntry.author.name}`;
  }

  return '';
};

export const formatNumber = (number: number | null | undefined) =>
  isNumber(number) ? number : 'N/A';

export const formatDateRange = (
  fromDate?: Date | null,
  untilDate?: Date | null
) => {
  let formattedRange = '';
  if (fromDate) {
    if (fromDate.getTime() === 0) {
      formattedRange += '- ';
    } else {
      formattedRange += `${formatDate(fromDate, 'll')} - `;
    }
  }
  if (untilDate) {
    if (untilDate.getTime() >= 0) {
      formattedRange += `${formatDate(untilDate, 'll')}`;
    }
  }
  return formattedRange;
};

export function formatGender(t: TFunction, gender?: Gender | null) {
  if (gender === Gender.male) {
    return t('maleCustomer');
  }
  if (gender === Gender.female) {
    return t('femaleCustomer');
  }
  return t('maleCustomer/femaleCustomer');
}

export function formatLimitedText(text: string, limit: number) {
  return text.length > limit ? text.substring(0, limit) + '…' : text;
}

export function formatPaymentMethod(method: PaymentMethod, t: TFunction) {
  switch (method) {
    case PaymentMethod.direct:
      return t('Invoice (Direct)');
    case PaymentMethod.factoring:
      return t('Invoice (Factoring OLD)');
    case PaymentMethod.crefoFactoring:
      return t('Invoice (Crefo Factoring)');
    case PaymentMethod.lsv:
      return t('LSV');
    default:
      return '';
  }
}

export function formatPaymentMethodShort(method: PaymentMethod, t: TFunction) {
  switch (method) {
    case PaymentMethod.direct:
      return t('Direct');
    case PaymentMethod.factoring:
      return t('Factoring');
    case PaymentMethod.lsv:
      return t('LSV');
    default:
      return '';
  }
}

export function formatUrl(slug: string) {
  if (
    slug.match('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}')
  ) {
    const ORDER_URL =
      process.env.REACT_APP_ORDERS_URL || 'https://auftragsstatus.onnoplus.de';
    return `${ORDER_URL}/${slug}`;
  }

  if (slug.match('[0-9a-z]{4}-[0-9a-z]{4}')) {
    const OFFER_URL =
      process.env.REACT_APP_OFFERS_URL || 'https://angebote.onnoplus.de';

    return `${OFFER_URL}/${slug}`;
  }

  return '';
}
