import DesktopSidebar from 'admin/components/sidebar/DesktopSidebar';
import MobileSidebar from 'admin/components/sidebar/MobileSidebar';
import React from 'react';

function Sidebar() {
  return (
    <>
      <DesktopSidebar />
      <MobileSidebar />
    </>
  );
}

export default Sidebar;
